h6 {
  font-size: .75em !important; }

h5 {
  font-size: .875em !important; }

h4 {
  font-size: 1em !important; }

h3 {
  font-size: 1.2em !important; }

h2 {
  font-size: 1.5em !important; }

h1 {
  font-size: 1.6em !important; }

h1, h2, h3, h4, h5, h6 {
  margin: 0px !important; }

.row {
  margin-right: 0px !important;
  margin-left: 0px !important; }

.nopadding {
  padding: 0 !important;
  margin: 0 !important; }

.error {
  color: red;
  text-align: center;
  font-weight: bold; }

.width-auto {
  width: auto !important; }

.height-auto {
  height: auto !important; }

#navbarSupportedContent {
  max-width: 1170px;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  display: flex !important;
  flex-basis: auto;
  flex-direction: row; }

#header {
  position: fixed;
  z-index: 9;
  width: 100%; }
  #header .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar {
  padding: 0px !important;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .navbar .navbar-nav {
    padding: 0px 30px;
    position: relative;
    flex-direction: row;
    display: flex;
    margin-bottom: 0;
    list-style: none; }
    .navbar .navbar-nav:first-child {
      padding-left: 0px; }
    .navbar .navbar-nav:last-child {
      padding-right: 0px; }
    .navbar .navbar-nav .navbar-brand {
      color: #7f7f7f;
      font-size: 1.2em;
      vertical-align: bottom;
      padding: 0px; }
      .navbar .navbar-nav .navbar-brand img {
        height: 26px;
        width: auto;
        margin-right: 5px; }
  .navbar .nav-item {
    position: relative; }
    .navbar .nav-item.user {
      padding-right: 10px;
      margin-right: 10px; }
      .navbar .nav-item.user .separate {
        position: absolute;
        height: 30px;
        width: 1px;
        background: #dee0e2;
        right: 0;
        top: 15px; }
      .navbar .nav-item.user .dropdown-menu.show {
        left: -70px;
        box-shadow: 0 16px 42px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #eceff1;
        position: absolute; }
        .navbar .nav-item.user .dropdown-menu.show .dropdown-item {
          color: #455a64;
          width: 270px;
          background: transparent;
          margin: 10px 0;
          padding: 10px 20px;
          font-size: 14px;
          color: #455a64; }
          .navbar .nav-item.user .dropdown-menu.show .dropdown-item svg {
            margin-right: 10px; }
          .navbar .nav-item.user .dropdown-menu.show .dropdown-item:hover {
            color: #455a64;
            background: #e5f8f8; }
    .navbar .nav-item.carrello a {
      display: flex; }
      .navbar .nav-item.carrello a .circleCount {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: solid 1.5px #008997;
        margin-right: 5px;
        color: #008997;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .navbar .nav-item .nav-link {
      font-family: Barlow;
      font-size: 0.875em;
      text-align: center;
      color: #000 !important;
      padding: 17px 10px !important; }
      .navbar .nav-item .nav-link:last-child {
        padding-right: 0 !important; }
      .navbar .nav-item .nav-link .newMex {
        position: absolute;
        width: 7px;
        height: 7px;
        background: red;
        outline: 3px solid #fff;
        border-radius: 3px;
        left: 30px;
        bottom: 40px; }
      .navbar .nav-item .nav-link svg {
        margin-right: 5px;
        width: 21px;
        height: 21px; }
      .navbar .nav-item .nav-link.active {
        color: #008997 !important;
        border-bottom: 3px solid #008997; }
        .navbar .nav-item .nav-link.active svg {
          fill: #008997; }
  .navbar .separate {
    position: absolute;
    height: 30px;
    width: 1px;
    background: #dee0e2;
    right: 0; }

@media (min-width: 320px) and (max-width: 480px) {
  #headerMobile {
    background: #fff;
    width: 100vw;
    position: fixed;
    top: 0;
    padding: 14px 17px;
    z-index: 10;
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07); }
    #headerMobile .fixedMenu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      #headerMobile .fixedMenu .nav-item {
        display: flex;
        justify-content: center;
        align-items: center; }
        #headerMobile .fixedMenu .nav-item.carrello a {
          display: flex; }
          #headerMobile .fixedMenu .nav-item.carrello a .circleCount {
            width: 22px;
            height: 22px;
            border-radius: 10px;
            border: solid 1.5px #008997;
            margin-right: 5px;
            color: #008997;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center; }
        #headerMobile .fixedMenu .nav-item.btnMenu {
          z-index: 11;
          position: relative; }
          #headerMobile .fixedMenu .nav-item.btnMenu .separate {
            position: absolute;
            height: 30px;
            width: 1px;
            background: #dee0e2;
            right: 0;
            top: 15px; }
          #headerMobile .fixedMenu .nav-item.btnMenu .newMex {
            position: absolute;
            width: 7px;
            height: 7px;
            background: red;
            outline: 2px solid #fff;
            border-radius: 3px;
            left: 18px;
            bottom: 21px; }
          #headerMobile .fixedMenu .nav-item.btnMenu img {
            width: 40px; }
        #headerMobile .fixedMenu .nav-item .navbar-brand {
          margin-left: 15px; }
          #headerMobile .fixedMenu .nav-item .navbar-brand img {
            opacity: 1;
            width: 140px; }
          #headerMobile .fixedMenu .nav-item .navbar-brand span {
            opacity: 0.5;
            font-size: 14.5px;
            color: #000;
            margin-left: 5px; }
      #headerMobile .fixedMenu .prefCar {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
        #headerMobile .fixedMenu .prefCar .nav-link {
          padding: 0 !important; }
          #headerMobile .fixedMenu .prefCar .nav-link svg {
            width: 22px;
            margin-right: 20px; }
    #headerMobile .expand {
      height: 0;
      overflow: hidden;
      -webkit-transition: all 0.8s;
      -moz-transition: all 0.8s;
      transition: all 0.8s;
      opacity: 0;
      visibility: hidden; }
      #headerMobile .expand.open {
        visibility: visible;
        opacity: 1;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 70px); }
      #headerMobile .expand .nav-item {
        padding: 0 25px;
        border-bottom: 1px solid #e8e8ec;
        display: flex;
        align-items: center; }
        #headerMobile .expand .nav-item .nav-link {
          font-family: Barlow;
          font-size: 14px;
          text-decoration: none;
          padding: 20px;
          color: #000;
          display: flex; }
          #headerMobile .expand .nav-item .nav-link svg {
            display: block;
            fill: #008997;
            width: 20px;
            margin-right: 27px; }
      #headerMobile .expand .help {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: Barlow;
        color: #008996;
        padding-bottom: 5px;
        border-bottom: 2px solid #008996;
        margin-top: 25px; }
      #headerMobile .expand .logout {
        width: 300px;
        height: 40px;
        border-radius: 4px;
        border: solid 2px #008996;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        position: absolute;
        bottom: 20px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #navbarSupportedContent {
    max-width: 100vw;
    position: relative;
    height: 100px;
    align-items: baseline; }
  #header {
    width: 100vw; }
    #header .container {
      margin: 0px;
      padding: 0px;
      width: 100%;
      max-width: 100%; }
  .navbar #navbarSupportedContent {
    position: relative;
    height: 120px;
    padding: 0 30px; }
  .navbar .navbar-nav {
    padding: 0; }
    .navbar .navbar-nav:first-child .separate {
      display: none; }
    .navbar .navbar-nav:nth-child(2) {
      position: absolute;
      top: 61px;
      display: flex;
      width: calc(100% - 60px);
      justify-content: space-around; }
      .navbar .navbar-nav:nth-child(2) .nav-item {
        width: 22%; }
  .navbar .nav-item .nav-link .newMex {
    left: 50px;
    bottom: 37px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar .nav-item .nav-link .newMex {
    left: 79px;
    bottom: 39px; } }

footer {
  border-top: 1px  solid lightgrey;
  background-color: white;
  color: #000000;
  position: absolute;
  right: 0;
  bottom: 0px;
  left: 0;
  display: flex !important;
  justify-content: center; }
  footer .row {
    max-width: 1366px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px; }
    footer .row .content {
      display: flex;
      align-items: center; }
    footer .row .privacy {
      display: flex;
      align-items: center; }
    footer .row a:hover {
      color: #000000; }
    footer .row.question {
      background: transparent; }
    footer .row h6, footer .row h5 {
      margin: 0 10px;
      color: #000000; }

@media (min-width: 320px) and (max-width: 480px) {
  footer {
    background: transparent;
    position: initial; }
    footer .row {
      height: 60px;
      flex-direction: column;
      width: 140px;
      flex-wrap: initial; }
      footer .row .content {
        margin: 20px 0; }
      footer .row h6, footer .row h5 {
        margin: 0 10px;
        color: #000000;
        font-size: .75em !important; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  footer .content {
    padding-left: 30px; }
  footer .privacy {
    padding-right: 30px; } }

#portList, #portCarousel, #filtriHome, #homeHashTag {
  margin-top: 20px;
  padding: 0px;
  position: relative; }
  #portList .group-3:nth-child(3n+2), #portCarousel .group-3:nth-child(3n+2), #filtriHome .group-3:nth-child(3n+2), #homeHashTag .group-3:nth-child(3n+2) {
    padding: 0 30px; }
  #portList .group-4:nth-child(4n+2), #portCarousel .group-4:nth-child(4n+2), #filtriHome .group-4:nth-child(4n+2), #homeHashTag .group-4:nth-child(4n+2) {
    padding: 0 12.5px 0 22.5px; }
  #portList .group-4:nth-child(4n+3), #portCarousel .group-4:nth-child(4n+3), #filtriHome .group-4:nth-child(4n+3), #homeHashTag .group-4:nth-child(4n+3) {
    padding: 0 22.5px 0 12.5px; }
  #portList .countFiltered, #portCarousel .countFiltered, #filtriHome .countFiltered, #homeHashTag .countFiltered {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    font-family: Barlow; }
  #portList .containerList, #portCarousel .containerList, #filtriHome .containerList, #homeHashTag .containerList {
    padding: 10px 0; }
  #portList .bkg, #portCarousel .bkg, #filtriHome .bkg, #homeHashTag .bkg {
    position: absolute;
    width: 100vw;
    height: 100%;
    left: calc((1148px - 100vw)/2);
    background: #f5f8fa;
    bottom: 0;
    z-index: -1; }
  #portList.preferiti, #portList.recently, #portList.containerHashTag, #portCarousel.preferiti, #portCarousel.recently, #portCarousel.containerHashTag, #filtriHome.preferiti, #filtriHome.recently, #filtriHome.containerHashTag, #homeHashTag.preferiti, #homeHashTag.recently, #homeHashTag.containerHashTag {
    background: #f5f8fa;
    z-index: 0; }
    #portList.preferiti .page-title, #portList.recently .page-title, #portList.containerHashTag .page-title, #portCarousel.preferiti .page-title, #portCarousel.recently .page-title, #portCarousel.containerHashTag .page-title, #filtriHome.preferiti .page-title, #filtriHome.recently .page-title, #filtriHome.containerHashTag .page-title, #homeHashTag.preferiti .page-title, #homeHashTag.recently .page-title, #homeHashTag.containerHashTag .page-title {
      padding-top: 30px; }
  #portList .page-title, #portCarousel .page-title, #filtriHome .page-title, #homeHashTag .page-title {
    font-family: Barlow;
    margin-bottom: 20px;
    margin-left: 20px; }
  #portList .operation, #portCarousel .operation, #filtriHome .operation, #homeHashTag .operation {
    font-family: Barlow-bold;
    margin-right: 20px;
    font-size: 0.875em;
    color: #008996;
    text-decoration: none; }
    #portList .operation:hover, #portCarousel .operation:hover, #filtriHome .operation:hover, #homeHashTag .operation:hover {
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold; }
  #portList .react-multiple-carousel__arrow:before, #portCarousel .react-multiple-carousel__arrow:before, #filtriHome .react-multiple-carousel__arrow:before, #homeHashTag .react-multiple-carousel__arrow:before {
    font-size: 12px; }
  #portList .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left, #portCarousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left, #filtriHome .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left, #homeHashTag .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    top: 132px;
    left: 3px;
    min-width: 30px;
    min-height: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #008996; }
  #portList .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right, #portCarousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right, #filtriHome .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right, #homeHashTag .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    top: 132px;
    right: 3px;
    min-width: 30px;
    min-height: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #008996; }
  #portList .react-multi-carousel-list, #portCarousel .react-multi-carousel-list, #filtriHome .react-multi-carousel-list, #homeHashTag .react-multi-carousel-list {
    padding: 10px 0px 20px;
    margin: 0 -5px; }
  #portList .react-multi-carousel-dot-list, #portCarousel .react-multi-carousel-dot-list, #filtriHome .react-multi-carousel-dot-list, #homeHashTag .react-multi-carousel-dot-list {
    display: flex;
    align-items: center;
    height: 12px; }
    #portList .react-multi-carousel-dot-list .dot, #portCarousel .react-multi-carousel-dot-list .dot, #filtriHome .react-multi-carousel-dot-list .dot, #homeHashTag .react-multi-carousel-dot-list .dot {
      width: 8px;
      height: 8px;
      border-radius: 3px;
      background-color: #979da5;
      margin: 0 8px;
      cursor: pointer; }
      #portList .react-multi-carousel-dot-list .dot.active, #portCarousel .react-multi-carousel-dot-list .dot.active, #filtriHome .react-multi-carousel-dot-list .dot.active, #homeHashTag .react-multi-carousel-dot-list .dot.active {
        width: 11px;
        height: 11px;
        border-radius: 5px;
        background-color: #008996; }
  #portList #checkAllCart, #portCarousel #checkAllCart, #filtriHome #checkAllCart, #homeHashTag #checkAllCart {
    display: flex;
    align-items: center;
    margin-top: 30px; }
    #portList #checkAllCart span, #portCarousel #checkAllCart span, #filtriHome #checkAllCart span, #homeHashTag #checkAllCart span {
      width: 25px;
      height: 25px;
      border-radius: 4px;
      border: 1px solid #008996;
      cursor: pointer;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px; }
      #portList #checkAllCart span.selected:before, #portCarousel #checkAllCart span.selected:before, #filtriHome #checkAllCart span.selected:before, #homeHashTag #checkAllCart span.selected:before {
        content: "\2713";
        color: #008996; }

#homeHashTag {
  padding-bottom: 30px; }
  #homeHashTag .listHomeHashTag {
    margin-top: 40px; }
  #homeHashTag .homeItemHash {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 10px 20px;
    border-radius: 20px;
    background: #fff;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    -moz-box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    margin-right: 30px;
    cursor: pointer; }
  #homeHashTag .btnHash {
    margin-top: 40px; }
    #homeHashTag .btnHash h5 {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 150px;
      height: 40px;
      color: #FFF;
      background: #008996;
      box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4);
      border-radius: 5px;
      cursor: pointer; }

#hashModal.modal-lg {
  max-width: 570px;
  top: 20%; }

#hashModal .modal-content {
  border: 0;
  border-radius: 10px;
  max-height: 500px; }
  #hashModal .modal-content .modal-header {
    background: #00c0bb;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 70px;
    align-items: center; }
    #hashModal .modal-content .modal-header .modal-title {
      color: #fff;
      width: 100%;
      text-align: center; }
      #hashModal .modal-content .modal-header .modal-title h5 {
        font-weight: normal; }
    #hashModal .modal-content .modal-header .close {
      color: #fff;
      text-shadow: none;
      opacity: 1; }
      #hashModal .modal-content .modal-header .close:not(:disabled):not(.disabled):focus {
        outline: none; }
  #hashModal .modal-content .modal-body {
    padding: 20px 10px; }

#portCarousel {
  padding-bottom: 20px; }

#filtriHome {
  margin-top: 40px; }

.filtroHome {
  padding-bottom: 10px;
  margin: 10px 0; }
  .filtroHome:first-child {
    margin-bottom: 30px; }
  .filtroHome .nav-link {
    padding: 10px; }
  .filtroHome .card.containerFiltroHome {
    width: 270px;
    height: 90px;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    justify-content: center;
    cursor: pointer;
    font-family: Barlow;
    padding: 0 20px;
    border: none; }
    .filtroHome .card.containerFiltroHome span {
      right: 20px;
      position: absolute; }
      .filtroHome .card.containerFiltroHome span svg {
        width: 12px;
        fill: #d9dadb; }
    .filtroHome .card.containerFiltroHome h2 {
      color: #7b75d3;
      padding-bottom: 10px; }
    .filtroHome .card.containerFiltroHome h5 {
      color: #000; }
    .filtroHome .card.containerFiltroHome.Mix {
      border: solid 2px #455a64; }
      .filtroHome .card.containerFiltroHome.Mix h2 {
        font-family: Barlow-Bold;
        color: #6d7278; }
    .filtroHome .card.containerFiltroHome.Secured {
      border: solid 2px #59a669; }
      .filtroHome .card.containerFiltroHome.Secured h2 {
        font-family: Barlow-Bold;
        color: #59a669; }
    .filtroHome .card.containerFiltroHome.UnSecured {
      border: solid 2px #7b75d3; }
      .filtroHome .card.containerFiltroHome.UnSecured h2 {
        font-family: Barlow-Bold;
        color: #7b75d3; }

.card.containerPack {
  width: 270px;
  height: 370px;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
  background-color: #FFFFFF;
  padding: 16px 20px 16px 20px;
  margin-bottom: 20px; }
  .card.containerPack:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.2); }
  .card.containerPack .stato {
    font-family: Barlow-bold;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #363a40;
    min-height: 25px; }
    .card.containerPack .stato .statoData {
      flex: none; }
      .card.containerPack .stato .statoData h6 {
        display: inline-block; }
      .card.containerPack .stato .statoData .tipo {
        margin-right: 2.5px !important; }
      .card.containerPack .stato .statoData .onSale {
        color: #008996; }
        .card.containerPack .stato .statoData .onSale .point {
          background: #008996; }
      .card.containerPack .stato .statoData .point {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #363a40;
        display: inline-block;
        margin-right: 2.5px; }
    .card.containerPack .stato .checkCart {
      width: 25px;
      height: 25px;
      border-radius: 4px;
      border: 1px solid #008996;
      cursor: pointer;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center; }
      .card.containerPack .stato .checkCart.selected:before {
        content: "\2713";
        color: #008996; }
      .card.containerPack .stato .checkCart.disabled {
        opacity: .2;
        cursor: default; }
  .card.containerPack .titolo {
    color: #363a40;
    margin-top: 16px;
    font-family: Barlow-semiBold;
    flex-wrap: wrap;
    height: 144px;
    min-height: 144px;
    position: relative; }
    .card.containerPack .titolo a {
      text-decoration: none;
      color: black;
      width: 200px;
      height: 56px; }
  .card.containerPack .star {
    right: 0px;
    top: 0; }
    .card.containerPack .star.disable {
      opacity: .2;
      cursor: default; }
    .card.containerPack .star svg {
      color: #007e8a;
      width: 20px;
      height: 20px; }
  .card.containerPack .desc {
    color: #363a40;
    margin-top: 5px;
    font-family: Barlow;
    align-self: baseline; }
    .card.containerPack .desc .data {
      font-family: Barlow;
      font-size: 12px;
      margin-bottom: 8px; }
    .card.containerPack .desc h6 {
      padding-top: 8px; }
    .card.containerPack .desc h6:last-child {
      height: 40px;
      line-height: 16px; }
  .card.containerPack .gbv {
    width: 100%;
    text-align: left;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
    font-family: Barlow-semiBold;
    color: #363a40;
    margin-top: 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f3f4f5; }
    .card.containerPack .gbv h6 {
      padding-left: 5px;
      color: #363a40;
      font-family: Barlow; }
    .card.containerPack .gbv h3, .card.containerPack .gbv h6 {
      display: inline-block; }
  .card.containerPack .categoria {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin: 8px -10px 0; }
    .card.containerPack .categoria h5 {
      flex: 1;
      padding: 7px 0px;
      text-align: center;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      margin: 0 3px !important; }
      .card.containerPack .categoria h5 span {
        margin: 0 5px; }
      .card.containerPack .categoria h5.secured {
        background-color: #e8f6ec;
        color: #59a669;
        font-weight: 600; }
      .card.containerPack .categoria h5.unsecured {
        background-color: #f1f1fb;
        color: #7b75d3;
        font-weight: 600; }
  .card.containerPack .contentImg {
    text-align: center;
    font-size: 25px; }

#portList .card.containerPack .titolo h2, #portCarousel .card.containerPack .titolo h2 {
  height: 56px; }

@media (min-width: 320px) and (max-width: 480px) {
  #portCarousel {
    margin-top: 30px; }
    #portCarousel .bkg {
      left: -10px; }
    #portCarousel .page-title {
      font-size: 1em !important; }
    #portCarousel .operation {
      font-family: Barlow;
      padding-bottom: 3px;
      border-bottom: 1px solid #008996; }
  #portList .group-3:nth-child(3n+2) {
    padding: 0px; }
  #portList .group-4:nth-child(4n+2) {
    padding: 0px; }
  #portList .group-4:nth-child(4n+3) {
    padding: 0px; }
  #portList .row {
    justify-content: center; }
    #portList .row .countPortfolio {
      position: absolute;
      top: -140px;
      left: 20px;
      max-width: 200px;
      line-height: 1.2em; }
    #portList .row .card.containerPack {
      width: 300px; }
  #filtriHome {
    margin-top: 0px; }
    #filtriHome .row {
      justify-content: center; }
  .page-title {
    font-size: 1em !important;
    margin-bottom: 5px !important;
    margin-top: 30px !important; }
  .filtroHome {
    padding-bottom: 0px;
    margin: 0; }
    .filtroHome:first-child {
      margin-bottom: 0px; }
    .filtroHome .nav-link {
      padding: 0px !important;
      margin: 5px 0; }
    .filtroHome .card.containerFiltroHome {
      width: 300px;
      height: 70px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .filtroHome .card.containerFiltroHome h2 {
        margin-right: 10px !important;
        padding-bottom: 0px; }
  #homeHashTag .listHomeHashTag {
    flex-wrap: wrap; }
  #homeHashTag .homeItemHash {
    margin-bottom: 20px; }
  #homeHashTag .btnHash h5 {
    width: 100%; }
  #hashModal.modal-lg {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0; }
  #hashModal .modal-content {
    border: 0;
    border-radius: 0px;
    max-height: initial;
    height: 100vh; }
    #hashModal .modal-content .modal-header {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      height: 70px;
      align-items: center;
      -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 192, 187, 0.5);
      -moz-box-shadow: 0px 10px 15px 0px rgba(0, 192, 187, 0.5);
      box-shadow: 0px 10px 15px 0px rgba(0, 192, 187, 0.5);
      margin-bottom: 10px; }
      #hashModal .modal-content .modal-header .modal-title {
        text-align: left; }
      #hashModal .modal-content .modal-header .close span {
        font-size: 40px;
        font-weight: 100; }
    #hashModal .modal-content .modal-body {
      padding: 20px 10px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #portList .tablet, #portCarousel .tablet, #filtriHome .tablet, #homeHashTag .tablet {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap; }
    #portList .tablet.prefCarTablet, #portCarousel .tablet.prefCarTablet, #filtriHome .tablet.prefCarTablet, #homeHashTag .tablet.prefCarTablet {
      justify-content: space-between;
      padding: 0 60px; }
      #portList .tablet.prefCarTablet .group-4, #portCarousel .tablet.prefCarTablet .group-4, #filtriHome .tablet.prefCarTablet .group-4, #homeHashTag .tablet.prefCarTablet .group-4 {
        padding: 0px 0px 10px 0px; }
  #portList .group-3, #portCarousel .group-3, #filtriHome .group-3, #homeHashTag .group-3 {
    padding: 0px !important; }
  #portList .bkg, #portCarousel .bkg, #filtriHome .bkg, #homeHashTag .bkg {
    left: calc((100% - 100vw)/2); } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #portList .tablet.prefCarTablet, #portCarousel .tablet.prefCarTablet, #filtriHome .tablet.prefCarTablet {
    justify-content: flex-start;
    padding: 0px 50px; }
    #portList .tablet.prefCarTablet .group-4, #portCarousel .tablet.prefCarTablet .group-4, #filtriHome .tablet.prefCarTablet .group-4 {
      padding: 10px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .filtroHome {
    width: 33%; }
    .filtroHome .card.containerFiltroHome {
      flex-direction: row;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 70px; }
      .filtroHome .card.containerFiltroHome h2 {
        padding: 0 10px; }
    .filtroHome.caratteristicheAssetValues {
      width: 50%; } }

@media (min-width: 320px) and (max-width: 480px) {
  #homeHashTag .listHomeHashTag a {
    margin-right: auto; }
  #homeHashTag .listHomeHashTag .homeItemHash {
    margin-right: initial; } }

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: normal;
  src: url("styles/utilities/fonts/Barlow-Regular.otf") format("truetype"); }

@font-face {
  font-family: "Barlow-semiBold";
  font-style: normal;
  font-weight: normal;
  src: url("styles/utilities/fonts/Barlow-SemiBold.otf") format("truetype"); }

@font-face {
  font-family: "Barlow-bold";
  font-style: normal;
  font-weight: normal;
  src: url("styles/utilities/fonts/Barlow-Bold.otf") format("truetype"); }

@font-face {
  font-family: "Barlow-italic";
  font-style: normal;
  font-weight: normal;
  src: url("styles/utilities/fonts/Barlow-Italic.otf") format("truetype"); }

@font-face {
  font-family: "Barlow-med";
  font-style: normal;
  font-weight: normal;
  src: url("styles/utilities/fonts/Barlow-Medium.otf") format("truetype"); }

@font-face {
  font-family: "Barlow-light";
  font-style: normal;
  font-weight: normal;
  src: url("styles/utilities/fonts/Barlow-Light.otf") format("truetype"); }

@font-face {
  font-family: "IBMPlex";
  font-style: normal;
  font-weight: normal;
  src: url("styles/utilities/fonts/IBMPlexSans-Regular.otf") format("truetype"); }

.Toaster__manager-top-right {
  top: 200px !important;
  right: 0px !important; }

.Toaster__alert {
  background-color: #008997;
  overflow: hidden;
  max-width: 650px;
  position: relative;
  border-radius: 0.4rem;
  display: flex;
  margin-top: 5rem;
  padding-right: 10px;
  box-shadow: 0 5px 51px 0 rgba(0, 126, 138, 0.5);
  box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px, rgba(52, 58, 64, 0.1) 0px 6px 12px 0px/rgba(52, 58, 64, 0.12) 0px 6px 15px -2px;
  width: 330px;
  height: 90px;
  display: flex;
  align-items: center; }

.Toaster__alert_text {
  box-sizing: border-box;
  font-family: Barlow;
  color: white;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  line-height: 1.5;
  font-size: 14px !important;
  margin: 0px; }

.Toaster__alert_close {
  padding: 12px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  top: 0px;
  right: 4px;
  border-radius: 0.4rem;
  border: 0;
  -webkit-appearance: none;
  font-size: 1rem;
  font-weight: 100;
  line-height: 1;
  opacity: 1 !important;
  color: #FFF; }

.Toaster__alert_close:focus {
  box-shadow: rgba(52, 58, 64, 0.15) 0px 0px 0px 3px; }

.Toaster__message-wrapper {
  transition: all .5s;
  padding: 8px; }

@media (min-width: 320px) and (max-width: 480px) {
  .Toaster__alert {
    max-width: 300px; } }

.searchItemsCount {
  font-family: Barlow;
  padding: 0px 0; }
  .searchItemsCount span {
    font-size: 0.875em;
    font-family: Barlow-bold; }

@media (min-width: 320px) and (max-width: 480px) {
  .searchItemsCount {
    position: absolute;
    top: -123px;
    left: 0;
    max-width: 235px; } }

.resultLoader {
  display: none;
  border-radius: 10px;
  margin-top: 30px; }
  .resultLoader.opaco {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .resultLoader .loader {
    width: 370px;
    height: 167px;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    border: solid 1px #008996;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .resultLoader .loader .testo {
      margin-top: 30px;
      font-family: Barlow;
      font-size: 14px;
      color: #455a64; }

@media (min-width: 1200px) {
  html {
    height: 100%; }
    html body {
      min-height: 100%;
      position: relative; }
  #body {
    padding-top: 75px; }
    #body.big {
      max-width: 100vw !important;
      padding: 0; }
  .container {
    max-width: 1200px !important;
    padding: 0px;
    position: relative; }
    .container #question {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: #007e8a;
      position: absolute;
      bottom: 30px;
      right: -55px;
      cursor: pointer; }
      .container #question #exclamation {
        width: 100%;
        height: 10px;
        color: white;
        padding-top: 25px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .container #question.fixed {
        position: fixed;
        right: calc((100vw - 1330px)/2); } }

#marketPlace {
  background: #FFF;
  padding-bottom: 100px; }
  #marketPlace.grigio {
    background: #f5f8fa; }
  #marketPlace.hide {
    padding-bottom: 0px; }
    #marketPlace.hide #header, #marketPlace.hide footer, #marketPlace.hide #question, #marketPlace.hide #headerMobile {
      display: none !important; }
  #marketPlace .row.question svg {
    color: #505050;
    font-size: 40px; }
  #marketPlace .button {
    width: 170px;
    text-align: center;
    padding: 10px 0;
    background: #008996;
    border-radius: 4px;
    color: #FFF;
    margin: 50px auto;
    border: solid 2px #008996; }
    #marketPlace .button a {
      color: #FFF;
      font-size: 0.8em;
      text-decoration: none; }
    #marketPlace .button:hover {
      background: white; }
      #marketPlace .button:hover a {
        color: #008996; }

#emptyCart {
  width: 77%;
  height: 26em;
  margin-top: 20px;
  border: solid 1px rgba(87, 36, 163, 0.1);
  border-radius: 10px;
  background-color: #f5f8fa; }
  #emptyCart #image {
    margin-top: 50px;
    margin-bottom: 20px;
    background-size: 200px auto;
    background-image: url("styles/images/emptystate.png");
    height: 200px;
    /* You must set a specified height */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */ }
  #emptyCart #messagioEmpty {
    color: #455a64;
    font-size: 24px; }
  #emptyCart #descriptionEmpty {
    font-size: 14px;
    color: #455a64;
    line-height: 1.5;
    text-align: justify; }

#link {
  border-bottom: 1px solid #008996;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #008996; }

@media (min-width: 320px) and (max-width: 480px) {
  #body.public {
    padding: 0px !important;
    margin-top: 0px; }
  .container {
    padding: 10px !important;
    margin-top: 68px; }
  #marketPlace {
    padding-bottom: 60px;
    background: #f5f8fa;
    overflow: hidden; }
    #marketPlace #over {
      display: none; }
      #marketPlace #over.show {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
        background: rgba(69, 90, 100, 0.8);
        width: 100vw;
        height: 100%; }
    #marketPlace .button {
      width: 300px; }
  #emptyCart {
    width: 100%;
    height: 100%; }
    #emptyCart #image {
      margin-top: 25px;
      background-size: 110px auto;
      height: 110px;
      margin-bottom: 15px; }
    #emptyCart #messagioEmpty {
      font-family: Barlow-med;
      line-height: 24px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 15px; }
    #emptyCart #descriptionEmpty {
      text-align: center;
      margin-bottom: 15px; }
    #emptyCart #links {
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      #emptyCart #links a {
        margin-bottom: 15px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #root {
    min-height: 100%;
    position: relative; }
  #body.container {
    max-width: 100%;
    margin: 0;
    padding: 120px 20px 0px 20px;
    max-width: 100%; }
  #body.big {
    padding: 0px; }
  #marketPlace {
    min-height: 100vh; }
    #marketPlace #over {
      display: none; }
      #marketPlace #over.show {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
        background: rgba(69, 90, 100, 0.8);
        width: 100vw;
        height: 100%; }
  #emptyCart {
    height: 100%;
    padding-bottom: 15px;
    margin-bottom: 20px; }
    #emptyCart #image {
      margin-top: 25px;
      background-size: 160px auto;
      height: 160px; }
    #emptyCart #messagioEmpty {
      text-align: center; }
    #emptyCart #descriptionEmpty {
      text-align: center; } }
