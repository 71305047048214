#wellcome {
  font-family: Barlow;
  width: 100%;
  height: 221px;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4);
  background-image: url("../images/hero.png");
  background-size: cover;
  margin-top: 30px;
  display: flex;
  align-items: center; }
  #wellcome .containerImg, #wellcome .userDetail {
    width: 50%;
    margin-left: 5em; }
    #wellcome .containerImg .nome, #wellcome .userDetail .nome {
      font-size: 24px;
      color: white; }
    #wellcome .containerImg .info, #wellcome .userDetail .info {
      font-size: 14px;
      font-weight: normal;
      color: #cbf0f4; }
    #wellcome .containerImg .link, #wellcome .userDetail .link {
      font-size: 10px;
      font-weight: bold; }
      #wellcome .containerImg .link a, #wellcome .userDetail .link a {
        color: white !important;
        text-decoration: underline; }

#user {
  font-weight: bold; }

#divider {
  width: 1em;
  height: auto;
  display: inline-block; }

.btnWelcome {
  justify-content: flex-start; }
  .btnWelcome .buttonAll {
    width: 170px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid white;
    background-color: white;
    outline: none;
    color: #008996; }
    .btnWelcome .buttonAll:active {
      color: white;
      background-color: #008996;
      outline: none; }
  .btnWelcome .buttonTour {
    background: #007e8a;
    width: 170px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    outline: none; }
    .btnWelcome .buttonTour:active {
      color: #008996;
      background-color: white;
      outline: none; }

@media (min-width: 320px) and (max-width: 480px) {
  #wellcome {
    height: 100%;
    background-image: url("../images/hero_mobile.png");
    background-color: #008996;
    align-items: flex-end;
    padding: 20px 10px;
    margin-top: 0; }
    #wellcome .containerImg, #wellcome .userDetail {
      width: 100%;
      margin-left: 0em;
      margin-top: 100px; }
      #wellcome .containerImg .nome, #wellcome .userDetail .nome {
        padding: 0 10px;
        font-size: 24px;
        color: white;
        margin-bottom: 10px; }
        #wellcome .containerImg .nome .hello, #wellcome .userDetail .nome .hello {
          font-size: 18px; }
        #wellcome .containerImg .nome .user, #wellcome .userDetail .nome .user {
          font-size: 18px;
          font-family: Barlow-semiBold; }
      #wellcome .containerImg .info, #wellcome .userDetail .info {
        padding: 0 10px;
        font-size: 14px;
        font-weight: normal;
        color: #cbf0f4;
        line-height: 1.5em; }
      #wellcome .containerImg .link, #wellcome .userDetail .link {
        font-size: 10px;
        font-weight: bold; }
        #wellcome .containerImg .link a, #wellcome .userDetail .link a {
          color: white !important;
          text-decoration: underline; }
  #divider {
    display: none; }
  .btnWelcome {
    justify-content: space-between; }
    .btnWelcome .link {
      width: calc(50% - 10px); }
    .btnWelcome .buttonAll {
      width: 100%; }
    .btnWelcome .buttonTour {
      width: 100%; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #wellcome {
    background-image: url("../images/hero_tablet.png"); }
    #wellcome .userDetail {
      margin-left: 30px; }
      #wellcome .userDetail .nome {
        font-size: 18px !important; }
        #wellcome .userDetail .nome .hello {
          font-family: Barlow;
          font-weight: normal; }
        #wellcome .userDetail .nome #user {
          font-family: Barlow-SemiBold; }
        #wellcome .userDetail .nome .wellcomeBr {
          display: block;
          font-size: 24px; } }
