#errorPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px; }
  #errorPage .text, #errorPage .img {
    width: 50%; }
    #errorPage .text img, #errorPage .img img {
      width: 100%; }
  #errorPage .text {
    display: flex;
    flex-direction: column;
    padding: 15px;
    color: #455a64;
    font-family: Barlow; }
    #errorPage .text h1 {
      font-size: 40px !important; }
    #errorPage .text h1, #errorPage .text h3 {
      padding: 20px 0; }
    #errorPage .text .btnBack {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 270px;
      height: 40px;
      background: #008996;
      box-shadow: 0px 5px 20px rgba(0, 182, 202, 0.4);
      cursor: pointer;
      border-radius: 5px;
      text-decoration: none;
      color: #FFF;
      font-family: Barlow-bold; }
      #errorPage .text .btnBack:hover {
        text-decoration: none;
        color: #FFF; }
      #errorPage .text .btnBack h5 {
        color: #FFF; }
        #errorPage .text .btnBack h5 svg {
          fill: #FFF;
          width: 16px;
          height: 16px;
          margin-right: 10px; }

@media (min-width: 320px) and (max-width: 480px) {
  #errorPage {
    margin-top: initial;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 210px); }
    #errorPage .text {
      width: 100%;
      text-align: center;
      align-items: center; }
      #errorPage .text h1 {
        font-size: 25px !important; }
    #errorPage .img {
      display: none; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #errorPage .text h1 {
    font-size: 25px !important; } }
