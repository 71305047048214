.containerStrats {
  margin-top: 20px;
  position: relative; }

.contentStrats {
  margin-top: 20px;
  max-width: 856px;
  display: flex;
  width: 100%; }
  .contentStrats .tipodiCredito {
    width: 50%;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    background-color: #FFF;
    margin-bottom: 20px; }
    .contentStrats .tipodiCredito #tipodicredito {
      display: flex;
      height: 40px;
      font-family: Barlow;
      font-weight: bold;
      font-size: 16px;
      color: #282f33;
      align-items: center;
      width: calc(100% - 40px);
      margin-left: 20px;
      border-bottom: 1px solid #eceff1; }
  .contentStrats .emptyStrats {
    margin-left: 20px;
    padding: 10px 0;
    font-family: Barlow-italic; }

@media (min-width: 320px) and (max-width: 480px) {
  .contentStrats {
    flex-direction: column; }
    .contentStrats .tipodiCredito {
      width: 100%; } }
