#center {
  margin: 30px auto; }

.chatbutton {
  float: right;
  cursor: pointer;
  margin-bottom: 20px; }
  .chatbutton button {
    padding: 7px 30px;
    background-color: #979da5;
    border: none;
    border-radius: 4px;
    color: #aaa; }
    .chatbutton button:focus {
      outline: none; }
    .chatbutton button:enabled {
      background-color: #008996;
      color: white; }
  .chatbutton #dataTape {
    background-color: #008996;
    color: white; }
  .chatbutton #dataTapeAnnulla {
    background-color: #e5f8f8;
    color: #008996; }
  .chatbutton #dataTButtons {
    margin-bottom: 15px; }

.chat_head {
  color: #455a64;
  font-size: 24px;
  margin-bottom: 2em;
  text-align: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #eceff1; }
  .chat_head span {
    font-family: Barlow; }
    .chat_head span b {
      font-family: Barlow-semiBold;
      color: #008996; }

.wordWrap {
  word-wrap: anywhere; }

.new_message_head {
  background: white none repeat scroll 0 0;
  float: left;
  font-size: 13px;
  padding: 18px 10px;
  width: 100%; }

.message_section {
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.07); }

#singlemess {
  width: 100%; }

.message_write {
  background: white none repeat scroll 0 0;
  padding: 15px 0;
  width: 100%; }
  .message_write #dataTHelp {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #e5f8f8;
    padding: 20px;
    margin-bottom: 10px; }
    .message_write #dataTHelp #alertIcon {
      padding: 0 6px; }
    .message_write #dataTHelp #description {
      font-family: Barlow;
      margin: 0 0 0 20px;
      color: #455a64; }

.message_write textarea.form-control {
  height: 70px;
  padding: 10px;
  resize: none;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important; }

.chat_bottom {
  float: left;
  margin-top: 13px;
  width: 100%; }

.chat_bottom {
  float: left;
  margin-top: 13px;
  width: 100%; }

#textarea {
  height: 200px;
  border: none; }

#textpayloadarea {
  border: none; }

.textandinput {
  border: solid 1px #e0e1e3;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }
  .textandinput #allegatoSize {
    float: right;
    font-family: Barlow;
    color: #979da5;
    font-size: 14px; }
  .textandinput li {
    margin-bottom: 10px;
    background-color: #f5f8fa;
    color: #008996;
    font-family: Barlow;
    font-weight: 600;
    list-style-type: none; }
  .textandinput #textpayloadarea {
    font-family: Barlow;
    font-style: italic; }
  .textandinput .counter {
    float: right;
    height: 40px;
    padding-top: 20px;
    padding-right: 10px;
    font-family: Barlow;
    font-style: italic;
    color: #495057;
    font-size: 14px;
    font-weight: 400; }
    .textandinput .counter.error {
      color: #ff3f5d;
      font-weight: bold; }

.borderUpload {
  display: none; }

#uploadButton {
  cursor: pointer;
  font-family: Barlow-bold;
  width: 8em;
  height: 40px;
  text-align: center;
  font-weight: bold;
  padding-top: 8px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #e5f8f8;
  color: #008996; }
  #uploadButton #icon {
    fill: #008996; }

#dataT {
  font-family: Barlow-SemiBold;
  color: #455a64; }
  #dataT #nomePacchetto {
    font-family: Barlow-SemiBold;
    color: #455a64;
    font-style: italic; }

#form {
  padding: 0px 25px; }
  #form #dataTHelp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-radius: 10px;
    background-color: #e5f8f8;
    padding: 20px; }
    #form #dataTHelp #alertIcon {
      padding: 0 6px; }
    #form #dataTHelp #description {
      font-family: Barlow;
      margin: 0 0 0 20px;
      color: #455a64; }
    #form #dataTHelp #linkData {
      font-size: 12px;
      color: #455a64;
      text-decoration: underline; }

#messageDetailBorder {
  font-family: Barlow;
  height: 100%;
  border-bottom: 1px solid #f5f8fa; }
  #messageDetailBorder #nome {
    font-family: Barlow-semiBold; }
  #messageDetailBorder #dataConversation {
    font-family: Barlow;
    font-weight: normal; }
  #messageDetailBorder #testoConversation {
    font-family: Barlow;
    font-weight: normal; }
  #messageDetailBorder .allegatiConversation {
    display: flex;
    flex-wrap: wrap;
    padding-left: 70px; }
    #messageDetailBorder .allegatiConversation li {
      margin-right: 20px;
      border-radius: 10px;
      border: solid 1px #eceff1;
      margin-bottom: 10px;
      background-color: white;
      color: #455a64;
      cursor: pointer;
      list-style-type: none;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px; }
      #messageDetailBorder .allegatiConversation li svg {
        margin-right: 8px; }

@media (min-width: 320px) and (max-width: 480px) {
  #center .message_section {
    padding-right: 0px;
    padding-left: 0px; }
    #center .message_section .new_message_head {
      padding: 30px 0px 10px;
      border-radius: 10px; }
      #center .message_section .new_message_head .container {
        margin-top: 0px; }
        #center .message_section .new_message_head .container #messageDetailBorder {
          padding: 0px !important; }
          #center .message_section .new_message_head .container #messageDetailBorder #circle {
            padding: 0px !important;
            margin: 25px 0px !important; }
          #center .message_section .new_message_head .container #messageDetailBorder #messages {
            padding: 10px 0px !important; }
            #center .message_section .new_message_head .container #messageDetailBorder #messages #content {
              position: relative; }
              #center .message_section .new_message_head .container #messageDetailBorder #messages #content #nome {
                padding-top: 15px !important; }
              #center .message_section .new_message_head .container #messageDetailBorder #messages #content #dataConversation {
                position: absolute;
                top: -10px;
                right: 0px; }
          #center .message_section .new_message_head .container #messageDetailBorder .allegatiConversation {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 0px;
            width: 280px; }
            #center .message_section .new_message_head .container #messageDetailBorder .allegatiConversation li {
              justify-content: flex-start;
              padding-left: 20px;
              width: 100%;
              margin-right: 0px;
              height: 60px; }
    #center .message_section .message_write {
      border-radius: 10px;
      padding: 0px 15px; }
      #center .message_section .message_write .textandinput textarea {
        height: 120px;
        font-size: 14px; }
      #center .message_section .message_write .textandinput .p-2 {
        border-bottom: 1px solid #e0e1e3;
        margin: 0 10px; }
        #center .message_section .message_write .textandinput .p-2 #containerAllegati {
          font-size: 14px; }
          #center .message_section .message_write .textandinput .p-2 #containerAllegati #allegatoSize {
            font-size: 12px; }
            #center .message_section .message_write .textandinput .p-2 #containerAllegati #allegatoSize svg {
              margin-left: 5px; }
      #center .message_section .message_write .textandinput #uploadButton {
        width: 132px;
        height: 40px;
        font-size: 14px;
        margin-top: 10px; }
        #center .message_section .message_write .textandinput #uploadButton svg {
          margin-right: 5px; }
  .chat_head {
    font-size: 20px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eceff1; }
    .chat_head span:last-child {
      font-size: 14px;
      font-family: Barlow; }
    .chat_head b {
      font-size: 14px;
      font-family: Barlow-semiBold;
      color: #008996; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #center {
    padding: 10px; }
    #center .message_section {
      max-width: 100%;
      padding-right: 20px;
      padding-left: 20px; }
      #center .message_section .new_message_head {
        padding: 30px 0px 10px;
        border-radius: 10px; }
        #center .message_section .new_message_head .container {
          margin-top: 0px;
          padding: 0; }
          #center .message_section .new_message_head .container #singlemess #messageDetailBorder {
            padding: 0 90px 0 0; }
            #center .message_section .new_message_head .container #singlemess #messageDetailBorder #circle {
              margin: 15px 15px 0 15px; }
  .chat_head {
    padding-bottom: 20px;
    max-width: 400px;
    text-align: center;
    margin: 0 auto; } }
