#buttoninfo {
  float: right;
  cursor: pointer; }
  #buttoninfo #info {
    border-radius: 4px;
    font-family: Barlow-bold;
    padding: 10px 20px;
    border: 2px solid #455a64;
    color: #455a64; }

.table {
  font-family: Barlow; }
  .table th, .table td {
    border-top: 1px solid #ececec !important;
    color: #000;
    font-size: 14px;
    padding: .75em 0px !important; }
    .table th:last-child, .table td:last-child {
      border-bottom: none; }
  .table thead {
    border-top: hidden; }
    .table thead > tr > th {
      color: #455a64;
      font-size: 12px;
      font-family: Barlow;
      vertical-align: bottom;
      border-bottom: 1px solid #ececec; }

#button {
  line-height: 12px;
  float: right;
  background: white; }

#upload {
  border: black;
  float: left; }

#submit {
  float: right; }

.custom {
  width: 40em;
  height: 20px; }

#containerPosizione {
  background-color: #f5f8fa; }
  #containerPosizione #backportafoglio {
    position: fixed;
    height: 60px;
    background-color: #008998;
    box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4);
    top: 60px;
    width: 100vw;
    left: 0; }
    #containerPosizione #backportafoglio #text {
      display: flex;
      max-width: 1200px !important;
      width: 100%;
      padding-left: 6px;
      padding-right: 27px;
      margin-right: auto;
      margin-left: auto;
      justify-content: space-between;
      align-items: center; }
      #containerPosizione #backportafoglio #text #bannerNav {
        display: flex;
        color: #FFF;
        font-family: Barlow-semiBold; }
        #containerPosizione #backportafoglio #text #bannerNav h5 {
          padding-right: 30px !important; }
          #containerPosizione #backportafoglio #text #bannerNav h5:last-child {
            padding-right: 0 !important; }
          #containerPosizione #backportafoglio #text #bannerNav h5 a {
            position: relative;
            color: #FFF;
            overflow: hidden; }
            #containerPosizione #backportafoglio #text #bannerNav h5 a a:hover {
              color: #FFF; }
            #containerPosizione #backportafoglio #text #bannerNav h5 a.active:after {
              content: "";
              width: 100%;
              position: absolute;
              height: 4px;
              left: 0;
              bottom: -23px;
              border-radius: 5px;
              background: #FFF; }
    #containerPosizione #backportafoglio .infoPort {
      display: flex;
      align-items: center;
      color: #FFF; }
      #containerPosizione #backportafoglio .infoPort .back {
        width: 16px;
        height: 16px;
        border-radius: 15px;
        background-color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px; }
        #containerPosizione #backportafoglio .infoPort .back svg {
          width: 8px;
          fill: #008998; }
      #containerPosizione #backportafoglio .infoPort .namePort {
        font-size: 14px;
        font-family: Barlow-bold;
        margin-right: 5px; }
      #containerPosizione #backportafoglio .infoPort .idPosition {
        font-size: 14px;
        font-family: Barlow; }
  #containerPosizione #detcont {
    margin-top: 80px; }
    #containerPosizione #detcont .posizione {
      font-family: Barlow;
      color: #455a64;
      font-size: 32px; }
    #containerPosizione #detcont .gbvRequest {
      display: flex;
      align-items: center;
      font-family: Barlow;
      color: #455a64;
      font-size: 24px; }
      #containerPosizione #detcont .gbvRequest .gbv {
        display: flex;
        margin-right: 30px; }
        #containerPosizione #detcont .gbvRequest .gbv span {
          font-size: 14px;
          padding-top: 5px; }
    #containerPosizione #detcont .request {
      display: flex;
      width: 135px;
      height: 40px;
      border-radius: 4px;
      border: solid 2px #455a64;
      font-size: 14px;
      color: #455a64;
      align-items: center;
      justify-content: center; }
      #containerPosizione #detcont .request.disable {
        opacity: .2; }
        #containerPosizione #detcont .request.disable :hover {
          cursor: default; }
      #containerPosizione #detcont .request svg {
        margin-right: 5px;
        fill: #455a64;
        padding-top: 5px; }
      #containerPosizione #detcont .request :hover {
        cursor: pointer; }
    #containerPosizione #detcont .info {
      color: #455a64;
      margin-top: 20px;
      margin-bottom: 40px; }
      #containerPosizione #detcont .info .campo {
        margin-right: 15px; }
        #containerPosizione #detcont .info .campo h5 {
          font-family: Barlow-italic; }
          #containerPosizione #detcont .info .campo h5 span {
            font-family: Barlow-bold; }
    #containerPosizione #detcont .contentAnchor {
      margin-bottom: 25px;
      border-radius: 10px;
      box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
      background-color: white;
      padding: 30px; }
      #containerPosizione #detcont .contentAnchor #stato {
        font-family: Barlow;
        font-weight: normal;
        color: #282f33; }

#form {
  padding: 0px 25px; }
  #form #dataTHelp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-radius: 10px;
    background-color: #e5f8f8;
    padding: 20px; }
    #form #dataTHelp #alertIcon {
      padding: 0 6px; }
    #form #dataTHelp #description {
      font-family: Barlow;
      margin: 0 0 0 20px;
      color: #455a64; }

@media (min-width: 320px) and (max-width: 480px) {
  #containerPosizione #backportafoglio {
    top: 68px;
    overflow: scroll;
    height: initial;
    display: block !important;
    padding: 20px; }
    #containerPosizione #backportafoglio #text {
      width: max-content;
      height: initial; }
      #containerPosizione #backportafoglio #text #bannerNav h5 {
        display: inline-block;
        padding: 0 10px !important; }
        #containerPosizione #backportafoglio #text #bannerNav h5:first-child {
          padding-left: 20px !important; }
        #containerPosizione #backportafoglio #text #bannerNav h5:last-child {
          padding-right: 20px !important; }
        #containerPosizione #backportafoglio #text #bannerNav h5 a {
          text-decoration: none; }
      #containerPosizione #backportafoglio #text #bannerNav h6 {
        display: inline-block;
        padding-right: 20px;
        font-family: Barlow;
        color: #FFF;
        opacity: 0.5; }
  #containerPosizione #bannerDettaglio {
    padding-top: 65px; }
    #containerPosizione #bannerDettaglio .infoPort {
      font-family: Barlow;
      font-size: 12px;
      color: #455a64;
      display: flex; }
      #containerPosizione #bannerDettaglio .infoPort .namePort span {
        font-family: Barlow-bold;
        color: #008996;
        cursor: pointer; }
  #containerPosizione #detcont {
    margin-top: 20px; }
    #containerPosizione #detcont .d-flex {
      flex-direction: column; }
    #containerPosizione #detcont .posizione {
      font-size: 24px;
      font-family: Barlow-med; }
    #containerPosizione #detcont .info {
      margin-top: 0px;
      margin-bottom: 20px;
      justify-content: flex-start; }
      #containerPosizione #detcont .info .campo {
        width: 50%;
        margin-right: 0px; }
      #containerPosizione #detcont .info:first-child {
        margin-top: 20px;
        margin-bottom: 5px; }
    #containerPosizione #detcont .request {
      width: 300px;
      margin-bottom: 20px; }
    #containerPosizione #detcont .contentAnchor {
      padding: initial; }
  .table {
    font-family: Barlow; }
    .table th, .table td {
      width: 50%;
      border-top: none !important;
      color: #000;
      font-size: 12px; }
    .table tr:last-child {
      border-bottom: 1px solid #ececec !important; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #containerPosizione #detcont {
    margin-top: 30px; }
    #containerPosizione #detcont .request {
      font-family: Barlow-bold; }
  .table {
    font-family: Barlow; }
    .table td {
      font-size: 14px;
      width: 70%;
      border-top: none !important;
      color: #000;
      font-size: 12px; }
      .table td:first-child {
        font-size: 12px;
        color: #455a64;
        width: 30%; }
    .table tr:last-child {
      border-bottom: 1px solid #ececec !important; } }
