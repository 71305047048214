.error-area {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  border-color: rgba(255, 0, 0, 0.5);
  border-radius: .25rem; }

.label-question {
  width: 100%;
  margin-bottom: 0px;
  margin-top: 5px; }

.questionAnswer {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
  background: #fff;
  padding: 10px 30px 30px;
  margin-top: 15px;
  font-family: Barlow-semiBold;
  font-size: 15px; }

.wordWrap {
  word-wrap: anywhere; }

.qeTitle {
  border-bottom: 1px solid #f3f4f5;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  width: 100%;
  font-family: Barlow-semiBold;
  font-size: 23px;
  color: #282f33; }

.qeList {
  border-bottom: 1px solid #f3f4f5;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  width: 100%;
  padding-top: 15px; }

.verdeLink {
  color: #008996; }

.questionAnswerInput {
  display: inline-block;
  width: 70%; }

.inputTextQuestionAnswer {
  font-family: Barlow;
  font-size: 1rem; }

@media (min-width: 320px) and (max-width: 768px) {
  .orderInput {
    display: inline-block;
    width: 18%;
    margin-left: 10px; }
  .questionAnswerInput {
    display: inline-block;
    width: 70%; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  .orderInput {
    display: inline-block;
    width: 11%;
    margin-left: 15px; }
  .questionAnswerInput {
    display: inline-block;
    width: 80%; } }

@media (min-width: 1200px) {
  .orderInput {
    display: inline-block;
    width: 5%;
    margin-left: 15px; }
  .questionAnswerInput {
    display: inline-block;
    width: 80%; } }

.bottonQuestion {
  cursor: pointer;
  display: inline-flex;
  margin-left: 15px;
  float: right; }

.spinnerQuestioAnswer {
  width: 100%;
  padding-top: 15px;
  text-align: center; }

#textareaQuestion {
  height: 100px;
  border: none;
  width: 100%; }

#textareaQuestionOrdine {
  height: 30px;
  border: none;
  width: 100%; }

.blu {
  color: #008996 !important; }

.testo {
  line-height: 21px;
  font-family: Barlow-italic;
  color: #292929;
  font-size: 14px;
  padding-left: 5px; }

.itemCollapse {
  color: #008996; }
  .itemCollapse a {
    color: #FFF;
    font-size: 0.8em;
    text-decoration: none; }
  .itemCollapse:hover {
    text-decoration: none;
    color: black; }
    .itemCollapse:hover a {
      color: #008996; }
    .itemCollapse:hover:hover {
      text-decoration: none;
      color: none; }

.m10 {
  margin-right: 10px; }
