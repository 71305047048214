.empty {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .empty .emptyContent {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .empty .emptyContent .circleEmpty {
      width: 80px;
      height: 80px;
      border-radius: 60px;
      background-color: #e5d4ec;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px; }
    .empty .emptyContent p {
      font-size: 24px;
      font-family: Barlow;
      color: #455a64;
      margin-bottom: 10px; }
      .empty .emptyContent p.prova {
        font-size: 14px;
        font-family: Barlow;
        color: #7d299f;
        padding-bottom: 3px;
        border-bottom: 1px solid #7d299f; }
