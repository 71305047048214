@charset "UTF-8";
#search .countPort {
  width: 100%;
  color: #455a64;
  font-family: Barlow;
  padding: 50px 0px; }
  #search .countPort h1 {
    line-height: 1.5em;
    font-size: 2em !important; }
    #search .countPort h1 span {
      color: #008996;
      font-family: Barlow-semiBold; }

#search #filterContainer {
  width: 270px; }

#search #lista {
  width: 870px;
  margin-left: 30px; }
  #search #lista .listPackSelCart {
    position: fixed;
    width: 870px;
    bottom: -110px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
    transition: linear .2s;
    background: white; }
    #search #lista .listPackSelCart .content {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px; }
      #search #lista .listPackSelCart .content .label {
        font-size: 36px; }
        #search #lista .listPackSelCart .content .label span {
          font-family: Barlow;
          color: #008996;
          font-weight: bold;
          margin-right: 10px; }
      #search #lista .listPackSelCart .content .btnDet {
        display: flex;
        height: 40px;
        border-radius: 5px;
        align-items: center;
        padding: 0 16px;
        cursor: pointer; }
        #search #lista .listPackSelCart .content .btnDet.verde {
          color: #FFF;
          background: #008996;
          box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4); }
    #search #lista .listPackSelCart.show {
      bottom: 0px;
      transition: cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.5s;
      z-index: 1; }

@media (min-width: 320px) and (max-width: 480px) {
  #search .countPort {
    padding: 20px 0px 30px 0;
    text-align: center; }
    #search .countPort h1 {
      line-height: initial;
      font-size: 20px !important;
      padding: 0px 30px;
      margin-bottom: 5px !important; }
    #search .countPort h4 {
      font-family: Barlow-italic;
      font-size: 14px !important;
      padding: 0px 25px; }
  #search #btnFiltroMobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0px 20px 20px; }
    #search #btnFiltroMobile .buttonFiltra {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: solid 2px #7d299f;
      background-color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center; }
      #search #btnFiltroMobile .buttonFiltra svg {
        width: 16px;
        fill: #7d299f; }
  #search #lista {
    width: 100% !important;
    margin-left: 0px !important; }
    #search #lista .listPackSelCart {
      width: 100vw;
      left: -10px; }
      #search #lista .listPackSelCart .content {
        flex-direction: column;
        justify-content: center;
        padding: 10px 30px; }
        #search #lista .listPackSelCart .content .label {
          font-size: 16px;
          margin-bottom: 5px; }
    #search #lista .sortMobile {
      justify-content: flex-end;
      margin: 15px 0 20px; }
  .row {
    width: 100%; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #search .countPort {
    text-align: center; }
  #search #btnFiltroMobile {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 20px 20px;
    justify-content: flex-start; }
    #search #btnFiltroMobile .buttonFiltra {
      width: 160px;
      height: 40px;
      border-radius: 4px;
      border: solid 2px #7d299f;
      background-color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center; }
      #search #btnFiltroMobile .buttonFiltra svg {
        width: 16px;
        fill: #7d299f;
        margin-right: 10px; }
      #search #btnFiltroMobile .buttonFiltra:after {
        content: 'Filter Portfolio';
        color: #7d299f;
        font-family: Barlow;
        font-size: 14px; }
  #search #lista {
    margin: 0px !important;
    width: calc(100vw - 40px) !important; }
    #search #lista .listPackSelCart {
      width: calc(100vw - 40px); } }

@media (min-width: 1024px) and (max-width: 1200px) {
  #search #lista {
    margin-left: 30px !important;
    width: calc(930px - 30px - 270px) !important; }
    #search #lista .listPackSelCart {
      width: calc(930px - 30px - 270px) !important; } }

#lista .tab,
#mappa .tab,
#strats .tab {
  box-shadow: 0px 5px 10px #cccccc;
  background-color: #455a64;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07); }

#lista .nav-tab,
#mappa .nav-tab,
#strats .nav-tab {
  font-size: 0.8em;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 15px 0;
  margin: 0 30px; }
  #lista .nav-tab:hover,
  #mappa .nav-tab:hover,
  #strats .nav-tab:hover {
    text-decoration: none; }
  #lista .nav-tab.active,
  #mappa .nav-tab.active,
  #strats .nav-tab.active {
    background-color: #455a64;
    color: #008996;
    cursor: default;
    border-bottom: 3px solid #008996; }

@media (min-width: 320px) and (max-width: 480px) {
  #lista .tab,
  #mappa .tab,
  #strats .tab {
    height: 50px;
    overflow: hidden; }
  #lista .nav-tab,
  #mappa .nav-tab,
  #strats .nav-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: initial;
    width: 25%;
    padding: 8px 0; }
    #lista .nav-tab.active,
    #mappa .nav-tab.active,
    #strats .nav-tab.active {
      background-color: initial; } }

.containerMapSearchTab {
  position: relative;
  margin-top: 20px; }

.card.containerPosition {
  margin: 5px 0 15px;
  border: none; }
  .card.containerPosition .dettaglioPort {
    width: 100%;
    height: 70px;
    background: #FFF;
    border-bottom: 1px solid #e7e7e7; }
    .card.containerPosition .dettaglioPort h2 {
      font-family: Barlow-semiBold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #363a40; }
    .card.containerPosition .dettaglioPort a {
      font-size: .8em;
      color: #008996;
      margin-right: 20px;
      font-family: Barlow;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      padding: 10px 0px;
      border-bottom: 1px solid; }
      .card.containerPosition .dettaglioPort a:hover {
        text-decoration: none; }
  .card.containerPosition .tipo h5 {
    color: #97d9ff; }
  .card.containerPosition h5.info {
    font-weight: bold; }
  .card.containerPosition .idPort {
    margin: 10px 0; }
    .card.containerPosition .idPort .id {
      font-weight: bold; }
  .card.containerPosition .btnDettaglio {
    margin: 20px 0;
    cursor: pointer; }
    .card.containerPosition .btnDettaglio h5 {
      padding: 7px 30px;
      border: 3px solid #888;
      color: #aaa; }
      .card.containerPosition .btnDettaglio h5:hover {
        color: #000; }
  .card.containerPosition .no-padding {
    padding: 0 !important;
    margin: 0 !important; }
    .card.containerPosition .no-padding .map {
      background: #eee;
      width: 100%;
      height: 100%; }

#positionList {
  margin-top: 20px;
  font-family: Barlow;
  border-radius: 10px; }
  #positionList .countFiltered {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 65px;
    align-items: center;
    border-bottom: 1px solid #e7e7e7; }
  #positionList .headerPos {
    height: 50px;
    align-items: center; }
    #positionList .headerPos .colPos {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #455a64;
      padding-right: 5px; }
      #positionList .headerPos .colPos.extraSmall {
        width: 6%; }
      #positionList .headerPos .colPos.small {
        width: 8%; }
      #positionList .headerPos .colPos.medium {
        width: 11%; }
      #positionList .headerPos .colPos.large {
        width: 14%; }
      #positionList .headerPos .colPos.extraLarge {
        width: 15%; }
      #positionList .headerPos .colPos.last {
        width: 3%; }
  #positionList .bodyPos {
    background: #FFF;
    min-height: 90px;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 34, 40, 0.07);
    background-color: #FFF;
    margin: 5px 0;
    flex-wrap: wrap; }
    #positionList .bodyPos.grigio {
      background-color: #f5f8fa; }
      #positionList .bodyPos.grigio .noMatch {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #e8e8ec; }
        #positionList .bodyPos.grigio .noMatch .item {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          border: solid 1px #e8e8ec;
          background-color: #e8e8ec;
          font-family: Barlow;
          font-size: 14px;
          color: #455a64;
          padding: 1px 10px;
          margin: 10px 5px;
          text-decoration: line-through; }
  #positionList .colPos {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-right: 5px;
    color: #000;
    display: flex;
    align-items: center; }
    #positionList .colPos.extraSmall {
      width: 6%; }
    #positionList .colPos.small {
      width: 8%; }
    #positionList .colPos.medium {
      width: 11%; }
    #positionList .colPos.large {
      width: 14%; }
    #positionList .colPos.extraLarge {
      width: 15%; }
    #positionList .colPos.last {
      width: 3%; }
    #positionList .colPos.noBold {
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #6d7278; }
    #positionList .colPos.big {
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #363a40;
      display: flex;
      align-items: flex-start; }
      #positionList .colPos.big:after {
        content: '€';
        font-size: 14px;
        padding-bottom: 1px; }
    #positionList .colPos.proc {
      position: relative;
      display: flex;
      flex-direction: row; }
      #positionList .colPos.proc .containerIcons {
        position: absolute;
        width: 270px;
        padding: 15px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #eceff1;
        display: none;
        background: #FFF;
        z-index: 9999;
        border-radius: 10px;
        top: -180px;
        left: -120px; }
        #positionList .colPos.proc .containerIcons.show {
          display: block; }
        #positionList .colPos.proc .containerIcons .iconProc {
          display: flex;
          margin: 5px 10px;
          font-family: Barlow; }
          #positionList .colPos.proc .containerIcons .iconProc svg {
            margin-right: 10px; }
        #positionList .colPos.proc .containerIcons .close {
          color: #000;
          position: absolute;
          font-weight: 300;
          top: 10px;
          right: 10px;
          font-size: 18px;
          cursor: pointer; }
      #positionList .colPos.proc .circle {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid #dfdfdf;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 7px;
        font-family: Barlow-bold;
        letter-spacing: .5px;
        color: #455a64; }
    #positionList .colPos .detPos {
      width: 10px;
      height: 10px;
      border-top: 2px solid #008996;
      border-right: 2px solid #008996;
      transform: rotate(45deg);
      cursor: pointer; }

@media (min-width: 320px) and (max-width: 480px) {
  #positionList {
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    background: #FFF;
    margin-top: 20px;
    position: relative; }
    #positionList .headerPos {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
    #positionList .mobile {
      width: 100%;
      margin-bottom: 10px; }
      #positionList .mobile a {
        width: 100%; }
      #positionList .mobile .bodyPos {
        width: 100%;
        min-height: initial;
        box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
        flex-wrap: inherit;
        padding: 15px 0;
        margin: initial;
        display: flex;
        flex-direction: column; }
        #positionList .mobile .bodyPos .rowPos {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          #positionList .mobile .bodyPos .rowPos:last-child {
            margin-bottom: 15px; }
          #positionList .mobile .bodyPos .rowPos.header {
            border-bottom: 1px solid #dee0e2;
            padding-bottom: 10px; }
            #positionList .mobile .bodyPos .rowPos.header .colPos {
              margin: 0;
              font-family: Barlow;
              font-size: 12px;
              flex: initial; }
              #positionList .mobile .bodyPos .rowPos.header .colPos span {
                font-family: Barlow-semiBold; }
          #positionList .mobile .bodyPos .rowPos .colPos {
            font-family: Barlow;
            font-size: 14px;
            color: #455a64;
            flex: initial;
            padding-left: 20px;
            margin-top: 15px; }
            #positionList .mobile .bodyPos .rowPos .colPos.value, #positionList .mobile .bodyPos .rowPos .colPos.proc {
              color: #000;
              padding-left: 0px;
              padding-right: 20px;
              text-align: right; }
            #positionList .mobile .bodyPos .rowPos .colPos.big {
              font-family: Barlow-semiBold;
              text-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
              color: #363a40;
              font-size: 20px;
              padding-left: 0px;
              padding-right: 20px; }
            #positionList .mobile .bodyPos .rowPos .colPos.proc .containerIcons {
              left: -235px; }
              #positionList .mobile .bodyPos .rowPos .colPos.proc .containerIcons .iconProc h4 {
                text-align: left; } }

.hashtag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-top: 1px solid #f3f4f5;
  padding-top: 16px; }
  .hashtag .hashtag_first {
    color: #4e5861;
    padding: 0px 10px;
    border-radius: 20px;
    border: 1px solid #4e5861;
    height: 24px;
    margin-right: 8px;
    text-align: center;
    cursor: pointer;
    font-size: 14px; }
  .hashtag .list_size {
    border-radius: 14px;
    border: 1px solid #dedede;
    font-size: 14px;
    color: #000;
    text-align: center;
    padding: 2px 4px;
    cursor: pointer;
    position: relative;
    height: 26px;
    min-width: 26px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .hashtag .list_size span {
      display: block; }
    .hashtag .list_size img {
      display: none; }
    .hashtag .list_size .list {
      display: none;
      text-align: left;
      background: #fff;
      position: absolute;
      padding: 20px;
      border-radius: 20px;
      border: 1px solid #dddddd;
      -webkit-box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
      -moz-box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
      box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
      width: 230px;
      left: -160px;
      bottom: calc(100% + 20px);
      z-index: 9999; }
    .hashtag .list_size:hover span {
      display: none; }
    .hashtag .list_size:hover img {
      display: block; }
    .hashtag .list_size:hover .list {
      display: block; }
      .hashtag .list_size:hover .list:after {
        position: absolute;
        bottom: -10px;
        left: 72%;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 8px 0 8px;
        border-color: #ffffff transparent transparent transparent; }

@media (min-width: 320px) and (max-width: 480px) {
  .hashtag .list_size span {
    display: block !important; }
  .hashtag .list_size img {
    display: none !important; }
  .hashtag .list_size.show span {
    display: none !important; }
  .hashtag .list_size.show img {
    display: block !important; }
  .hashtag .list_size .list {
    display: none !important; }
    .hashtag .list_size .list.show {
      display: block !important; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .hashtag .list_size span {
    display: block !important; }
  .hashtag .list_size img {
    display: none !important; }
  .hashtag .list_size.show span {
    display: none !important; }
  .hashtag .list_size.show img {
    display: block !important; }
  .hashtag .list_size .list {
    display: none !important; }
    .hashtag .list_size .list.show {
      display: block !important; } }

#searchDetail {
  position: relative; }
  #searchDetail #history {
    position: absolute;
    top: -15px;
    background: #008996;
    left: calc((1150px - 100vw)/2);
    width: 100vw;
    height: 290px;
    z-index: 0; }
  #searchDetail #filtroPos {
    margin-top: 40px; }
    #searchDetail #filtroPos #filterContainer {
      background: #FFF;
      max-width: 270px;
      padding-bottom: 40px; }
      #searchDetail #filtroPos #filterContainer .headerFilter {
        margin-bottom: 20px; }
      #searchDetail #filtroPos #filterContainer .filterBox {
        border-bottom: 1px solid rgba(87, 36, 163, 0.1); }
      #searchDetail #filtroPos #filterContainer #collapsetipoDiCreditoValues .solo, #searchDetail #filtroPos #filterContainer #collapsecontroparteValues .solo {
        display: none; }
    #searchDetail #filtroPos #mostraRis {
      display: none;
      width: 100%;
      height: 40px;
      box-shadow: 0 -5px 20px 0 rgba(0, 182, 202, 0.4);
      background: #008996;
      cursor: pointer; }
      #searchDetail #filtroPos #mostraRis h5 {
        font-family: Barlow;
        display: table-cell;
        vertical-align: middle;
        color: #FFF;
        text-align: center; }
  #searchDetail .countPosByPort {
    display: flex;
    height: auto;
    margin: 20px 0;
    align-items: center;
    flex-wrap: wrap; }
    #searchDetail .countPosByPort .buttonFiltra {
      display: none;
      font-family: Barlow-bold;
      color: #7d299f;
      width: 155px;
      background: #FFF;
      padding: 7px 5px;
      border: 2px solid #7d299f;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 20px; }
      #searchDetail .countPosByPort .buttonFiltra svg {
        width: 16px;
        display: inline-block;
        fill: #7d299f;
        margin-right: 5px; }
      #searchDetail .countPosByPort .buttonFiltra h4 {
        display: inline-block;
        margin: 0 auto;
        text-align: center; }
    #searchDetail .countPosByPort .countFiltered {
      font-size: 0.875em;
      font-family: Barlow;
      width: 100%; }
      #searchDetail .countPosByPort .countFiltered span {
        font-family: Barlow-bold; }
    #searchDetail .countPosByPort h3 {
      display: inline-block;
      margin-left: 20px; }
  #searchDetail #positionList .headerPos {
    padding: 0; }

#detailPort {
  display: flex;
  width: 100%;
  z-index: 1;
  margin-top: 45px; }
  #detailPort .info {
    width: 70%;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    background: #FFF;
    padding: 18px 30px 30px 30px;
    margin-right: 15px; }
    #detailPort .info .stato {
      font-family: Barlow-bold;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      #detailPort .info .stato span {
        color: #979da5;
        font-family: Barlow; }
        #detailPort .info .stato span.data {
          margin-left: 10px; }
      #detailPort .info .stato .statoData {
        color: #000; }
        #detailPort .info .stato .statoData .point {
          background: #000; }
        #detailPort .info .stato .statoData .onSale {
          color: #008996; }
          #detailPort .info .stato .statoData .onSale .point {
            background: #008996; }
      #detailPort .info .stato .point {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #008996;
        display: inline-block;
        margin-right: 5px; }
    #detailPort .info .header {
      display: flex;
      justify-content: space-between;
      line-height: 45px;
      margin: 10px 0 14px 0; }
      #detailPort .info .header .titolo {
        font-family: Barlow-med;
        font-size: 40px;
        color: #455a64; }
      #detailPort .info .header .gbv {
        font-family: Barlow;
        font-size: 32px;
        color: #282f33;
        display: flex;
        position: relative; }
        #detailPort .info .header .gbv:after {
          content: '€';
          font-size: 16px;
          padding-bottom: 1px;
          position: absolute;
          right: -12px;
          top: -5px; }
    #detailPort .info .seller, #detailPort .info .description, #detailPort .info .comunicationRef {
      color: #979da5;
      font-family: Barlow;
      margin-bottom: 10px; }
      #detailPort .info .seller span, #detailPort .info .description span, #detailPort .info .comunicationRef span {
        font-family: Barlow-semiBold;
        color: #455a64; }
    #detailPort .info .seller {
      display: flex; }
      #detailPort .info .seller span {
        margin-right: 30px; }
    #detailPort .info .listHomeHashTag {
      padding-bottom: 15px;
      flex-wrap: wrap; }
      #detailPort .info .listHomeHashTag .homeItemHash {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 10px 20px;
        border-radius: 20px;
        background: #fff;
        border: 1px solid #455a64;
        margin: 0 15px 15px;
        cursor: context-menu; }
    #detailPort .info .comunicationRef {
      padding-bottom: 25px; }
    #detailPort .info .buttonsDetail {
      border-top: 1px solid #f3f4f5;
      display: flex;
      justify-content: space-between;
      padding-top: 20px; }
      #detailPort .info .buttonsDetail .request, #detailPort .info .buttonsDetail .add {
        display: flex; }
        #detailPort .info .buttonsDetail .request .btnDet, #detailPort .info .buttonsDetail .add .btnDet {
          display: flex;
          height: 40px;
          border-radius: 4px;
          margin: 10px auto;
          align-items: center;
          padding: 0 16px;
          cursor: pointer; }
          #detailPort .info .buttonsDetail .request .btnDet.disable, #detailPort .info .buttonsDetail .add .btnDet.disable {
            opacity: .2;
            cursor: default; }
          #detailPort .info .buttonsDetail .request .btnDet svg, #detailPort .info .buttonsDetail .add .btnDet svg {
            width: 17px; }
          #detailPort .info .buttonsDetail .request .btnDet.grigio, #detailPort .info .buttonsDetail .add .btnDet.grigio {
            border: solid 2px #455a64;
            background: #FFF;
            color: #455a64; }
            #detailPort .info .buttonsDetail .request .btnDet.grigio:first-child, #detailPort .info .buttonsDetail .add .btnDet.grigio:first-child {
              margin-right: 10px; }
            #detailPort .info .buttonsDetail .request .btnDet.grigio svg, #detailPort .info .buttonsDetail .add .btnDet.grigio svg {
              fill: #455a64; }
          #detailPort .info .buttonsDetail .request .btnDet.verde, #detailPort .info .buttonsDetail .add .btnDet.verde {
            color: #FFF;
            background: #008996;
            box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4); }
          #detailPort .info .buttonsDetail .request .btnDet.pref, #detailPort .info .buttonsDetail .add .btnDet.pref {
            background: #FFF;
            border: solid 2px #008996;
            padding: 0 8px;
            margin-right: 10px; }
            #detailPort .info .buttonsDetail .request .btnDet.pref svg, #detailPort .info .buttonsDetail .add .btnDet.pref svg {
              width: 20px;
              fill: #008996; }
            #detailPort .info .buttonsDetail .request .btnDet.pref.prefered, #detailPort .info .buttonsDetail .add .btnDet.pref.prefered {
              background: #008996; }
              #detailPort .info .buttonsDetail .request .btnDet.pref.prefered svg, #detailPort .info .buttonsDetail .add .btnDet.pref.prefered svg {
                fill: #FFF;
                background: #008996; }
  #detailPort .composition {
    width: 30%;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    background: #FFF;
    padding: 18px 30px 30px 30px;
    margin-left: 15px; }
    #detailPort .composition .titolo {
      padding-bottom: 18px;
      width: 100%;
      border-bottom: 1px solid #f3f4f5; }
    #detailPort .composition .box {
      padding-bottom: 22px;
      border-bottom: 1px solid #f3f4f5;
      display: flex; }
      #detailPort .composition .box:last-child {
        padding-bottom: initial;
        border-bottom: initial; }
      #detailPort .composition .box .type {
        width: 50%;
        display: flex;
        flex-direction: column; }
        #detailPort .composition .box .type h5 {
          font-family: Barlow-italic;
          color: #455a64;
          margin: 18px 0 14px 0 !important; }
        #detailPort .composition .box .type .attributes {
          display: flex; }
          #detailPort .composition .box .type .attributes .quadrato {
            font-family: Barlow-semiBold;
            font-size: 13px;
            border-radius: 4px;
            background-color: #eceeef;
            color: #455a64;
            padding: 1px 3px;
            margin-right: 10px;
            margin-bottom: 5px;
            height: 22px;
            min-width: 22px; }
            #detailPort .composition .box .type .attributes .quadrato.viola {
              background-color: #f1f1fb;
              color: #7b75d3; }
            #detailPort .composition .box .type .attributes .quadrato.verde {
              background-color: #e8f6ec;
              color: #59a669; }
          #detailPort .composition .box .type .attributes .label {
            font-family: Barlow-semiBold;
            font-size: .875em;
            color: #282f33; }
            #detailPort .composition .box .type .attributes .label.noFound {
              font-family: Barlow-italic; }
      #detailPort .composition .box.asset .type {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap; }
        #detailPort .composition .box.asset .type h5 {
          width: 100%; }
        #detailPort .composition .box.asset .type .attributes {
          width: 50%; }

.rc-pagination > li {
  margin-bottom: 5px; }

.rc-pagination .rc-pagination-item, .rc-pagination .rc-pagination-prev, .rc-pagination .rc-pagination-next {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
  border: solid 1px #dcdee2;
  color: #979da5;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center; }
  .rc-pagination .rc-pagination-item.rc-pagination-item-active, .rc-pagination .rc-pagination-prev.rc-pagination-item-active, .rc-pagination .rc-pagination-next.rc-pagination-item-active {
    color: #008996;
    border: solid 2px #008996; }
  .rc-pagination .rc-pagination-item.rc-pagination-disabled, .rc-pagination .rc-pagination-prev.rc-pagination-disabled, .rc-pagination .rc-pagination-next.rc-pagination-disabled {
    opacity: .3; }
  .rc-pagination .rc-pagination-item a, .rc-pagination .rc-pagination-prev a, .rc-pagination .rc-pagination-next a {
    font-family: Barlow-semiBold;
    font-size: 14px; }

.rc-pagination .rc-pagination-prev a, .rc-pagination .rc-pagination-next a {
  font-size: 30px;
  color: #455a64; }

#sort {
  position: relative; }
  #sort.show #other {
    display: block; }
  #sort svg {
    width: 18px;
    fill: #455a64;
    cursor: pointer; }
    #sort svg:hover {
      fill: #000; }
  #sort #selected {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    #sort #selected #colonna {
      cursor: pointer;
      color: #455a64;
      font-family: Barlow-semiBold;
      margin-right: 5px; }
      #sort #selected #colonna:hover {
        color: #000; }
      #sort #selected #colonna span {
        font-family: Barlow;
        font-size: 14px; }
  #sort #other {
    box-shadow: 0 5px 10px 0 rgba(0, 34, 40, 0.07);
    position: absolute;
    top: 30px;
    left: 50px;
    width: 170px;
    display: none;
    z-index: 1;
    background: #f5f8fa; }
    #sort #other .itemOrder {
      width: 100%;
      font-family: Barlow;
      font-size: .875em;
      color: #455a64;
      padding: 5px 10px;
      border-bottom: 1px solid #ebebeb;
      cursor: pointer; }
      #sort #other .itemOrder:hover {
        font-family: Barlow-semiBold; }
    #sort #other:not(focus) {
      display: none; }

#tags {
  margin: 10px 0 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  #tags .tag {
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 16px;
    background-color: #e5d4ec;
    max-width: fit-content;
    display: flex;
    height: 32px;
    padding: 0 10px;
    align-items: center;
    color: #7d299f;
    font-size: 14px;
    font-family: Barlow-semiBold;
    cursor: pointer; }
    #tags .tag svg {
      margin-right: 10px; }
    #tags .tag span {
      font-family: Barlow; }

@media (min-width: 320px) and (max-width: 480px) {
  #searchDetail #history {
    top: -10px;
    left: -10px;
    height: 290px; }
  #searchDetail #filtroPos #filterContainer {
    width: 270px;
    position: fixed;
    left: 0px;
    z-index: 99;
    top: 0;
    height: 100vh;
    overflow: scroll;
    transform: translate(-300px, 0);
    transition: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0;
    overflow-x: hidden;
    border-radius: initial; }
    #searchDetail #filtroPos #filterContainer.showFilter {
      transform: translate(0px, 0);
      transition: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
      opacity: 1; }
    #searchDetail #filtroPos #filterContainer .headerFilter {
      margin-bottom: 20px; }
  #searchDetail .mobileTab {
    padding: 0; }
    #searchDetail .mobileTab .countPosByPort {
      justify-content: space-between;
      flex-wrap: wrap; }
      #searchDetail .mobileTab .countPosByPort .buttonFiltra {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        border: solid 2px #7d299f;
        background-color: #FFF;
        justify-content: center;
        align-items: center;
        padding: initial; }
        #searchDetail .mobileTab .countPosByPort .buttonFiltra svg {
          margin-right: 0px; }
      #searchDetail .mobileTab .countPosByPort .countFiltered {
        width: 80%; }
    #searchDetail .mobileTab #positionList {
      box-shadow: initial;
      background: initial; }
  #detailPort {
    flex-direction: column;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07); }
    #detailPort .info {
      width: 100%;
      margin-right: initial;
      background: initial;
      box-shadow: initial;
      padding: 15px 20px 0px 20px; }
      #detailPort .info.btn {
        padding: 15px 10px 0px 10px; }
      #detailPort .info .header {
        line-height: 28px;
        flex-direction: column; }
        #detailPort .info .header .titolo {
          font-size: 24px; }
        #detailPort .info .header .gbv {
          font-size: 20px; }
          #detailPort .info .header .gbv:after {
            padding-bottom: initial;
            position: initial;
            right: initial;
            top: initial; }
      #detailPort .info .seller {
        display: initial; }
        #detailPort .info .seller span {
          margin-right: initial; }
      #detailPort .info .comunicationRef {
        padding-bottom: 20px; }
      #detailPort .info .buttonsDetail {
        flex-direction: column; }
        #detailPort .info .buttonsDetail .request {
          flex-direction: column; }
          #detailPort .info .buttonsDetail .request .btnDet {
            width: 100%;
            margin: 5px auto;
            justify-content: center; }
        #detailPort .info .buttonsDetail .add {
          padding: 0px;
          justify-content: space-evenly; }
          #detailPort .info .buttonsDetail .add .btnDet.verde {
            font-family: Barlow;
            margin: 5px 0px;
            padding: 0 10px; }
          #detailPort .info .buttonsDetail .add .btnDet.pref {
            margin: 5px 5px 5px 0px; }
      #detailPort .info .listHomeHashTag .homeItemHash {
        margin: 0 5px 15px; }
    #detailPort .composition {
      width: 100%;
      margin-left: 0;
      background: initial;
      box-shadow: initial;
      padding: 15px 20px 0px 20px; }
      #detailPort .composition .titolo {
        padding-bottom: initial;
        border: initial; }
      #detailPort .composition .box .type .attributes .label {
        font-family: Barlow-med; }
  #tags {
    overflow: auto;
    flex-wrap: nowrap;
    padding-bottom: 15px;
    position: relative; }
    #tags .tag, #tags div, #tags span {
      display: flex;
      flex-wrap: nowrap;
      width: max-content; }
  #sort {
    width: 100%; }
    #sort #selected {
      justify-content: flex-end; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #searchDetail #history {
    left: calc((100% - 100vw)/2); }
  #searchDetail #detailPort {
    flex-direction: column; }
    #searchDetail #detailPort .info {
      width: 100%;
      border-radius: 10px 10px 0 0;
      padding: 18px 30px 20px 30px;
      margin-right: 0; }
      #searchDetail #detailPort .info .seller {
        display: flex; }
        #searchDetail #detailPort .info .seller h5 {
          margin-right: 40px !important; }
    #searchDetail #detailPort .composition {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-left: 0;
      border-radius: 0 0 10px 10px;
      padding-top: 30px; }
      #searchDetail #detailPort .composition .titolo {
        top: 15px;
        font-family: Barlow-SemiBold;
        position: absolute;
        border: none;
        width: initial; }
      #searchDetail #detailPort .composition .box {
        width: 50%; }
  #searchDetail #filtroPos #filterContainer {
    width: 270px;
    position: fixed;
    left: 0px;
    z-index: 99;
    top: 0;
    height: 100vh;
    overflow: scroll;
    transform: translate(-300px, 0);
    transition: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0;
    overflow-x: hidden;
    border-radius: initial; }
    #searchDetail #filtroPos #filterContainer.showFilter {
      transform: translate(0px, 0);
      transition: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
      opacity: 1; }
    #searchDetail #filtroPos #filterContainer .headerFilter {
      margin-bottom: 20px; }
  #searchDetail .mobileTab {
    padding: 0; }
    #searchDetail .mobileTab .countPosByPort {
      justify-content: space-between;
      flex-wrap: wrap; }
      #searchDetail .mobileTab .countPosByPort .buttonFiltra {
        width: 160px;
        height: 40px;
        border-radius: 4px;
        border: solid 2px #7d299f;
        background-color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center; }
        #searchDetail .mobileTab .countPosByPort .buttonFiltra svg {
          width: 16px;
          fill: #7d299f;
          margin-right: 10px; }
        #searchDetail .mobileTab .countPosByPort .buttonFiltra:after {
          content: 'Filter Portfolio';
          color: #7d299f;
          font-family: Barlow;
          font-size: 14px; }
      #searchDetail .mobileTab .countPosByPort .countFiltered {
        width: 60%; }
    #searchDetail .mobileTab #positionList {
      box-shadow: initial;
      background: initial; } }
