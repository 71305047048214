#guida {
  max-width: 1366px;
  margin: 0 auto; }
  #guida .fasciaBanner, #guida .fasciaBrowse, #guida .fasciaDesc, #guida .fasciaExplore, #guida .fasciaReview, #guida .fasciaRequest, #guida .fasciaMark, #guida .fasciaFrequently {
    padding: 150px 95px 170px;
    position: relative;
    background-color: #008996;
    display: flex;
    justify-content: space-between; }
    #guida .fasciaBanner .des, #guida .fasciaBrowse .des, #guida .fasciaDesc .des, #guida .fasciaExplore .des, #guida .fasciaReview .des, #guida .fasciaRequest .des, #guida .fasciaMark .des, #guida .fasciaFrequently .des {
      position: relative;
      z-index: 3;
      width: 50%;
      padding-right: 30px;
      display: flex;
      flex-direction: column; }
      #guida .fasciaBanner .des .ti, #guida .fasciaBrowse .des .ti, #guida .fasciaDesc .des .ti, #guida .fasciaExplore .des .ti, #guida .fasciaReview .des .ti, #guida .fasciaRequest .des .ti, #guida .fasciaMark .des .ti, #guida .fasciaFrequently .des .ti {
        font-family: Barlow;
        font-size: 48px;
        color: white;
        margin-bottom: 10px; }
      #guida .fasciaBanner .des .text, #guida .fasciaBrowse .des .text, #guida .fasciaDesc .des .text, #guida .fasciaExplore .des .text, #guida .fasciaReview .des .text, #guida .fasciaRequest .des .text, #guida .fasciaMark .des .text, #guida .fasciaFrequently .des .text {
        font-family: Barlow;
        font-size: 16px;
        color: white;
        margin-bottom: 30px; }
      #guida .fasciaBanner .des .link a, #guida .fasciaBrowse .des .link a, #guida .fasciaDesc .des .link a, #guida .fasciaExplore .des .link a, #guida .fasciaReview .des .link a, #guida .fasciaRequest .des .link a, #guida .fasciaMark .des .link a, #guida .fasciaFrequently .des .link a {
        font-family: Barlow;
        text-decoration: none;
        color: #008996;
        padding-bottom: 5px;
        border-bottom: 2px solid #008996;
        font-size: 14px; }
        #guida .fasciaBanner .des .link a:hover, #guida .fasciaBrowse .des .link a:hover, #guida .fasciaDesc .des .link a:hover, #guida .fasciaExplore .des .link a:hover, #guida .fasciaReview .des .link a:hover, #guida .fasciaRequest .des .link a:hover, #guida .fasciaMark .des .link a:hover, #guida .fasciaFrequently .des .link a:hover {
          color: #008996; }
    #guida .fasciaBanner .ways, #guida .fasciaBrowse .ways, #guida .fasciaDesc .ways, #guida .fasciaExplore .ways, #guida .fasciaReview .ways, #guida .fasciaRequest .ways, #guida .fasciaMark .ways, #guida .fasciaFrequently .ways {
      max-width: 370px;
      font-family: Barlow-semiBold;
      font-size: 20px;
      color: #fefefe; }
      #guida .fasciaBanner .ways svg, #guida .fasciaBrowse .ways svg, #guida .fasciaDesc .ways svg, #guida .fasciaExplore .ways svg, #guida .fasciaReview .ways svg, #guida .fasciaRequest .ways svg, #guida .fasciaMark .ways svg, #guida .fasciaFrequently .ways svg {
        margin-right: 12px; }
      #guida .fasciaBanner .ways .testo, #guida .fasciaBrowse .ways .testo, #guida .fasciaDesc .ways .testo, #guida .fasciaExplore .ways .testo, #guida .fasciaReview .ways .testo, #guida .fasciaRequest .ways .testo, #guida .fasciaMark .ways .testo, #guida .fasciaFrequently .ways .testo {
        line-height: 21px;
        font-family: Barlow-italic;
        color: #FFF;
        font-size: 14px;
        padding-left: 30px; }
      #guida .fasciaBanner .ways .type, #guida .fasciaBrowse .ways .type, #guida .fasciaDesc .ways .type, #guida .fasciaExplore .ways .type, #guida .fasciaReview .ways .type, #guida .fasciaRequest .ways .type, #guida .fasciaMark .ways .type, #guida .fasciaFrequently .ways .type {
        position: relative;
        margin-bottom: 20px;
        padding-bottom: 20px; }
        #guida .fasciaBanner .ways .type:after, #guida .fasciaBrowse .ways .type:after, #guida .fasciaDesc .ways .type:after, #guida .fasciaExplore .ways .type:after, #guida .fasciaReview .ways .type:after, #guida .fasciaRequest .ways .type:after, #guida .fasciaMark .ways .type:after, #guida .fasciaFrequently .ways .type:after {
          content: '';
          width: 370px;
          height: 1px;
          opacity: 0.4;
          background-image: linear-gradient(to left, rgba(255, 255, 255, 0), var(--white) 48%, rgba(255, 255, 255, 0));
          position: absolute;
          left: 0;
          bottom: 0; }
        #guida .fasciaBanner .ways .type:last-child, #guida .fasciaBrowse .ways .type:last-child, #guida .fasciaDesc .ways .type:last-child, #guida .fasciaExplore .ways .type:last-child, #guida .fasciaReview .ways .type:last-child, #guida .fasciaRequest .ways .type:last-child, #guida .fasciaMark .ways .type:last-child, #guida .fasciaFrequently .ways .type:last-child {
          margin-bottom: 0px;
          padding-bottom: 0px; }
          #guida .fasciaBanner .ways .type:last-child:after, #guida .fasciaBrowse .ways .type:last-child:after, #guida .fasciaDesc .ways .type:last-child:after, #guida .fasciaExplore .ways .type:last-child:after, #guida .fasciaReview .ways .type:last-child:after, #guida .fasciaRequest .ways .type:last-child:after, #guida .fasciaMark .ways .type:last-child:after, #guida .fasciaFrequently .ways .type:last-child:after {
            content: initial; }
        #guida .fasciaBanner .ways .type .exploreCollapse a, #guida .fasciaBrowse .ways .type .exploreCollapse a, #guida .fasciaDesc .ways .type .exploreCollapse a, #guida .fasciaExplore .ways .type .exploreCollapse a, #guida .fasciaReview .ways .type .exploreCollapse a, #guida .fasciaRequest .ways .type .exploreCollapse a, #guida .fasciaMark .ways .type .exploreCollapse a, #guida .fasciaFrequently .ways .type .exploreCollapse a {
          text-decoration: none;
          color: #FFF; }
          #guida .fasciaBanner .ways .type .exploreCollapse a.blu, #guida .fasciaBrowse .ways .type .exploreCollapse a.blu, #guida .fasciaDesc .ways .type .exploreCollapse a.blu, #guida .fasciaExplore .ways .type .exploreCollapse a.blu, #guida .fasciaReview .ways .type .exploreCollapse a.blu, #guida .fasciaRequest .ways .type .exploreCollapse a.blu, #guida .fasciaMark .ways .type .exploreCollapse a.blu, #guida .fasciaFrequently .ways .type .exploreCollapse a.blu {
            color: #008996; }
            #guida .fasciaBanner .ways .type .exploreCollapse a.blu svg, #guida .fasciaBrowse .ways .type .exploreCollapse a.blu svg, #guida .fasciaDesc .ways .type .exploreCollapse a.blu svg, #guida .fasciaExplore .ways .type .exploreCollapse a.blu svg, #guida .fasciaReview .ways .type .exploreCollapse a.blu svg, #guida .fasciaRequest .ways .type .exploreCollapse a.blu svg, #guida .fasciaMark .ways .type .exploreCollapse a.blu svg, #guida .fasciaFrequently .ways .type .exploreCollapse a.blu svg {
              fill: #008996; }
          #guida .fasciaBanner .ways .type .exploreCollapse a:hover, #guida .fasciaBrowse .ways .type .exploreCollapse a:hover, #guida .fasciaDesc .ways .type .exploreCollapse a:hover, #guida .fasciaExplore .ways .type .exploreCollapse a:hover, #guida .fasciaReview .ways .type .exploreCollapse a:hover, #guida .fasciaRequest .ways .type .exploreCollapse a:hover, #guida .fasciaMark .ways .type .exploreCollapse a:hover, #guida .fasciaFrequently .ways .type .exploreCollapse a:hover {
            text-decoration: none; }
    #guida .fasciaBanner .image, #guida .fasciaBrowse .image, #guida .fasciaDesc .image, #guida .fasciaExplore .image, #guida .fasciaReview .image, #guida .fasciaRequest .image, #guida .fasciaMark .image, #guida .fasciaFrequently .image {
      width: 50%;
      position: relative;
      z-index: 2; }
      #guida .fasciaBanner .image img, #guida .fasciaBrowse .image img, #guida .fasciaDesc .image img, #guida .fasciaExplore .image img, #guida .fasciaReview .image img, #guida .fasciaRequest .image img, #guida .fasciaMark .image img, #guida .fasciaFrequently .image img {
        position: absolute;
        right: -95px;
        width: 115%;
        box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1); }
  #guida .fasciaBanner .image img {
    top: -20px;
    box-shadow: initial; }
  #guida .fasciaDesc {
    padding: 70px 95px 80px;
    align-items: center;
    background: #FFF;
    display: flex;
    justify-content: center; }
    #guida .fasciaDesc:before {
      content: '';
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      height: calc(35%);
      background: white;
      -webkit-transform: skewY(-4deg);
      -moz-transform: skewY(-4deg);
      -ms-transform: skewY(-4deg);
      -o-transform: skewY(-4deg);
      transform: skewY(-4deg);
      z-index: 2; }
    #guida .fasciaDesc .des {
      width: 770px;
      padding-right: 0px; }
      #guida .fasciaDesc .des .text {
        font-family: Barlow;
        font-size: 16px;
        color: #000;
        margin-bottom: 30px; }
  #guida .fasciaBrowse {
    padding: 100px 95px 100px;
    align-items: center;
    background: #F5F8FA; }
    #guida .fasciaBrowse .des {
      padding-left: 80px; }
      #guida .fasciaBrowse .des .ti {
        font-size: 32px;
        color: black; }
      #guida .fasciaBrowse .des .text {
        font-size: 14px;
        color: #000; }
    #guida .fasciaBrowse .image {
      width: 50%; }
      #guida .fasciaBrowse .image img {
        position: initial;
        width: 100%; }
    #guida .fasciaBrowse .circleQ {
      z-index: 2; }
      #guida .fasciaBrowse .circleQ img {
        width: 55px;
        height: 55px; }
  #guida .fasciaExplore {
    padding: 100px 95px 120px 120px;
    background: #465a65; }
    #guida .fasciaExplore .des .ti {
      font-family: Barlow;
      font-size: 32px;
      color: #FFF;
      margin-bottom: 30px; }
    #guida .fasciaExplore .des .text {
      font-size: 14px; }
    #guida .fasciaExplore .image {
      padding-top: 30px;
      width: 50%;
      position: relative;
      z-index: 2; }
      #guida .fasciaExplore .image img {
        position: absolute;
        right: -30px;
        width: 100%; }
  #guida .fasciaReview, #guida .fasciaMark {
    align-items: center;
    background: #f5f8fa;
    justify-content: end;
    overflow: hidden; }
    #guida .fasciaReview .des, #guida .fasciaMark .des {
      max-width: 470px;
      margin-left: 130px;
      padding: 0; }
      #guida .fasciaReview .des .ti, #guida .fasciaMark .des .ti {
        font-size: 32px;
        color: #000; }
      #guida .fasciaReview .des .text, #guida .fasciaMark .des .text {
        position: relative;
        margin-bottom: 20px;
        padding-bottom: 20px;
        color: #000000;
        font-size: 14px; }
        #guida .fasciaReview .des .text:after, #guida .fasciaMark .des .text:after {
          content: '';
          width: 370px;
          height: 1px;
          opacity: 0.4;
          background-image: linear-gradient(to left, rgba(70, 90, 101, 0), #465a65 48%, rgba(70, 90, 101, 0));
          position: absolute;
          left: 0;
          bottom: 0; }
    #guida .fasciaReview .ways .testo, #guida .fasciaMark .ways .testo {
      color: #000;
      margin-bottom: 10px; }
    #guida .fasciaReview .ways .type:after, #guida .fasciaMark .ways .type:after {
      background-image: linear-gradient(to left, rgba(70, 90, 101, 0), #465a65 48%, rgba(70, 90, 101, 0)); }
    #guida .fasciaReview .ways .type .exploreCollapse a, #guida .fasciaMark .ways .type .exploreCollapse a {
      color: #000; }
      #guida .fasciaReview .ways .type .exploreCollapse a:hover, #guida .fasciaMark .ways .type .exploreCollapse a:hover {
        text-decoration: none; }
    #guida .fasciaReview .image, #guida .fasciaMark .image {
      width: 50%;
      position: relative;
      z-index: 2; }
      #guida .fasciaReview .image img, #guida .fasciaMark .image img {
        position: initial;
        max-width: 570px;
        box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1); }
  #guida .fasciaRequest {
    padding: 70px 95px 120px 95px;
    background: #008996;
    overflow: hidden; }
    #guida .fasciaRequest .des {
      max-width: 470px;
      width: 60%;
      padding-right: 0; }
      #guida .fasciaRequest .des .ti {
        font-size: 32px; }
      #guida .fasciaRequest .des .text {
        font-size: 14px; }
      #guida .fasciaRequest .des .link a {
        font-family: Barlow-bold;
        color: #FFF;
        border-bottom: 2px solid #FFF; }
        #guida .fasciaRequest .des .link a:hover {
          color: #FFF; }
      #guida .fasciaRequest .des .box {
        margin-top: 40px;
        width: 470px;
        height: 88px;
        border-radius: 10px;
        background-color: #3397a1;
        display: flex;
        padding: 0 20px; }
        #guida .fasciaRequest .des .box .content {
          display: flex;
          align-items: center; }
          #guida .fasciaRequest .des .box .content .info {
            font-family: Barlow;
            font-size: 14px;
            color: #FFF;
            margin-left: 20px; }
            #guida .fasciaRequest .des .box .content .info a {
              text-decoration: underline;
              color: #FFF; }
              #guida .fasciaRequest .des .box .content .info a:hover {
                color: #FFF; }
    #guida .fasciaRequest .image {
      width: 40%;
      transform: rotate(4deg); }
      #guida .fasciaRequest .image img {
        transform: rotate(-4deg);
        top: -90px;
        left: 35px;
        width: 850px;
        box-shadow: initial; }
  #guida .fasciaMark .des .text:after {
    content: initial; }
  #guida .fasciaFrequently {
    padding-top: 120px;
    padding-bottom: 50px;
    background: #FFF; }
    #guida .fasciaFrequently .ask {
      max-width: 970px;
      margin: 0 auto;
      margin-bottom: 60px; }
      #guida .fasciaFrequently .ask .title {
        padding-bottom: 60px;
        text-align: center;
        font-size: 32px;
        font-family: Barlow;
        margin-bottom: 20px;
        color: #000; }
      #guida .fasciaFrequently .ask .containerItem {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        padding: 10px; }
        #guida .fasciaFrequently .ask .containerItem.active {
          border: 1px solid #008996; }
        #guida .fasciaFrequently .ask .containerItem .item {
          border-radius: 10px;
          width: 100%;
          height: 80px;
          background-color: #FFF;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 0.4s; }
          #guida .fasciaFrequently .ask .containerItem .item .testo {
            font-family: Barlow-semiBold;
            font-size: 16px;
            color: #282f33; }
          #guida .fasciaFrequently .ask .containerItem .item .plus {
            width: 30px;
            height: 30px;
            background: #e5f8f8;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            cursor: pointer; }
        #guida .fasciaFrequently .ask .containerItem .panel {
          background-color: #f5f8fa;
          border-radius: 10px;
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.2s ease-out; }
          #guida .fasciaFrequently .ask .containerItem .panel .testo {
            padding: 10px 20px 10px; }
            #guida .fasciaFrequently .ask .containerItem .panel .testo:first-child {
              padding: 20px 20px 10px; }
            #guida .fasciaFrequently .ask .containerItem .panel .testo:last-child {
              padding: 10px 20px 20px; }
  #guida .end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    #guida .end .ancora {
      align-items: center;
      font-size: 24px;
      font-family: Barlow;
      margin-bottom: 30px;
      margin-top: 50px; }
    #guida .end .assistenza {
      width: 270px;
      height: 40px;
      border-radius: 4px;
      box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4);
      background-color: #008996;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      font-size: 14px;
      font-family: Barlow;
      margin-bottom: 30px;
      cursor: pointer; }

@media (min-width: 320px) and (max-width: 480px) {
  #guida {
    max-width: 100%;
    margin: 0 auto; }
    #guida .fasciaBanner, #guida .fasciaBrowse, #guida .fasciaDesc, #guida .fasciaExplore, #guida .fasciaReview, #guida .fasciaRequest, #guida .fasciaMark, #guida .fasciaFrequently {
      padding: 80px 20px 10px;
      justify-content: center;
      flex-direction: column; }
      #guida .fasciaBanner .des, #guida .fasciaBrowse .des, #guida .fasciaDesc .des, #guida .fasciaExplore .des, #guida .fasciaReview .des, #guida .fasciaRequest .des, #guida .fasciaMark .des, #guida .fasciaFrequently .des {
        width: 100%;
        padding-right: 0px;
        text-align: center; }
        #guida .fasciaBanner .des .ti, #guida .fasciaBrowse .des .ti, #guida .fasciaDesc .des .ti, #guida .fasciaExplore .des .ti, #guida .fasciaReview .des .ti, #guida .fasciaRequest .des .ti, #guida .fasciaMark .des .ti, #guida .fasciaFrequently .des .ti {
          font-size: 24px; }
        #guida .fasciaBanner .des .text, #guida .fasciaBrowse .des .text, #guida .fasciaDesc .des .text, #guida .fasciaExplore .des .text, #guida .fasciaReview .des .text, #guida .fasciaRequest .des .text, #guida .fasciaMark .des .text, #guida .fasciaFrequently .des .text {
          font-size: 14px;
          margin-bottom: 20px; }
        #guida .fasciaBanner .des .link, #guida .fasciaBrowse .des .link, #guida .fasciaDesc .des .link, #guida .fasciaExplore .des .link, #guida .fasciaReview .des .link, #guida .fasciaRequest .des .link, #guida .fasciaMark .des .link, #guida .fasciaFrequently .des .link {
          text-align: left;
          margin-bottom: 20px; }
          #guida .fasciaBanner .des .link a, #guida .fasciaBrowse .des .link a, #guida .fasciaDesc .des .link a, #guida .fasciaExplore .des .link a, #guida .fasciaReview .des .link a, #guida .fasciaRequest .des .link a, #guida .fasciaMark .des .link a, #guida .fasciaFrequently .des .link a {
            font-family: Barlow-SemiBold; }
      #guida .fasciaBanner .ways, #guida .fasciaBrowse .ways, #guida .fasciaDesc .ways, #guida .fasciaExplore .ways, #guida .fasciaReview .ways, #guida .fasciaRequest .ways, #guida .fasciaMark .ways, #guida .fasciaFrequently .ways {
        max-width: 100%;
        font-size: 16px; }
        #guida .fasciaBanner .ways svg, #guida .fasciaBrowse .ways svg, #guida .fasciaDesc .ways svg, #guida .fasciaExplore .ways svg, #guida .fasciaReview .ways svg, #guida .fasciaRequest .ways svg, #guida .fasciaMark .ways svg, #guida .fasciaFrequently .ways svg {
          margin-right: 12px; }
        #guida .fasciaBanner .ways .type:after, #guida .fasciaBrowse .ways .type:after, #guida .fasciaDesc .ways .type:after, #guida .fasciaExplore .ways .type:after, #guida .fasciaReview .ways .type:after, #guida .fasciaRequest .ways .type:after, #guida .fasciaMark .ways .type:after, #guida .fasciaFrequently .ways .type:after {
          width: 100%; }
        #guida .fasciaBanner .ways .type:last-child, #guida .fasciaBrowse .ways .type:last-child, #guida .fasciaDesc .ways .type:last-child, #guida .fasciaExplore .ways .type:last-child, #guida .fasciaReview .ways .type:last-child, #guida .fasciaRequest .ways .type:last-child, #guida .fasciaMark .ways .type:last-child, #guida .fasciaFrequently .ways .type:last-child {
          margin-bottom: 20px; }
      #guida .fasciaBanner .image, #guida .fasciaBrowse .image, #guida .fasciaDesc .image, #guida .fasciaExplore .image, #guida .fasciaReview .image, #guida .fasciaRequest .image, #guida .fasciaMark .image, #guida .fasciaFrequently .image {
        width: 100%; }
        #guida .fasciaBanner .image img, #guida .fasciaBrowse .image img, #guida .fasciaDesc .image img, #guida .fasciaExplore .image img, #guida .fasciaReview .image img, #guida .fasciaRequest .image img, #guida .fasciaMark .image img, #guida .fasciaFrequently .image img {
          position: initial;
          width: 100%; }
    #guida .fasciaDesc {
      padding: 0px 20px 20px; }
      #guida .fasciaDesc:before {
        top: -45px; }
      #guida .fasciaDesc .des {
        text-align: left; }
        #guida .fasciaDesc .des .text {
          margin-bottom: 10px; }
    #guida .fasciaBrowse, #guida .fasciaExplore, #guida .fasciaReview, #guida .fasciaRequest, #guida .fasciaMark {
      padding: 20px 20px 10px; }
      #guida .fasciaBrowse .des, #guida .fasciaExplore .des, #guida .fasciaReview .des, #guida .fasciaRequest .des, #guida .fasciaMark .des {
        text-align: left;
        padding-left: 0px; }
        #guida .fasciaBrowse .des .ti, #guida .fasciaExplore .des .ti, #guida .fasciaReview .des .ti, #guida .fasciaRequest .des .ti, #guida .fasciaMark .des .ti {
          font-size: 20px;
          margin-bottom: 20px;
          margin-top: 10px; }
        #guida .fasciaBrowse .des .text, #guida .fasciaExplore .des .text, #guida .fasciaReview .des .text, #guida .fasciaRequest .des .text, #guida .fasciaMark .des .text {
          margin-bottom: 10px; }
      #guida .fasciaBrowse .image, #guida .fasciaExplore .image, #guida .fasciaReview .image, #guida .fasciaRequest .image, #guida .fasciaMark .image {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px; }
    #guida .fasciaExplore {
      padding: 30px 20px 10px; }
      #guida .fasciaExplore .image {
        padding-top: 10px; }
    #guida .fasciaReview .image {
      margin-bottom: 0;
      margin-top: 20px; }
    #guida .fasciaReview .des {
      max-width: 100%;
      margin-left: 0px; }
      #guida .fasciaReview .des .text:after {
        width: 100%; }
    #guida .fasciaReview .ways {
      padding-top: 10px; }
    #guida .fasciaRequest {
      background: #007e8a; }
      #guida .fasciaRequest .image {
        transform: none;
        box-shadow: none;
        margin-bottom: 0; }
        #guida .fasciaRequest .image img {
          transform: none;
          margin-left: 20px;
          margin-top: -20px; }
      #guida .fasciaRequest .des .box {
        width: 100%;
        margin-top: 10px;
        height: 100%;
        padding: 10px 20px 10px 10px;
        margin-bottom: 10px; }
    #guida .fasciaMark .des {
      max-width: 100%;
      margin-left: 0px; }
      #guida .fasciaMark .des .text {
        padding-bottom: 0px; }
    #guida .end .ancora {
      font-size: 16px;
      text-align: center;
      width: 150px;
      margin-top: 20px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #guida {
    max-width: 100%;
    margin: 0 auto; }
    #guida .fasciaBanner, #guida .fasciaBrowse, #guida .fasciaDesc, #guida .fasciaExplore, #guida .fasciaReview, #guida .fasciaRequest, #guida .fasciaMark, #guida .fasciaFrequently {
      padding: 150px 20px 40px; }
      #guida .fasciaBanner .des .ti, #guida .fasciaBrowse .des .ti, #guida .fasciaDesc .des .ti, #guida .fasciaExplore .des .ti, #guida .fasciaReview .des .ti, #guida .fasciaRequest .des .ti, #guida .fasciaMark .des .ti, #guida .fasciaFrequently .des .ti {
        font-size: 32px; }
      #guida .fasciaBanner .image img, #guida .fasciaBrowse .image img, #guida .fasciaDesc .image img, #guida .fasciaExplore .image img, #guida .fasciaReview .image img, #guida .fasciaRequest .image img, #guida .fasciaMark .image img, #guida .fasciaFrequently .image img {
        right: -20px;
        width: 100%; }
    #guida .fasciaDesc {
      padding: 20px 20px 20px; }
      #guida .fasciaDesc:before {
        height: 45%; }
      #guida .fasciaDesc .des {
        width: 600px; }
        #guida .fasciaDesc .des .ti {
          font-size: 24px; }
        #guida .fasciaDesc .des .text {
          margin-bottom: 10px; }
    #guida .fasciaBrowse {
      padding: 40px 20px 30px; }
      #guida .fasciaBrowse .des {
        padding: 20px;
        width: 100%; }
        #guida .fasciaBrowse .des .ti {
          font-size: 24px; }
        #guida .fasciaBrowse .des .text {
          width: 100%; }
          #guida .fasciaBrowse .des .text .image {
            float: left;
            width: 350px;
            margin: 10px 20px 20px 0px; }
    #guida .fasciaExplore {
      padding: 50px 20px 30px; }
      #guida .fasciaExplore .des {
        padding: 0 20px;
        width: 100%; }
        #guida .fasciaExplore .des .ti {
          font-size: 24px; }
        #guida .fasciaExplore .des .text {
          width: 100%; }
          #guida .fasciaExplore .des .text .image {
            width: 350px;
            float: left;
            margin: 0px 20px 10px 0px;
            padding: 0; }
            #guida .fasciaExplore .des .text .image img {
              position: initial; }
        #guida .fasciaExplore .des .ways {
          max-width: 100%; }
          #guida .fasciaExplore .des .ways .type:after {
            width: 100%; }
    #guida .fasciaReview {
      padding: 50px 20px 50px; }
      #guida .fasciaReview .des {
        margin-left: 30px; }
        #guida .fasciaReview .des .ti {
          font-size: 24px; }
    #guida .fasciaRequest {
      padding: 50px 20px 50px; }
      #guida .fasciaRequest .des {
        max-width: 100%;
        width: 50%;
        padding-right: 0; }
        #guida .fasciaRequest .des .ti {
          font-size: 24px; }
      #guida .fasciaRequest .image {
        width: 50%;
        transform: initial; }
        #guida .fasciaRequest .image img {
          transform: initial;
          top: 100px;
          left: 20px; }
    #guida .fasciaMark {
      flex-wrap: wrap;
      padding: 50px 20px;
      align-items: flex-start; }
      #guida .fasciaMark .des {
        width: 100%;
        max-width: 100%;
        margin: 0; }
        #guida .fasciaMark .des .ti {
          font-size: 24px; }
        #guida .fasciaMark .des:last-child {
          width: 60%;
          padding: 0 20px; }
          #guida .fasciaMark .des:last-child .text {
            padding-bottom: 0px; }
            #guida .fasciaMark .des:last-child .text p {
              margin-bottom: 10px; }
      #guida .fasciaMark .image {
        width: 40%; } }
