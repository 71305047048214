.row, col-12, container {
  display: flex; }

.headerCarrello {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px; }
  .headerCarrello .title {
    font-family: Barlow;
    font-size: 32px;
    color: #282f33; }

.procedi {
  display: flex;
  justify-content: center;
  align-items: center; }
  .procedi button {
    padding: 7px 30px;
    background-color: #979da5;
    border: none;
    border-radius: 4px;
    color: #aaa; }
    .procedi button:focus {
      outline: none; }
    .procedi button:enabled {
      height: 40px;
      width: 203px;
      background-color: #008996;
      color: white;
      box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4); }

#delbutton {
  border: none;
  background: lightgrey;
  box-shadow: none;
  border-radius: 0px; }

#button {
  text-align: center; }

.star {
  color: yellow; }

.form-group textarea.form-control {
  resize: none;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important; }

.carrelloheader #buttonann {
  float: left;
  border: 1px;
  margin-bottom: 25px; }

.carrelloheader #buttoncont {
  float: right;
  background-color: #008996;
  border: none; }

.react-confirm-alert-overlay {
  background: rgba(69, 90, 100, 0.8) !important; }
  .react-confirm-alert-overlay .react-confirm-alert-blur {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background: rgba(69, 90, 100, 0.8); }

.react-confirm-alert {
  max-height: 85vh;
  overflow: hidden;
  overflow-y: auto;
  font-family: Barlow;
  border-radius: 10px;
  max-width: 700px;
  box-shadow: 0 60px 100px 0 rgba(69, 90, 100, 0.5);
  text-align: left;
  background-color: white;
  /* Let's get this party started */
  /* Track */
  /* Handle */ }
  .react-confirm-alert .question {
    font-family: Barlow;
    font-size: 14px; }
    .react-confirm-alert .question a {
      font-family: Barlow;
      text-decoration: none;
      color: #008996;
      margin-bottom: 4px;
      padding-bottom: 4px;
      border-bottom: 2px solid #008996;
      border-radius: 1px; }
  .react-confirm-alert #headerAlert {
    height: 70px;
    color: white;
    text-align: center;
    margin: 0 auto;
    padding-top: 18px;
    background-color: #008996;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
    .react-confirm-alert #headerAlert #close {
      float: right;
      padding-right: 15px;
      fill: white; }
  .react-confirm-alert .react-confirm-alert-body {
    text-align: center;
    width: 40em;
    height: 500px;
    font-family: Barlow; }
    .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group {
      justify-content: center; }
      .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button {
        width: 13em;
        height: 40px;
        border-radius: 4px;
        background-color: #e5f8f8;
        color: #008996;
        font-family: Barlow; }
  .react-confirm-alert::-webkit-scrollbar {
    width: 6px;
    -webkit-border-radius: 10px;
    border-radius: 10px; }
  .react-confirm-alert::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px; }
  .react-confirm-alert::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #ddd;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }
  .react-confirm-alert::-webkit-scrollbar-thumb:window-inactive {
    background: #ddd;
    -webkit-border-radius: 10px;
    border-radius: 10px; }

#elimina {
  font-size: 0.9em;
  cursor: default; }

.favorites {
  padding: 20px 0;
  margin-bottom: 40px;
  font-family: Barlow;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282f33; }

.textandinput .counter {
  float: right;
  height: 40px;
  padding-top: 20px;
  padding-right: 10px;
  font-family: Barlow;
  font-style: italic;
  color: #495057;
  font-size: 14px;
  font-weight: 400; }
  .textandinput .counter.error {
    color: #ff3f5d;
    font-weight: bold; }

#investitore {
  display: none; }

#defaultContactFormName {
  border: solid 2px #e8e8ec !important; }
  #defaultContactFormName:focus {
    box-shadow: 0 0 10px 0 rgba(0, 193, 192, 0.2) !important;
    border: solid 2px #008996;
    border-color: #008996 !important; }

.custom-ui {
  font-family: Barlow; }

form {
  max-width: 570px; }

#border {
  padding-top: 10px;
  border-top: 1px solid #f3f4f5;
  margin: 10px -10px 0; }
  #border #remove {
    font-weight: bold;
    font-family: Barlow;
    text-align: center;
    margin-bottom: 5px !important;
    padding: 2px 30px;
    border-radius: 4px;
    border: 2px solid #ff3f5d;
    color: #ff3f5d; }
    #border #remove:hover {
      color: white;
      background-color: #ff3f5d; }

@media (min-width: 320px) and (max-width: 480px) {
  .favorites {
    font-size: 20px;
    padding-top: 0;
    margin-bottom: 0px; }
  .headerCarrello {
    justify-content: center;
    margin-bottom: 20px; }
    .headerCarrello .title {
      font-family: Barlow;
      font-size: 20px;
      color: #282f33; }
    .headerCarrello .procedi {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 10; }
      .headerCarrello .procedi button {
        background: #008996;
        border-radius: initial;
        width: 100%; }
        .headerCarrello .procedi button:disabled {
          background-color: #979da5; }
  #react-confirm-alert {
    max-width: 100%; }
    #react-confirm-alert .react-confirm-alert {
      max-width: 100%;
      max-height: 100vh;
      border-radius: 0px; }
      #react-confirm-alert .react-confirm-alert .react-confirm-alert-body {
        width: 100%;
        height: 100%; }
        #react-confirm-alert .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group {
          justify-content: center; }
      #react-confirm-alert .react-confirm-alert .custom-ui {
        width: 100%; }
        #react-confirm-alert .react-confirm-alert .custom-ui #headerAlert {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          height: 60px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          padding-left: 15px; }
          #react-confirm-alert .react-confirm-alert .custom-ui #headerAlert #close {
            margin-bottom: 0rem !important; }
        #react-confirm-alert .react-confirm-alert .custom-ui #form {
          padding: 0 10px; }
          #react-confirm-alert .react-confirm-alert .custom-ui #form #dataTHelp {
            justify-content: flex-start;
            align-items: flex-start;
            padding: 20px 15px; }
            #react-confirm-alert .react-confirm-alert .custom-ui #form #dataTHelp #description {
              font-size: 14px;
              line-height: normal;
              margin-left: 15px; }
            #react-confirm-alert .react-confirm-alert .custom-ui #form #dataTHelp #linkData {
              display: block;
              margin-top: 10px; }
          #react-confirm-alert .react-confirm-alert .custom-ui #form .p-4 #dataT, #react-confirm-alert .react-confirm-alert .custom-ui #form .p-4 p {
            text-align: center;
            font-size: 14px; }
          #react-confirm-alert .react-confirm-alert .custom-ui #form .p-4 .chatbutton {
            float: initial;
            width: 100%; }
            #react-confirm-alert .react-confirm-alert .custom-ui #form .p-4 .chatbutton #dataTButtons {
              justify-content: space-between !important; }
          #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write .textandinput .form-control {
            font-family: Barlow-italic;
            font-size: 14px; }
          #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write .textandinput .p-2 {
            border-bottom: 1px solid #e0e1e3;
            margin: 0 10px; }
            #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write .textandinput .p-2 #containerAllegati {
              font-size: 14px; }
              #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write .textandinput .p-2 #containerAllegati #allegatoSize {
                font-size: 12px; }
                #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write .textandinput .p-2 #containerAllegati #allegatoSize svg {
                  margin-left: 5px; }
          #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write .textandinput #uploadButton {
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            width: 130px;
            margin-top: 10px; }
            #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write .textandinput #uploadButton svg {
              margin-right: 5px; }
          #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write #containerAllegati li {
            font-size: 14px;
            padding: 0 5px; }
            #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write #containerAllegati li span {
              font-size: 12px; }
              #react-confirm-alert .react-confirm-alert .custom-ui #form .message_write #containerAllegati li span svg {
                margin-left: 10px; } }
