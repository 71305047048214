#buyer,
#seller,
#about,
#faq,
#signup {
  max-width: 1366px;
  margin: 0 auto; }
  #buyer .fasciaHeader,
  #seller .fasciaHeader,
  #about .fasciaHeader,
  #faq .fasciaHeader,
  #signup .fasciaHeader {
    position: relative;
    background-color: #fff; }
    #buyer .fasciaHeader #headerPublic,
    #seller .fasciaHeader #headerPublic,
    #about .fasciaHeader #headerPublic,
    #faq .fasciaHeader #headerPublic,
    #signup .fasciaHeader #headerPublic {
      box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.1);
      padding: 45px 95px;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 70px; }
      #buyer .fasciaHeader #headerPublic a,
      #seller .fasciaHeader #headerPublic a,
      #about .fasciaHeader #headerPublic a,
      #faq .fasciaHeader #headerPublic a,
      #signup .fasciaHeader #headerPublic a {
        text-decoration: none;
        color: #000; }
      #buyer .fasciaHeader #headerPublic .logo,
      #seller .fasciaHeader #headerPublic .logo,
      #about .fasciaHeader #headerPublic .logo,
      #faq .fasciaHeader #headerPublic .logo,
      #signup .fasciaHeader #headerPublic .logo {
        display: flex;
        align-items: center; }
        #buyer .fasciaHeader #headerPublic .logo a,
        #seller .fasciaHeader #headerPublic .logo a,
        #about .fasciaHeader #headerPublic .logo a,
        #faq .fasciaHeader #headerPublic .logo a,
        #signup .fasciaHeader #headerPublic .logo a {
          font-size: 20px;
          color: #ccc; }
        #buyer .fasciaHeader #headerPublic .logo img,
        #seller .fasciaHeader #headerPublic .logo img,
        #about .fasciaHeader #headerPublic .logo img,
        #faq .fasciaHeader #headerPublic .logo img,
        #signup .fasciaHeader #headerPublic .logo img {
          width: 140px;
          margin-right: 5px; }
      #buyer .fasciaHeader #headerPublic .linkHeader,
      #seller .fasciaHeader #headerPublic .linkHeader,
      #about .fasciaHeader #headerPublic .linkHeader,
      #faq .fasciaHeader #headerPublic .linkHeader,
      #signup .fasciaHeader #headerPublic .linkHeader {
        font-family: Barlow;
        display: flex;
        align-items: center; }
        #buyer .fasciaHeader #headerPublic .linkHeader a,
        #seller .fasciaHeader #headerPublic .linkHeader a,
        #about .fasciaHeader #headerPublic .linkHeader a,
        #faq .fasciaHeader #headerPublic .linkHeader a,
        #signup .fasciaHeader #headerPublic .linkHeader a {
          color: #000;
          margin-right: 30px; }
          #buyer .fasciaHeader #headerPublic .linkHeader a:last-child,
          #seller .fasciaHeader #headerPublic .linkHeader a:last-child,
          #about .fasciaHeader #headerPublic .linkHeader a:last-child,
          #faq .fasciaHeader #headerPublic .linkHeader a:last-child,
          #signup .fasciaHeader #headerPublic .linkHeader a:last-child {
            margin-right: 0; }
          #buyer .fasciaHeader #headerPublic .linkHeader a .active,
          #seller .fasciaHeader #headerPublic .linkHeader a .active,
          #about .fasciaHeader #headerPublic .linkHeader a .active,
          #faq .fasciaHeader #headerPublic .linkHeader a .active,
          #signup .fasciaHeader #headerPublic .linkHeader a .active {
            font-family: Barlow-semiBold;
            color: #008996; }
        #buyer .fasciaHeader #headerPublic .linkHeader .login,
        #buyer .fasciaHeader #headerPublic .linkHeader .signIn,
        #seller .fasciaHeader #headerPublic .linkHeader .login,
        #seller .fasciaHeader #headerPublic .linkHeader .signIn,
        #about .fasciaHeader #headerPublic .linkHeader .login,
        #about .fasciaHeader #headerPublic .linkHeader .signIn,
        #faq .fasciaHeader #headerPublic .linkHeader .login,
        #faq .fasciaHeader #headerPublic .linkHeader .signIn,
        #signup .fasciaHeader #headerPublic .linkHeader .login,
        #signup .fasciaHeader #headerPublic .linkHeader .signIn {
          font-family: Barlow-bold;
          margin: 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 170px;
          height: 40px;
          border-radius: 4px;
          border: solid 1px #008996;
          cursor: pointer;
          color: #008996; }
          #buyer .fasciaHeader #headerPublic .linkHeader .login svg,
          #buyer .fasciaHeader #headerPublic .linkHeader .signIn svg,
          #seller .fasciaHeader #headerPublic .linkHeader .login svg,
          #seller .fasciaHeader #headerPublic .linkHeader .signIn svg,
          #about .fasciaHeader #headerPublic .linkHeader .login svg,
          #about .fasciaHeader #headerPublic .linkHeader .signIn svg,
          #faq .fasciaHeader #headerPublic .linkHeader .login svg,
          #faq .fasciaHeader #headerPublic .linkHeader .signIn svg,
          #signup .fasciaHeader #headerPublic .linkHeader .login svg,
          #signup .fasciaHeader #headerPublic .linkHeader .signIn svg {
            margin-right: 10px; }
          #buyer .fasciaHeader #headerPublic .linkHeader .login a,
          #buyer .fasciaHeader #headerPublic .linkHeader .signIn a,
          #seller .fasciaHeader #headerPublic .linkHeader .login a,
          #seller .fasciaHeader #headerPublic .linkHeader .signIn a,
          #about .fasciaHeader #headerPublic .linkHeader .login a,
          #about .fasciaHeader #headerPublic .linkHeader .signIn a,
          #faq .fasciaHeader #headerPublic .linkHeader .login a,
          #faq .fasciaHeader #headerPublic .linkHeader .signIn a,
          #signup .fasciaHeader #headerPublic .linkHeader .login a,
          #signup .fasciaHeader #headerPublic .linkHeader .signIn a {
            display: flex;
            align-items: center; }
          #buyer .fasciaHeader #headerPublic .linkHeader .login.signIn,
          #buyer .fasciaHeader #headerPublic .linkHeader .signIn.signIn,
          #seller .fasciaHeader #headerPublic .linkHeader .login.signIn,
          #seller .fasciaHeader #headerPublic .linkHeader .signIn.signIn,
          #about .fasciaHeader #headerPublic .linkHeader .login.signIn,
          #about .fasciaHeader #headerPublic .linkHeader .signIn.signIn,
          #faq .fasciaHeader #headerPublic .linkHeader .login.signIn,
          #faq .fasciaHeader #headerPublic .linkHeader .signIn.signIn,
          #signup .fasciaHeader #headerPublic .linkHeader .login.signIn,
          #signup .fasciaHeader #headerPublic .linkHeader .signIn.signIn {
            background: #008996;
            color: #fff; }
    #buyer .fasciaHeader .description,
    #seller .fasciaHeader .description,
    #about .fasciaHeader .description,
    #faq .fasciaHeader .description,
    #signup .fasciaHeader .description {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-family: Barlow-light;
      color: #000;
      text-align: center; }
      #buyer .fasciaHeader .description .type,
      #seller .fasciaHeader .description .type,
      #about .fasciaHeader .description .type,
      #faq .fasciaHeader .description .type,
      #signup .fasciaHeader .description .type {
        font-size: 14px;
        font-family: Barlow;
        color: #000000; }
      #buyer .fasciaHeader .description .title,
      #seller .fasciaHeader .description .title,
      #about .fasciaHeader .description .title,
      #faq .fasciaHeader .description .title,
      #signup .fasciaHeader .description .title {
        max-width: 600px;
        font-size: 48px;
        margin-bottom: 80px; }
        #buyer .fasciaHeader .description .title b,
        #seller .fasciaHeader .description .title b,
        #about .fasciaHeader .description .title b,
        #faq .fasciaHeader .description .title b,
        #signup .fasciaHeader .description .title b {
          color: #008997; }
      #buyer .fasciaHeader .description img,
      #seller .fasciaHeader .description img,
      #about .fasciaHeader .description img,
      #faq .fasciaHeader .description img,
      #signup .fasciaHeader .description img {
        width: 770px;
        height: 200px;
        border-radius: 10px;
        box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05); }
  #buyer .fasciaSearch,
  #buyer .fasciaFast,
  #buyer .fasciaAdvisor,
  #buyer .fasciaQuestions,
  #buyer .fasciaAboutPage,
  #seller .fasciaSearch,
  #seller .fasciaFast,
  #seller .fasciaAdvisor,
  #seller .fasciaQuestions,
  #seller .fasciaAboutPage,
  #about .fasciaSearch,
  #about .fasciaFast,
  #about .fasciaAdvisor,
  #about .fasciaQuestions,
  #about .fasciaAboutPage,
  #faq .fasciaSearch,
  #faq .fasciaFast,
  #faq .fasciaAdvisor,
  #faq .fasciaQuestions,
  #faq .fasciaAboutPage,
  #signup .fasciaSearch,
  #signup .fasciaFast,
  #signup .fasciaAdvisor,
  #signup .fasciaQuestions,
  #signup .fasciaAboutPage {
    padding: 200px 95px 95px;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    margin-bottom: 112px;
    background: #f5f8fa;
    margin-top: -100px; }
    #buyer .fasciaSearch .description,
    #buyer .fasciaFast .description,
    #buyer .fasciaAdvisor .description,
    #buyer .fasciaQuestions .description,
    #buyer .fasciaAboutPage .description,
    #seller .fasciaSearch .description,
    #seller .fasciaFast .description,
    #seller .fasciaAdvisor .description,
    #seller .fasciaQuestions .description,
    #seller .fasciaAboutPage .description,
    #about .fasciaSearch .description,
    #about .fasciaFast .description,
    #about .fasciaAdvisor .description,
    #about .fasciaQuestions .description,
    #about .fasciaAboutPage .description,
    #faq .fasciaSearch .description,
    #faq .fasciaFast .description,
    #faq .fasciaAdvisor .description,
    #faq .fasciaQuestions .description,
    #faq .fasciaAboutPage .description,
    #signup .fasciaSearch .description,
    #signup .fasciaFast .description,
    #signup .fasciaAdvisor .description,
    #signup .fasciaQuestions .description,
    #signup .fasciaAboutPage .description {
      position: relative;
      z-index: 1;
      width: 50%;
      padding-right: 30px;
      display: flex;
      flex-direction: column; }
      #buyer .fasciaSearch .description .question,
      #buyer .fasciaFast .description .question,
      #buyer .fasciaAdvisor .description .question,
      #buyer .fasciaQuestions .description .question,
      #buyer .fasciaAboutPage .description .question,
      #seller .fasciaSearch .description .question,
      #seller .fasciaFast .description .question,
      #seller .fasciaAdvisor .description .question,
      #seller .fasciaQuestions .description .question,
      #seller .fasciaAboutPage .description .question,
      #about .fasciaSearch .description .question,
      #about .fasciaFast .description .question,
      #about .fasciaAdvisor .description .question,
      #about .fasciaQuestions .description .question,
      #about .fasciaAboutPage .description .question,
      #faq .fasciaSearch .description .question,
      #faq .fasciaFast .description .question,
      #faq .fasciaAdvisor .description .question,
      #faq .fasciaQuestions .description .question,
      #faq .fasciaAboutPage .description .question,
      #signup .fasciaSearch .description .question,
      #signup .fasciaFast .description .question,
      #signup .fasciaAdvisor .description .question,
      #signup .fasciaQuestions .description .question,
      #signup .fasciaAboutPage .description .question {
        font-family: Barlow;
        font-size: 32px;
        color: #000;
        margin-bottom: 30px; }
        #buyer .fasciaSearch .description .question b,
        #buyer .fasciaFast .description .question b,
        #buyer .fasciaAdvisor .description .question b,
        #buyer .fasciaQuestions .description .question b,
        #buyer .fasciaAboutPage .description .question b,
        #seller .fasciaSearch .description .question b,
        #seller .fasciaFast .description .question b,
        #seller .fasciaAdvisor .description .question b,
        #seller .fasciaQuestions .description .question b,
        #seller .fasciaAboutPage .description .question b,
        #about .fasciaSearch .description .question b,
        #about .fasciaFast .description .question b,
        #about .fasciaAdvisor .description .question b,
        #about .fasciaQuestions .description .question b,
        #about .fasciaAboutPage .description .question b,
        #faq .fasciaSearch .description .question b,
        #faq .fasciaFast .description .question b,
        #faq .fasciaAdvisor .description .question b,
        #faq .fasciaQuestions .description .question b,
        #faq .fasciaAboutPage .description .question b,
        #signup .fasciaSearch .description .question b,
        #signup .fasciaFast .description .question b,
        #signup .fasciaAdvisor .description .question b,
        #signup .fasciaQuestions .description .question b,
        #signup .fasciaAboutPage .description .question b {
          font-family: Barlow-med; }
      #buyer .fasciaSearch .description .testo,
      #buyer .fasciaFast .description .testo,
      #buyer .fasciaAdvisor .description .testo,
      #buyer .fasciaQuestions .description .testo,
      #buyer .fasciaAboutPage .description .testo,
      #seller .fasciaSearch .description .testo,
      #seller .fasciaFast .description .testo,
      #seller .fasciaAdvisor .description .testo,
      #seller .fasciaQuestions .description .testo,
      #seller .fasciaAboutPage .description .testo,
      #about .fasciaSearch .description .testo,
      #about .fasciaFast .description .testo,
      #about .fasciaAdvisor .description .testo,
      #about .fasciaQuestions .description .testo,
      #about .fasciaAboutPage .description .testo,
      #faq .fasciaSearch .description .testo,
      #faq .fasciaFast .description .testo,
      #faq .fasciaAdvisor .description .testo,
      #faq .fasciaQuestions .description .testo,
      #faq .fasciaAboutPage .description .testo,
      #signup .fasciaSearch .description .testo,
      #signup .fasciaFast .description .testo,
      #signup .fasciaAdvisor .description .testo,
      #signup .fasciaQuestions .description .testo,
      #signup .fasciaAboutPage .description .testo {
        font-family: Barlow;
        font-size: 16px;
        color: #000;
        margin-bottom: 15px; }
        #buyer .fasciaSearch .description .testo b.highlight,
        #buyer .fasciaFast .description .testo b.highlight,
        #buyer .fasciaAdvisor .description .testo b.highlight,
        #buyer .fasciaQuestions .description .testo b.highlight,
        #buyer .fasciaAboutPage .description .testo b.highlight,
        #seller .fasciaSearch .description .testo b.highlight,
        #seller .fasciaFast .description .testo b.highlight,
        #seller .fasciaAdvisor .description .testo b.highlight,
        #seller .fasciaQuestions .description .testo b.highlight,
        #seller .fasciaAboutPage .description .testo b.highlight,
        #about .fasciaSearch .description .testo b.highlight,
        #about .fasciaFast .description .testo b.highlight,
        #about .fasciaAdvisor .description .testo b.highlight,
        #about .fasciaQuestions .description .testo b.highlight,
        #about .fasciaAboutPage .description .testo b.highlight,
        #faq .fasciaSearch .description .testo b.highlight,
        #faq .fasciaFast .description .testo b.highlight,
        #faq .fasciaAdvisor .description .testo b.highlight,
        #faq .fasciaQuestions .description .testo b.highlight,
        #faq .fasciaAboutPage .description .testo b.highlight,
        #signup .fasciaSearch .description .testo b.highlight,
        #signup .fasciaFast .description .testo b.highlight,
        #signup .fasciaAdvisor .description .testo b.highlight,
        #signup .fasciaQuestions .description .testo b.highlight,
        #signup .fasciaAboutPage .description .testo b.highlight {
          text-decoration: underline;
          cursor: pointer; }
    #buyer .fasciaSearch .img,
    #buyer .fasciaFast .img,
    #buyer .fasciaAdvisor .img,
    #buyer .fasciaQuestions .img,
    #buyer .fasciaAboutPage .img,
    #seller .fasciaSearch .img,
    #seller .fasciaFast .img,
    #seller .fasciaAdvisor .img,
    #seller .fasciaQuestions .img,
    #seller .fasciaAboutPage .img,
    #about .fasciaSearch .img,
    #about .fasciaFast .img,
    #about .fasciaAdvisor .img,
    #about .fasciaQuestions .img,
    #about .fasciaAboutPage .img,
    #faq .fasciaSearch .img,
    #faq .fasciaFast .img,
    #faq .fasciaAdvisor .img,
    #faq .fasciaQuestions .img,
    #faq .fasciaAboutPage .img,
    #signup .fasciaSearch .img,
    #signup .fasciaFast .img,
    #signup .fasciaAdvisor .img,
    #signup .fasciaQuestions .img,
    #signup .fasciaAboutPage .img {
      position: relative;
      z-index: 1; }
  #buyer .fasciaFast,
  #seller .fasciaFast,
  #about .fasciaFast,
  #faq .fasciaFast,
  #signup .fasciaFast {
    padding: 120px 95px 170px;
    z-index: 2; }
    #buyer .fasciaFast:before,
    #seller .fasciaFast:before,
    #about .fasciaFast:before,
    #faq .fasciaFast:before,
    #signup .fasciaFast:before {
      content: "";
      position: absolute;
      top: -100px;
      left: 0;
      width: 100%;
      height: calc(40%);
      background: #f5f8fa;
      -webkit-transform: skewY(-2deg);
      -moz-transform: skewY(-2deg);
      -ms-transform: skewY(-2deg);
      -o-transform: skewY(-2deg);
      transform: skewY(-2deg);
      box-shadow: 0px -15px 25px -15px rgba(0, 0, 0, 0.05);
      margin-bottom: 0;
      z-index: 1; }
    #buyer .fasciaFast .img,
    #seller .fasciaFast .img,
    #about .fasciaFast .img,
    #faq .fasciaFast .img,
    #signup .fasciaFast .img {
      position: relative;
      z-index: 1;
      width: 50%; }
      #buyer .fasciaFast .img img,
      #seller .fasciaFast .img img,
      #about .fasciaFast .img img,
      #faq .fasciaFast .img img,
      #signup .fasciaFast .img img {
        position: absolute;
        top: -80px;
        left: 115px;
        width: 568px; }
  #buyer .fasciaAdvisor,
  #seller .fasciaAdvisor,
  #about .fasciaAdvisor,
  #faq .fasciaAdvisor,
  #signup .fasciaAdvisor {
    margin-bottom: 10px;
    padding: 120px 95px 200px;
    z-index: 2; }
    #buyer .fasciaAdvisor:before,
    #seller .fasciaAdvisor:before,
    #about .fasciaAdvisor:before,
    #faq .fasciaAdvisor:before,
    #signup .fasciaAdvisor:before {
      content: "";
      position: absolute;
      top: -65px;
      left: 0;
      width: 100%;
      height: calc(40%);
      background: #f5f8fa;
      -webkit-transform: skewY(-2deg);
      -moz-transform: skewY(-2deg);
      -ms-transform: skewY(-2deg);
      -o-transform: skewY(-2deg);
      transform: skewY(-2deg);
      box-shadow: 0px -15px 25px -15px rgba(0, 0, 0, 0.05);
      z-index: 1; }
    #buyer .fasciaAdvisor .img,
    #seller .fasciaAdvisor .img,
    #about .fasciaAdvisor .img,
    #faq .fasciaAdvisor .img,
    #signup .fasciaAdvisor .img {
      display: flex;
      width: 50%;
      justify-content: center; }
      #buyer .fasciaAdvisor .img img,
      #seller .fasciaAdvisor .img img,
      #about .fasciaAdvisor .img img,
      #faq .fasciaAdvisor .img img,
      #signup .fasciaAdvisor .img img {
        width: 310px; }
  #buyer .fasciaQuestions,
  #seller .fasciaQuestions,
  #about .fasciaQuestions,
  #faq .fasciaQuestions,
  #signup .fasciaQuestions {
    background-color: #fff;
    margin-bottom: 0px; }
    #buyer .fasciaQuestions .ask,
    #seller .fasciaQuestions .ask,
    #about .fasciaQuestions .ask,
    #faq .fasciaQuestions .ask,
    #signup .fasciaQuestions .ask {
      display: flex;
      flex-direction: column;
      width: 570px; }
      #buyer .fasciaQuestions .ask .item,
      #seller .fasciaQuestions .ask .item,
      #about .fasciaQuestions .ask .item,
      #faq .fasciaQuestions .ask .item,
      #signup .fasciaQuestions .ask .item {
        width: 100%;
        height: 80px;
        border-radius: 10px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.07);
        background-color: #fff;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px; }
        #buyer .fasciaQuestions .ask .item .testo,
        #seller .fasciaQuestions .ask .item .testo,
        #about .fasciaQuestions .ask .item .testo,
        #faq .fasciaQuestions .ask .item .testo,
        #signup .fasciaQuestions .ask .item .testo {
          font-family: Barlow-semiBold;
          font-size: 16px;
          color: #282f33; }
        #buyer .fasciaQuestions .ask .item .plus,
        #seller .fasciaQuestions .ask .item .plus,
        #about .fasciaQuestions .ask .item .plus,
        #faq .fasciaQuestions .ask .item .plus,
        #signup .fasciaQuestions .ask .item .plus {
          width: 30px;
          height: 30px;
          background: #e5f8f8;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          cursor: pointer; }
  #buyer .fasciaAbout,
  #seller .fasciaAbout,
  #about .fasciaAbout,
  #faq .fasciaAbout,
  #signup .fasciaAbout {
    padding-top: 100px; }
    #buyer .fasciaAbout .img,
    #seller .fasciaAbout .img,
    #about .fasciaAbout .img,
    #faq .fasciaAbout .img,
    #signup .fasciaAbout .img {
      position: relative;
      z-index: 1;
      width: 50%; }
      #buyer .fasciaAbout .img img,
      #seller .fasciaAbout .img img,
      #about .fasciaAbout .img img,
      #faq .fasciaAbout .img img,
      #signup .fasciaAbout .img img {
        position: absolute;
        top: -160px;
        left: 15px; }
  #buyer .fasciaReady,
  #seller .fasciaReady,
  #about .fasciaReady,
  #faq .fasciaReady,
  #signup .fasciaReady {
    padding: 45px 95px 65px;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: center;
    margin-bottom: 112px;
    padding-top: 50px;
    z-index: 3; }
    #buyer .fasciaReady:before,
    #seller .fasciaReady:before,
    #about .fasciaReady:before,
    #faq .fasciaReady:before,
    #signup .fasciaReady:before {
      content: "";
      position: absolute;
      top: -80px;
      left: 0;
      width: 100%;
      height: calc(150%);
      background: #008996;
      -webkit-transform: skewY(6deg);
      -moz-transform: skewY(6deg);
      -ms-transform: skewY(6deg);
      -o-transform: skewY(6deg);
      transform: skewY(6deg);
      z-index: 1; }
    #buyer .fasciaReady .description,
    #seller .fasciaReady .description,
    #about .fasciaReady .description,
    #faq .fasciaReady .description,
    #signup .fasciaReady .description {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-family: Barlow;
      color: #ffffff;
      text-align: center; }
      #buyer .fasciaReady .description .title,
      #seller .fasciaReady .description .title,
      #about .fasciaReady .description .title,
      #faq .fasciaReady .description .title,
      #signup .fasciaReady .description .title {
        max-width: 713px;
        font-size: 40px;
        margin-bottom: 20px; }
      #buyer .fasciaReady .description .subtitle,
      #seller .fasciaReady .description .subtitle,
      #about .fasciaReady .description .subtitle,
      #faq .fasciaReady .description .subtitle,
      #signup .fasciaReady .description .subtitle {
        font-family: Barlow-light;
        max-width: 565px;
        font-size: 16px;
        margin-bottom: 20px; }
      #buyer .fasciaReady .description .getStart,
      #seller .fasciaReady .description .getStart,
      #about .fasciaReady .description .getStart,
      #faq .fasciaReady .description .getStart,
      #signup .fasciaReady .description .getStart {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 370px;
        height: 64px;
        border-radius: 4px;
        box-shadow: 0 5px 20px 0 rgba(255, 255, 255, 0.8);
        background-color: #fff;
        color: #008996;
        font-family: Barlow-bold;
        font-size: 24px; }
  #buyer .fasciaHelp,
  #seller .fasciaHelp,
  #about .fasciaHelp,
  #faq .fasciaHelp,
  #signup .fasciaHelp {
    border-bottom: 1px solid #d8d8d8;
    padding: 30px 0px;
    margin: 0 95px; }
    #buyer .fasciaHelp .description,
    #seller .fasciaHelp .description,
    #about .fasciaHelp .description,
    #faq .fasciaHelp .description,
    #signup .fasciaHelp .description {
      display: flex;
      flex-direction: row;
      align-items: center; }
      #buyer .fasciaHelp .description .question,
      #seller .fasciaHelp .description .question,
      #about .fasciaHelp .description .question,
      #faq .fasciaHelp .description .question,
      #signup .fasciaHelp .description .question {
        font-size: 24px;
        margin-bottom: 12px; }
      #buyer .fasciaHelp .description .testo,
      #seller .fasciaHelp .description .testo,
      #about .fasciaHelp .description .testo,
      #faq .fasciaHelp .description .testo,
      #signup .fasciaHelp .description .testo {
        margin-bottom: 5px;
        color: #008996; }
        #buyer .fasciaHelp .description .testo span,
        #seller .fasciaHelp .description .testo span,
        #about .fasciaHelp .description .testo span,
        #faq .fasciaHelp .description .testo span,
        #signup .fasciaHelp .description .testo span {
          margin-left: 10px;
          color: #008996;
          cursor: pointer;
          border-bottom: 1px solid;
          padding-bottom: 5px; }
  #buyer .footer,
  #seller .footer,
  #about .footer,
  #faq .footer,
  #signup .footer {
    padding: 30px 95px;
    margin-bottom: 30px; }
    #buyer .footer .logoFooter,
    #seller .footer .logoFooter,
    #about .footer .logoFooter,
    #faq .footer .logoFooter,
    #signup .footer .logoFooter {
      margin-bottom: 20px; }
      #buyer .footer .logoFooter a,
      #seller .footer .logoFooter a,
      #about .footer .logoFooter a,
      #faq .footer .logoFooter a,
      #signup .footer .logoFooter a {
        text-decoration: none;
        color: #828282; }
        #buyer .footer .logoFooter a img,
        #seller .footer .logoFooter a img,
        #about .footer .logoFooter a img,
        #faq .footer .logoFooter a img,
        #signup .footer .logoFooter a img {
          width: 140px;
          margin-right: 5px; }
    #buyer .footer .info,
    #seller .footer .info,
    #about .footer .info,
    #faq .footer .info,
    #signup .footer .info {
      font-family: Barlow;
      font-size: 12px;
      color: #6d7278; }
      #buyer .footer .info .link,
      #seller .footer .info .link,
      #about .footer .info .link,
      #faq .footer .info .link,
      #signup .footer .info .link {
        margin-top: 20px;
        display: flex;
        align-items: center;
        color: #6d7278; }
        #buyer .footer .info .link .privacy,
        #seller .footer .info .link .privacy,
        #about .footer .info .link .privacy,
        #faq .footer .info .link .privacy,
        #signup .footer .info .link .privacy {
          cursor: pointer; }
          #buyer .footer .info .link .privacy a,
          #seller .footer .info .link .privacy a,
          #about .footer .info .link .privacy a,
          #faq .footer .info .link .privacy a,
          #signup .footer .info .link .privacy a {
            font-size: 12px;
            color: #6d7278;
            text-decoration: none; }
        #buyer .footer .info .link .lux,
        #seller .footer .info .link .lux,
        #about .footer .info .link .lux,
        #faq .footer .info .link .lux,
        #signup .footer .info .link .lux {
          margin-left: 30px;
          cursor: pointer; }
          #buyer .footer .info .link .lux a,
          #seller .footer .info .link .lux a,
          #about .footer .info .link .lux a,
          #faq .footer .info .link .lux a,
          #signup .footer .info .link .lux a {
            font-size: 12px;
            color: #6d7278;
            text-decoration: none; }

#seller .fasciaSearch {
  padding: 200px 95px 75px; }
  #seller .fasciaSearch .img {
    display: flex;
    justify-content: center;
    width: 50%; }
    #seller .fasciaSearch .img img {
      width: 310px; }

#seller .fasciaFast {
  padding: 100px 95px 170px; }
  #seller .fasciaFast .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%; }
    #seller .fasciaFast .img img {
      position: initial;
      width: 430px; }

#seller .fasciaAdvisor {
  padding: 120px 95px 265px; }
  #seller .fasciaAdvisor .img {
    position: relative;
    z-index: 1;
    width: 50%; }
    #seller .fasciaAdvisor .img img {
      position: absolute;
      top: 0px;
      left: -95px;
      width: 570px; }

#about .fasciaHeader #headerPublic {
  box-shadow: none;
  margin-bottom: 0; }

#about .fasciaAboutPage {
  padding: 180px 95px;
  margin-top: 0;
  position: relative;
  overflow: hidden;
  background: #fff; }
  #about .fasciaAboutPage img {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    z-index: 0; }
  #about .fasciaAboutPage .description {
    z-index: 3; }
    #about .fasciaAboutPage .description .question {
      font-size: 48px;
      line-height: normal; }
      #about .fasciaAboutPage .description .question span {
        font-family: Barlow-med;
        color: #008997; }
      #about .fasciaAboutPage .description .question .testo {
        line-height: 1.5; }

#about .fasciaSearch {
  background: #fff;
  padding: 100px 95px 190px;
  margin-bottom: 0px; }
  #about .fasciaSearch .btn {
    font-family: Barlow-Bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 270px;
    height: 40px;
    border-radius: 4px;
    background-color: #008996;
    color: #fff;
    cursor: pointer;
    margin-top: 40px; }
    #about .fasciaSearch .btn a {
      color: #fff;
      text-decoration: none; }
      #about .fasciaSearch .btn a:hover {
        color: #fff;
        text-decoration: none; }

#faq .ask {
  max-width: 970px;
  margin: 0 auto;
  margin-bottom: 60px; }
  #faq .ask .title {
    font-family: Barlow;
    margin-bottom: 20px; }
  #faq .ask .containerItem {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 10px; }
    #faq .ask .containerItem.active {
      border: 1px solid #008996; }
    #faq .ask .containerItem .item {
      border-radius: 10px;
      width: 100%;
      height: 80px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.4s; }
      #faq .ask .containerItem .item .testo {
        font-family: Barlow-semiBold;
        font-size: 16px;
        color: #282f33; }
      #faq .ask .containerItem .item .plus {
        width: 30px;
        height: 30px;
        background: #e5f8f8;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        cursor: pointer; }
    #faq .ask .containerItem .panel {
      background-color: #f5f8fa;
      border-radius: 10px;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.2s ease-out; }
      #faq .ask .containerItem .panel .testo {
        padding: 10px 20px 10px; }
        #faq .ask .containerItem .panel .testo:first-child {
          padding: 20px 20px 10px; }
        #faq .ask .containerItem .panel .testo:last-child {
          padding: 10px 20px 20px; }

#signup .fasciaHeader #headerPublic {
  margin-bottom: 0; }

#signup .fasciaSignup {
  padding: 30px 100px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 670px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../images/bkgSignup.png"); }
  #signup .fasciaSignup .description {
    position: relative;
    z-index: 1;
    width: 50%;
    padding-right: 30px;
    display: flex;
    flex-direction: column; }
    #signup .fasciaSignup .description .link {
      margin-bottom: 100px; }
      #signup .fasciaSignup .description .link a {
        text-decoration: none;
        color: #fff;
        font-family: Barlow;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 120px; }
        #signup .fasciaSignup .description .link a:hover {
          text-decoration: none;
          color: #fff; }
    #signup .fasciaSignup .description .started {
      color: #ffffff;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 400; }
    #signup .fasciaSignup .description .question {
      font-size: 40px;
      font-family: Barlow-semiBold;
      line-height: 48px;
      color: #fff;
      margin-bottom: 30px; }
      #signup .fasciaSignup .description .question span {
        font-family: Barlow; }
      #signup .fasciaSignup .description .question b {
        font-family: Barlow-med; }
    #signup .fasciaSignup .description .testo {
      max-width: 450px;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #fff;
      margin-bottom: 15px; }
      #signup .fasciaSignup .description .testo b.highlight {
        text-decoration: underline;
        cursor: pointer; }
  #signup .fasciaSignup .formSignup {
    width: 50%; }
    #signup .fasciaSignup .formSignup form {
      position: relative;
      width: 470px;
      max-height: 100%;
      border-radius: 10px;
      background: #ffffff;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
      padding: 30px !important; }
      #signup .fasciaSignup .formSignup form .has-error .form-control {
        background-color: #fff;
        border-color: rgba(255, 0, 0, 0.5);
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
        margin-bottom: 5px !important; }
      #signup .fasciaSignup .formSignup form .has-error span {
        font-size: 14px;
        color: #d00000; }
      #signup .fasciaSignup .formSignup form.hidden .header,
      #signup .fasciaSignup .formSignup form.hidden label,
      #signup .fasciaSignup .formSignup form.hidden input,
      #signup .fasciaSignup .formSignup form.hidden .carrelloheader,
      #signup .fasciaSignup .formSignup form.hidden .already,
      #signup .fasciaSignup .formSignup form.hidden .checkContainer,
      #signup .fasciaSignup .formSignup form.hidden .selectContainer,
      #signup .fasciaSignup .formSignup form.hidden .textareaContainer {
        visibility: hidden; }
      #signup .fasciaSignup .formSignup form .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px; }
        #signup .fasciaSignup .formSignup form .header .title {
          font-family: Barlow;
          font-size: 24px;
          color: #000; }
        #signup .fasciaSignup .formSignup form .header .logo {
          font-family: Barlow-italic;
          font-size: 12px;
          color: #455a64; }
      #signup .fasciaSignup .formSignup form label {
        font-size: 12px;
        color: #455a64; }
      #signup .fasciaSignup .formSignup form .carrelloheader {
        background: #008996;
        box-shadow: 0px 5px 20px rgba(0, 182, 202, 0.4);
        border-radius: 5px;
        font-family: Barlow-SemiBold;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-bottom: 20px; }
        #signup .fasciaSignup .formSignup form .carrelloheader #buttoncont {
          width: 100%;
          color: #fff; }
        #signup .fasciaSignup .formSignup form .carrelloheader.disabled {
          opacity: 0.2; }
      #signup .fasciaSignup .formSignup form .already {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Barlow;
        font-size: 14px; }
        #signup .fasciaSignup .formSignup form .already a {
          margin-left: 5px;
          color: #008996;
          text-decoration: underline; }
          #signup .fasciaSignup .formSignup form .already a:hover {
            color: #008996; }
      #signup .fasciaSignup .formSignup form #conferma {
        display: none;
        position: absolute;
        top: 40%;
        left: calc((100% - 275px) / 2);
        width: 275px;
        max-height: 100%;
        flex-direction: column;
        align-items: center; }
        #signup .fasciaSignup .formSignup form #conferma .title {
          font-family: Barlow;
          font-size: 24px;
          margin-bottom: 15px; }
        #signup .fasciaSignup .formSignup form #conferma .testo {
          font-family: Barlow;
          font-size: 16px;
          margin-bottom: 30px; }
        #signup .fasciaSignup .formSignup form #conferma a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 170px;
          height: 40px;
          background: #e5f8f8;
          text-decoration: none;
          color: #008996;
          font-family: Barlow;
          font-size: 14px; }
          #signup .fasciaSignup .formSignup form #conferma a:hover {
            text-decoration: none; }
        #signup .fasciaSignup .formSignup form #conferma.show {
          display: flex; }
      #signup .fasciaSignup .formSignup form .solo {
        cursor: pointer;
        border-radius: 3px;
        color: #455a64;
        position: relative; }
        #signup .fasciaSignup .formSignup form .solo.select {
          background: #008996;
          color: #fff; }
        #signup .fasciaSignup .formSignup form .solo:after {
          position: absolute;
          left: 50px;
          top: -37px;
          width: 260px;
          height: auto;
          display: none;
          z-index: 99; }
        #signup .fasciaSignup .formSignup form .solo:hover:after {
          display: block; }

@media (min-width: 320px) and (max-width: 480px) {
  #buyer,
  #seller,
  #about,
  #signup {
    max-width: 100vw; }
    #buyer .fasciaHeader,
    #seller .fasciaHeader,
    #about .fasciaHeader,
    #signup .fasciaHeader {
      padding: 14px;
      height: 100%;
      background: #fff; }
      #buyer .fasciaHeader:before,
      #seller .fasciaHeader:before,
      #about .fasciaHeader:before,
      #signup .fasciaHeader:before {
        content: initial; }
      #buyer .fasciaHeader #headerPublic,
      #seller .fasciaHeader #headerPublic,
      #about .fasciaHeader #headerPublic,
      #signup .fasciaHeader #headerPublic {
        padding: 0px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        transition: background-color 0.3s;
        background: #fff; }
        #buyer .fasciaHeader #headerPublic .menuMobile,
        #seller .fasciaHeader #headerPublic .menuMobile,
        #about .fasciaHeader #headerPublic .menuMobile,
        #signup .fasciaHeader #headerPublic .menuMobile {
          box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 14px 17px; }
        #buyer .fasciaHeader #headerPublic .linkHeader,
        #seller .fasciaHeader #headerPublic .linkHeader,
        #about .fasciaHeader #headerPublic .linkHeader,
        #signup .fasciaHeader #headerPublic .linkHeader {
          overflow: hidden;
          -webkit-transition: height 0.3s linear;
          -moz-transition: height 0.3s linear;
          transition: height 0.3s linear;
          opacity: 0;
          height: 0;
          justify-content: space-between;
          width: calc(100% - 30px); }
          #buyer .fasciaHeader #headerPublic .linkHeader.open,
          #seller .fasciaHeader #headerPublic .linkHeader.open,
          #about .fasciaHeader #headerPublic .linkHeader.open,
          #signup .fasciaHeader #headerPublic .linkHeader.open {
            opacity: 1;
            display: flex;
            flex-direction: column;
            height: calc(100vh - 50px); }
          #buyer .fasciaHeader #headerPublic .linkHeader .linkMenu,
          #seller .fasciaHeader #headerPublic .linkHeader .linkMenu,
          #about .fasciaHeader #headerPublic .linkHeader .linkMenu,
          #signup .fasciaHeader #headerPublic .linkHeader .linkMenu {
            width: 100%; }
            #buyer .fasciaHeader #headerPublic .linkHeader .linkMenu a,
            #seller .fasciaHeader #headerPublic .linkHeader .linkMenu a,
            #about .fasciaHeader #headerPublic .linkHeader .linkMenu a,
            #signup .fasciaHeader #headerPublic .linkHeader .linkMenu a {
              text-align: center;
              margin-right: 0px;
              color: #000; }
              #buyer .fasciaHeader #headerPublic .linkHeader .linkMenu a h5,
              #seller .fasciaHeader #headerPublic .linkHeader .linkMenu a h5,
              #about .fasciaHeader #headerPublic .linkHeader .linkMenu a h5,
              #signup .fasciaHeader #headerPublic .linkHeader .linkMenu a h5 {
                padding: 20px 0;
                border-bottom: 1px solid; }
          #buyer .fasciaHeader #headerPublic .linkHeader .buttonMenu,
          #seller .fasciaHeader #headerPublic .linkHeader .buttonMenu,
          #about .fasciaHeader #headerPublic .linkHeader .buttonMenu,
          #signup .fasciaHeader #headerPublic .linkHeader .buttonMenu {
            padding-bottom: 20px; }
            #buyer .fasciaHeader #headerPublic .linkHeader .buttonMenu .login,
            #seller .fasciaHeader #headerPublic .linkHeader .buttonMenu .login,
            #about .fasciaHeader #headerPublic .linkHeader .buttonMenu .login,
            #signup .fasciaHeader #headerPublic .linkHeader .buttonMenu .login {
              width: 290px;
              border: solid 1px #008996;
              color: #008996; }
            #buyer .fasciaHeader #headerPublic .linkHeader .buttonMenu .signIn,
            #seller .fasciaHeader #headerPublic .linkHeader .buttonMenu .signIn,
            #about .fasciaHeader #headerPublic .linkHeader .buttonMenu .signIn,
            #signup .fasciaHeader #headerPublic .linkHeader .buttonMenu .signIn {
              width: 290px;
              box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4);
              background-color: #008996;
              color: #fff; }
      #buyer .fasciaHeader .description,
      #seller .fasciaHeader .description,
      #about .fasciaHeader .description,
      #signup .fasciaHeader .description {
        margin-top: 70px; }
        #buyer .fasciaHeader .description .title,
        #seller .fasciaHeader .description .title,
        #about .fasciaHeader .description .title,
        #signup .fasciaHeader .description .title {
          font-size: 24px;
          margin-bottom: 30px; }
        #buyer .fasciaHeader .description .subtitle,
        #buyer .fasciaHeader .description .subsubtitle,
        #seller .fasciaHeader .description .subtitle,
        #seller .fasciaHeader .description .subsubtitle,
        #about .fasciaHeader .description .subtitle,
        #about .fasciaHeader .description .subsubtitle,
        #signup .fasciaHeader .description .subtitle,
        #signup .fasciaHeader .description .subsubtitle {
          margin-bottom: 30px;
          font-size: 14px; }
        #buyer .fasciaHeader .description img,
        #seller .fasciaHeader .description img,
        #about .fasciaHeader .description img,
        #signup .fasciaHeader .description img {
          margin-top: 0px;
          width: 85%; }
    #buyer .fasciaSearch,
    #buyer .fasciaFast,
    #buyer .fasciaAdvisor,
    #buyer .fasciaAboutPage,
    #seller .fasciaSearch,
    #seller .fasciaFast,
    #seller .fasciaAdvisor,
    #seller .fasciaAboutPage,
    #about .fasciaSearch,
    #about .fasciaFast,
    #about .fasciaAdvisor,
    #about .fasciaAboutPage,
    #signup .fasciaSearch,
    #signup .fasciaFast,
    #signup .fasciaAdvisor,
    #signup .fasciaAboutPage {
      padding: 120px 14px 20px;
      flex-direction: column; }
      #buyer .fasciaSearch .img,
      #buyer .fasciaFast .img,
      #buyer .fasciaAdvisor .img,
      #buyer .fasciaAboutPage .img,
      #seller .fasciaSearch .img,
      #seller .fasciaFast .img,
      #seller .fasciaAdvisor .img,
      #seller .fasciaAboutPage .img,
      #about .fasciaSearch .img,
      #about .fasciaFast .img,
      #about .fasciaAdvisor .img,
      #about .fasciaAboutPage .img,
      #signup .fasciaSearch .img,
      #signup .fasciaFast .img,
      #signup .fasciaAdvisor .img,
      #signup .fasciaAboutPage .img {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        width: 100%; }
        #buyer .fasciaSearch .img img,
        #buyer .fasciaFast .img img,
        #buyer .fasciaAdvisor .img img,
        #buyer .fasciaAboutPage .img img,
        #seller .fasciaSearch .img img,
        #seller .fasciaFast .img img,
        #seller .fasciaAdvisor .img img,
        #seller .fasciaAboutPage .img img,
        #about .fasciaSearch .img img,
        #about .fasciaFast .img img,
        #about .fasciaAdvisor .img img,
        #about .fasciaAboutPage .img img,
        #signup .fasciaSearch .img img,
        #signup .fasciaFast .img img,
        #signup .fasciaAdvisor .img img,
        #signup .fasciaAboutPage .img img {
          width: 70%; }
      #buyer .fasciaSearch .description,
      #buyer .fasciaFast .description,
      #buyer .fasciaAdvisor .description,
      #buyer .fasciaAboutPage .description,
      #seller .fasciaSearch .description,
      #seller .fasciaFast .description,
      #seller .fasciaAdvisor .description,
      #seller .fasciaAboutPage .description,
      #about .fasciaSearch .description,
      #about .fasciaFast .description,
      #about .fasciaAdvisor .description,
      #about .fasciaAboutPage .description,
      #signup .fasciaSearch .description,
      #signup .fasciaFast .description,
      #signup .fasciaAdvisor .description,
      #signup .fasciaAboutPage .description {
        width: 100%;
        padding-right: 0px; }
        #buyer .fasciaSearch .description .question,
        #buyer .fasciaFast .description .question,
        #buyer .fasciaAdvisor .description .question,
        #buyer .fasciaAboutPage .description .question,
        #seller .fasciaSearch .description .question,
        #seller .fasciaFast .description .question,
        #seller .fasciaAdvisor .description .question,
        #seller .fasciaAboutPage .description .question,
        #about .fasciaSearch .description .question,
        #about .fasciaFast .description .question,
        #about .fasciaAdvisor .description .question,
        #about .fasciaAboutPage .description .question,
        #signup .fasciaSearch .description .question,
        #signup .fasciaFast .description .question,
        #signup .fasciaAdvisor .description .question,
        #signup .fasciaAboutPage .description .question {
          font-size: 20px;
          margin-bottom: 10px; }
        #buyer .fasciaSearch .description .testo,
        #buyer .fasciaFast .description .testo,
        #buyer .fasciaAdvisor .description .testo,
        #buyer .fasciaAboutPage .description .testo,
        #seller .fasciaSearch .description .testo,
        #seller .fasciaFast .description .testo,
        #seller .fasciaAdvisor .description .testo,
        #seller .fasciaAboutPage .description .testo,
        #about .fasciaSearch .description .testo,
        #about .fasciaFast .description .testo,
        #about .fasciaAdvisor .description .testo,
        #about .fasciaAboutPage .description .testo,
        #signup .fasciaSearch .description .testo,
        #signup .fasciaFast .description .testo,
        #signup .fasciaAdvisor .description .testo,
        #signup .fasciaAboutPage .description .testo {
          font-size: 14px; }
    #buyer .fasciaFast,
    #seller .fasciaFast,
    #about .fasciaFast,
    #signup .fasciaFast {
      margin-bottom: 130px;
      padding: 20px 14px 14px;
      box-shadow: 0 -20px 40px 0 rgba(0, 0, 0, 0.03); }
      #buyer .fasciaFast:before,
      #seller .fasciaFast:before,
      #about .fasciaFast:before,
      #signup .fasciaFast:before {
        content: initial; }
      #buyer .fasciaFast .img,
      #seller .fasciaFast .img,
      #about .fasciaFast .img,
      #signup .fasciaFast .img {
        width: 100%;
        margin-bottom: 0px; }
        #buyer .fasciaFast .img img,
        #seller .fasciaFast .img img,
        #about .fasciaFast .img img,
        #signup .fasciaFast .img img {
          position: relative;
          top: 0;
          border: 0;
          width: 90%;
          left: 0; }
        #buyer .fasciaFast .img .gradient,
        #seller .fasciaFast .img .gradient,
        #about .fasciaFast .img .gradient,
        #signup .fasciaFast .img .gradient {
          position: absolute;
          width: 100%;
          height: 50px;
          background-image: linear-gradient(to bottom, rgba(245, 248, 250, 0.8), #f5f8fa);
          bottom: 0px;
          left: 0; }
    #buyer .fasciaAdvisor,
    #seller .fasciaAdvisor,
    #about .fasciaAdvisor,
    #signup .fasciaAdvisor {
      padding: 20px 14px 65px;
      box-shadow: 0 -20px 40px 0 rgba(0, 0, 0, 0.03); }
      #buyer .fasciaAdvisor:before,
      #seller .fasciaAdvisor:before,
      #about .fasciaAdvisor:before,
      #signup .fasciaAdvisor:before {
        content: initial; }
      #buyer .fasciaAdvisor .img,
      #seller .fasciaAdvisor .img,
      #about .fasciaAdvisor .img,
      #signup .fasciaAdvisor .img {
        width: 100%; }
    #buyer .fasciaReady,
    #seller .fasciaReady,
    #about .fasciaReady,
    #signup .fasciaReady {
      z-index: 3;
      padding: 0 30px 60px 30px;
      margin-bottom: 0px; }
      #buyer .fasciaReady:before,
      #seller .fasciaReady:before,
      #about .fasciaReady:before,
      #signup .fasciaReady:before {
        top: -50px;
        height: calc(115%); }
      #buyer .fasciaReady .description .title,
      #seller .fasciaReady .description .title,
      #about .fasciaReady .description .title,
      #signup .fasciaReady .description .title {
        font-size: 20px; }
      #buyer .fasciaReady .description .subtitle,
      #seller .fasciaReady .description .subtitle,
      #about .fasciaReady .description .subtitle,
      #signup .fasciaReady .description .subtitle {
        font-size: 14px; }
      #buyer .fasciaReady .description .getStart,
      #seller .fasciaReady .description .getStart,
      #about .fasciaReady .description .getStart,
      #signup .fasciaReady .description .getStart {
        width: 280px;
        height: 40px;
        font-size: 14px; }
    #buyer .fasciaHelp,
    #seller .fasciaHelp,
    #about .fasciaHelp,
    #signup .fasciaHelp {
      padding: 80px 14px 10px 14px;
      background: #fff;
      margin: -50px 0px 0px 0px; }
      #buyer .fasciaHelp .description,
      #seller .fasciaHelp .description,
      #about .fasciaHelp .description,
      #signup .fasciaHelp .description {
        text-align: center;
        width: 100%; }
        #buyer .fasciaHelp .description .question,
        #seller .fasciaHelp .description .question,
        #about .fasciaHelp .description .question,
        #signup .fasciaHelp .description .question {
          font-size: 20px; }
        #buyer .fasciaHelp .description .testo,
        #seller .fasciaHelp .description .testo,
        #about .fasciaHelp .description .testo,
        #signup .fasciaHelp .description .testo {
          font-size: 14px;
          margin-bottom: 20px; }
    #buyer .footer,
    #seller .footer,
    #about .footer,
    #signup .footer {
      background: #fff;
      background: #fff;
      padding: 14px;
      margin-bottom: 0; }
      #buyer .footer .logoFooter,
      #seller .footer .logoFooter,
      #about .footer .logoFooter,
      #signup .footer .logoFooter {
        text-align: center;
        padding-top: 10px; }
      #buyer .footer .info,
      #seller .footer .info,
      #about .footer .info,
      #signup .footer .info {
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        #buyer .footer .info .capitale,
        #seller .footer .info .capitale,
        #about .footer .info .capitale,
        #signup .footer .info .capitale {
          margin-bottom: 20px; }
  #seller .fasciaFast .img {
    margin-bottom: 30px; }
  #seller .fasciaAdvisor .img {
    width: 100%; }
    #seller .fasciaAdvisor .img img {
      position: initial;
      top: 0px;
      left: 0px;
      width: 70%; }
  #about .fasciaAboutPage {
    padding: 180px 14px 20px;
    margin-bottom: 0px; }
    #about .fasciaAboutPage img {
      top: 5px; }
  #about .fasciaSearch .description {
    padding-bottom: 70px; }
    #about .fasciaSearch .description .btn {
      margin-top: 10px;
      background-color: #fff;
      width: 280px;
      height: 40px;
      border-radius: 4px;
      border: solid 2px #008996;
      color: #008996; }
  #signup .fasciaHeader {
    padding: 0px; }
  #signup .fasciaSignup {
    margin-top: 60px;
    padding: 0px;
    flex-direction: column;
    justify-content: initial;
    background-image: initial;
    height: 100%; }
    #signup .fasciaSignup:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      height: 340px;
      background: #008996;
      transform: skewY(-2deg);
      box-shadow: 0px -15px 25px -15px rgba(0, 0, 0, 0.05);
      margin-bottom: 0;
      z-index: 1; }
    #signup .fasciaSignup .description {
      padding: 0px 14px;
      width: 100%; }
      #signup .fasciaSignup .description .link {
        margin-bottom: 25px; }
        #signup .fasciaSignup .description .link a {
          font-size: 12px; }
      #signup .fasciaSignup .description .started {
        font-family: Barlow;
        color: #f5f8fa;
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px; }
      #signup .fasciaSignup .description .question {
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 20px; }
      #signup .fasciaSignup .description .testo {
        font-size: 14px;
        text-align: center; }
    #signup .fasciaSignup .formSignup {
      width: 100%;
      margin-top: 40px; }
      #signup .fasciaSignup .formSignup form {
        width: 100%;
        padding: 30px 20px !important;
        box-shadow: initial; }
        #signup .fasciaSignup .formSignup form .header {
          margin-bottom: 10px; }
          #signup .fasciaSignup .formSignup form .header .title {
            font-size: 18px; }
          #signup .fasciaSignup .formSignup form .header .logo {
            display: none; }
        #signup .fasciaSignup .formSignup form input:last-child {
          margin-bottom: 30px !important; }
        #signup .fasciaSignup .formSignup form .already {
          flex-direction: column; }
  #signup .footer .logoFooter {
    padding-top: 10px;
    border-top: 1px solid #d8d8d8; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #buyer,
  #seller,
  #about,
  #faq,
  #signup {
    max-width: 100%; }
    #buyer .fasciaHeader,
    #seller .fasciaHeader,
    #about .fasciaHeader,
    #faq .fasciaHeader,
    #signup .fasciaHeader {
      padding: 20px; }
      #buyer .fasciaHeader:before,
      #seller .fasciaHeader:before,
      #about .fasciaHeader:before,
      #faq .fasciaHeader:before,
      #signup .fasciaHeader:before {
        height: calc(100%); }
      #buyer .fasciaHeader #headerPublic,
      #seller .fasciaHeader #headerPublic,
      #about .fasciaHeader #headerPublic,
      #faq .fasciaHeader #headerPublic,
      #signup .fasciaHeader #headerPublic {
        padding: 0 0 20px 0;
        margin-bottom: 40px; }
        #buyer .fasciaHeader #headerPublic #openPublic .login,
        #seller .fasciaHeader #headerPublic #openPublic .login,
        #about .fasciaHeader #headerPublic #openPublic .login,
        #faq .fasciaHeader #headerPublic #openPublic .login,
        #signup .fasciaHeader #headerPublic #openPublic .login {
          width: 100px; }
        #buyer .fasciaHeader #headerPublic #openPublic .signIn,
        #seller .fasciaHeader #headerPublic #openPublic .signIn,
        #about .fasciaHeader #headerPublic #openPublic .signIn,
        #faq .fasciaHeader #headerPublic #openPublic .signIn,
        #signup .fasciaHeader #headerPublic #openPublic .signIn {
          width: 100px; }
      #buyer .fasciaHeader .description .title,
      #seller .fasciaHeader .description .title,
      #about .fasciaHeader .description .title,
      #faq .fasciaHeader .description .title,
      #signup .fasciaHeader .description .title {
        font-size: 32px;
        width: 600px;
        line-height: 40px;
        margin-bottom: 40px; }
      #buyer .fasciaHeader .description img,
      #seller .fasciaHeader .description img,
      #about .fasciaHeader .description img,
      #faq .fasciaHeader .description img,
      #signup .fasciaHeader .description img {
        width: 660px; }
    #buyer .fasciaSearch,
    #buyer .fasciaFast,
    #buyer .fasciaAdvisor,
    #buyer .fasciaReady,
    #buyer .fasciaHelp,
    #buyer .footer,
    #seller .fasciaSearch,
    #seller .fasciaFast,
    #seller .fasciaAdvisor,
    #seller .fasciaReady,
    #seller .fasciaHelp,
    #seller .footer,
    #about .fasciaSearch,
    #about .fasciaFast,
    #about .fasciaAdvisor,
    #about .fasciaReady,
    #about .fasciaHelp,
    #about .footer,
    #faq .fasciaSearch,
    #faq .fasciaFast,
    #faq .fasciaAdvisor,
    #faq .fasciaReady,
    #faq .fasciaHelp,
    #faq .footer,
    #signup .fasciaSearch,
    #signup .fasciaFast,
    #signup .fasciaAdvisor,
    #signup .fasciaReady,
    #signup .fasciaHelp,
    #signup .footer {
      padding: 140px 20px 40px; }
      #buyer .fasciaSearch .description,
      #buyer .fasciaFast .description,
      #buyer .fasciaAdvisor .description,
      #buyer .fasciaReady .description,
      #buyer .fasciaHelp .description,
      #buyer .footer .description,
      #seller .fasciaSearch .description,
      #seller .fasciaFast .description,
      #seller .fasciaAdvisor .description,
      #seller .fasciaReady .description,
      #seller .fasciaHelp .description,
      #seller .footer .description,
      #about .fasciaSearch .description,
      #about .fasciaFast .description,
      #about .fasciaAdvisor .description,
      #about .fasciaReady .description,
      #about .fasciaHelp .description,
      #about .footer .description,
      #faq .fasciaSearch .description,
      #faq .fasciaFast .description,
      #faq .fasciaAdvisor .description,
      #faq .fasciaReady .description,
      #faq .fasciaHelp .description,
      #faq .footer .description,
      #signup .fasciaSearch .description,
      #signup .fasciaFast .description,
      #signup .fasciaAdvisor .description,
      #signup .fasciaReady .description,
      #signup .fasciaHelp .description,
      #signup .footer .description {
        padding-left: 15px; }
        #buyer .fasciaSearch .description .title,
        #buyer .fasciaFast .description .title,
        #buyer .fasciaAdvisor .description .title,
        #buyer .fasciaReady .description .title,
        #buyer .fasciaHelp .description .title,
        #buyer .footer .description .title,
        #seller .fasciaSearch .description .title,
        #seller .fasciaFast .description .title,
        #seller .fasciaAdvisor .description .title,
        #seller .fasciaReady .description .title,
        #seller .fasciaHelp .description .title,
        #seller .footer .description .title,
        #about .fasciaSearch .description .title,
        #about .fasciaFast .description .title,
        #about .fasciaAdvisor .description .title,
        #about .fasciaReady .description .title,
        #about .fasciaHelp .description .title,
        #about .footer .description .title,
        #faq .fasciaSearch .description .title,
        #faq .fasciaFast .description .title,
        #faq .fasciaAdvisor .description .title,
        #faq .fasciaReady .description .title,
        #faq .fasciaHelp .description .title,
        #faq .footer .description .title,
        #signup .fasciaSearch .description .title,
        #signup .fasciaFast .description .title,
        #signup .fasciaAdvisor .description .title,
        #signup .fasciaReady .description .title,
        #signup .fasciaHelp .description .title,
        #signup .footer .description .title {
          font-size: 14px; }
        #buyer .fasciaSearch .description .question,
        #buyer .fasciaFast .description .question,
        #buyer .fasciaAdvisor .description .question,
        #buyer .fasciaReady .description .question,
        #buyer .fasciaHelp .description .question,
        #buyer .footer .description .question,
        #seller .fasciaSearch .description .question,
        #seller .fasciaFast .description .question,
        #seller .fasciaAdvisor .description .question,
        #seller .fasciaReady .description .question,
        #seller .fasciaHelp .description .question,
        #seller .footer .description .question,
        #about .fasciaSearch .description .question,
        #about .fasciaFast .description .question,
        #about .fasciaAdvisor .description .question,
        #about .fasciaReady .description .question,
        #about .fasciaHelp .description .question,
        #about .footer .description .question,
        #faq .fasciaSearch .description .question,
        #faq .fasciaFast .description .question,
        #faq .fasciaAdvisor .description .question,
        #faq .fasciaReady .description .question,
        #faq .fasciaHelp .description .question,
        #faq .footer .description .question,
        #signup .fasciaSearch .description .question,
        #signup .fasciaFast .description .question,
        #signup .fasciaAdvisor .description .question,
        #signup .fasciaReady .description .question,
        #signup .fasciaHelp .description .question,
        #signup .footer .description .question {
          font-size: 24px;
          margin-bottom: 10px; }
        #buyer .fasciaSearch .description .testo,
        #buyer .fasciaFast .description .testo,
        #buyer .fasciaAdvisor .description .testo,
        #buyer .fasciaReady .description .testo,
        #buyer .fasciaHelp .description .testo,
        #buyer .footer .description .testo,
        #seller .fasciaSearch .description .testo,
        #seller .fasciaFast .description .testo,
        #seller .fasciaAdvisor .description .testo,
        #seller .fasciaReady .description .testo,
        #seller .fasciaHelp .description .testo,
        #seller .footer .description .testo,
        #about .fasciaSearch .description .testo,
        #about .fasciaFast .description .testo,
        #about .fasciaAdvisor .description .testo,
        #about .fasciaReady .description .testo,
        #about .fasciaHelp .description .testo,
        #about .footer .description .testo,
        #faq .fasciaSearch .description .testo,
        #faq .fasciaFast .description .testo,
        #faq .fasciaAdvisor .description .testo,
        #faq .fasciaReady .description .testo,
        #faq .fasciaHelp .description .testo,
        #faq .footer .description .testo,
        #signup .fasciaSearch .description .testo,
        #signup .fasciaFast .description .testo,
        #signup .fasciaAdvisor .description .testo,
        #signup .fasciaReady .description .testo,
        #signup .fasciaHelp .description .testo,
        #signup .footer .description .testo {
          font-size: 14px;
          margin-bottom: 10px; }
      #buyer .fasciaSearch .img,
      #buyer .fasciaFast .img,
      #buyer .fasciaAdvisor .img,
      #buyer .fasciaReady .img,
      #buyer .fasciaHelp .img,
      #buyer .footer .img,
      #seller .fasciaSearch .img,
      #seller .fasciaFast .img,
      #seller .fasciaAdvisor .img,
      #seller .fasciaReady .img,
      #seller .fasciaHelp .img,
      #seller .footer .img,
      #about .fasciaSearch .img,
      #about .fasciaFast .img,
      #about .fasciaAdvisor .img,
      #about .fasciaReady .img,
      #about .fasciaHelp .img,
      #about .footer .img,
      #faq .fasciaSearch .img,
      #faq .fasciaFast .img,
      #faq .fasciaAdvisor .img,
      #faq .fasciaReady .img,
      #faq .fasciaHelp .img,
      #faq .footer .img,
      #signup .fasciaSearch .img,
      #signup .fasciaFast .img,
      #signup .fasciaAdvisor .img,
      #signup .fasciaReady .img,
      #signup .fasciaHelp .img,
      #signup .footer .img {
        width: 50%; }
        #buyer .fasciaSearch .img img,
        #buyer .fasciaFast .img img,
        #buyer .fasciaAdvisor .img img,
        #buyer .fasciaReady .img img,
        #buyer .fasciaHelp .img img,
        #buyer .footer .img img,
        #seller .fasciaSearch .img img,
        #seller .fasciaFast .img img,
        #seller .fasciaAdvisor .img img,
        #seller .fasciaReady .img img,
        #seller .fasciaHelp .img img,
        #seller .footer .img img,
        #about .fasciaSearch .img img,
        #about .fasciaFast .img img,
        #about .fasciaAdvisor .img img,
        #about .fasciaReady .img img,
        #about .fasciaHelp .img img,
        #about .footer .img img,
        #faq .fasciaSearch .img img,
        #faq .fasciaFast .img img,
        #faq .fasciaAdvisor .img img,
        #faq .fasciaReady .img img,
        #faq .fasciaHelp .img img,
        #faq .footer .img img,
        #signup .fasciaSearch .img img,
        #signup .fasciaFast .img img,
        #signup .fasciaAdvisor .img img,
        #signup .fasciaReady .img img,
        #signup .fasciaHelp .img img,
        #signup .footer .img img {
          width: 100%; }
    #buyer .fasciaFast,
    #seller .fasciaFast,
    #about .fasciaFast,
    #faq .fasciaFast,
    #signup .fasciaFast {
      padding: 120px 20px 120px; }
      #buyer .fasciaFast:before,
      #seller .fasciaFast:before,
      #about .fasciaFast:before,
      #faq .fasciaFast:before,
      #signup .fasciaFast:before {
        top: -30px; }
      #buyer .fasciaFast .img img,
      #seller .fasciaFast .img img,
      #about .fasciaFast .img img,
      #faq .fasciaFast .img img,
      #signup .fasciaFast .img img {
        left: 20px; }
    #buyer .fasciaAdvisor,
    #seller .fasciaAdvisor,
    #about .fasciaAdvisor,
    #faq .fasciaAdvisor,
    #signup .fasciaAdvisor {
      padding: 40px 20px 120px; }
      #buyer .fasciaAdvisor:before,
      #seller .fasciaAdvisor:before,
      #about .fasciaAdvisor:before,
      #faq .fasciaAdvisor:before,
      #signup .fasciaAdvisor:before {
        top: -30px; }
      #buyer .fasciaAdvisor .img img,
      #seller .fasciaAdvisor .img img,
      #about .fasciaAdvisor .img img,
      #faq .fasciaAdvisor .img img,
      #signup .fasciaAdvisor .img img {
        width: 80%; }
    #buyer .fasciaReady,
    #seller .fasciaReady,
    #about .fasciaReady,
    #faq .fasciaReady,
    #signup .fasciaReady {
      padding: 0 0 120px;
      margin-bottom: 30px; }
      #buyer .fasciaReady:before,
      #seller .fasciaReady:before,
      #about .fasciaReady:before,
      #faq .fasciaReady:before,
      #signup .fasciaReady:before {
        top: -55px;
        height: calc(100%); }
      #buyer .fasciaReady .description .title,
      #seller .fasciaReady .description .title,
      #about .fasciaReady .description .title,
      #faq .fasciaReady .description .title,
      #signup .fasciaReady .description .title {
        margin-bottom: 10px; }
      #buyer .fasciaReady .description .subtitle,
      #seller .fasciaReady .description .subtitle,
      #about .fasciaReady .description .subtitle,
      #faq .fasciaReady .description .subtitle,
      #signup .fasciaReady .description .subtitle {
        max-width: 450px; }
      #buyer .fasciaReady .description .getStart,
      #seller .fasciaReady .description .getStart,
      #about .fasciaReady .description .getStart,
      #faq .fasciaReady .description .getStart,
      #signup .fasciaReady .description .getStart {
        width: 300px;
        height: 50px;
        font-size: 20px; }
    #buyer .fasciaHelp,
    #seller .fasciaHelp,
    #about .fasciaHelp,
    #faq .fasciaHelp,
    #signup .fasciaHelp {
      padding: 0 0 30px 0;
      margin: -45px 0 0 0; }
    #buyer .footer,
    #seller .footer,
    #about .footer,
    #faq .footer,
    #signup .footer {
      padding: 20px 20px; }
      #buyer .footer .info .capitale,
      #seller .footer .info .capitale,
      #about .footer .info .capitale,
      #faq .footer .info .capitale,
      #signup .footer .info .capitale {
        width: 80%; }
  #seller .fasciaSearch {
    align-items: center;
    margin-bottom: 105px; }
    #seller .fasciaSearch .img img {
      width: 80%; }
  #seller .fasciaFast {
    padding: 40px 20px; }
  #seller .fasciaAdvisor {
    padding: 60px 20px 120px; }
    #seller .fasciaAdvisor .img img {
      width: 110%; }
  #about .fasciaHeader #headerPublic {
    margin-bottom: 0; }
  #about .fasciaAboutPage {
    padding: 40px 0 0 40px;
    margin-bottom: 120px; }
    #about .fasciaAboutPage .description {
      width: 60%; }
      #about .fasciaAboutPage .description .question {
        font-size: 32px;
        margin-bottom: 10px; }
  #about .fasciaSearch {
    padding: 0 20px;
    margin-bottom: 120px;
    align-items: center; }
    #about .fasciaSearch .img {
      width: 40%; }
    #about .fasciaSearch .description {
      width: 60%;
      padding-right: 20px;
      padding-left: 20px; }
      #about .fasciaSearch .description .btn {
        margin: 20px auto 0 auto; }
  #signup {
    height: calc(100vh - 255px); }
    #signup .fasciaHeader {
      padding-bottom: 0px; }
      #signup .fasciaHeader #headerPublic {
        margin-bottom: 0; }
    #signup .fasciaSignup {
      background-size: 100% 80%;
      padding: 0px 30px;
      height: 100%; }
      #signup .fasciaSignup .description {
        padding-right: 10px;
        width: 40%; }
        #signup .fasciaSignup .description .link {
          margin-bottom: 30px;
          margin-top: 20px; }
        #signup .fasciaSignup .description .question {
          font-size: 20px;
          line-height: 26px;
          margin-top: 10px;
          margin-bottom: 20px; }
      #signup .fasciaSignup .formSignup {
        width: 60%; }
        #signup .fasciaSignup .formSignup form {
          padding: 20px !important;
          width: 100%; }
          #signup .fasciaSignup .formSignup form .header {
            margin-bottom: 20px; } }
