#Tabs {
  width: 100%; }

.nav-tabs > li > a {
  color: white;
  text-decoration: none; }

.nav-tabs li {
  width: 50%;
  border-right: 1px solid #dee0e2; }
  .nav-tabs li a {
    position: relative; }
    .nav-tabs li a .barraActive {
      display: none; }
    .nav-tabs li a.active .barraActive {
      display: block;
      position: absolute;
      bottom: -20px;
      left: 80px;
      width: calc(100% - 160px);
      height: 3px;
      background: #FFF; }

.nav-tabs {
  background: #455a64;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.2);
  height: 4em;
  font-family: Barlow;
  text-align: center;
  align-items: center;
  justify-content: center; }

#messagecontainer {
  background: white;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.07); }
  #messagecontainer .new {
    background-color: rgba(0, 193, 192, 0.1);
    border: solid 1px #008996;
    border-radius: 10px; }
  #messagecontainer .containerMessages {
    padding: 10px 10px 15px 10px;
    border-bottom: 1px solid #dee0e2; }
  #messagecontainer #content {
    padding: 10px 10px 0 10px; }
  #messagecontainer .attachments {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px; }
    #messagecontainer .attachments .topic span {
      padding-top: 2px;
      padding-bottom: 2px; }
    #messagecontainer .attachments a {
      display: flex;
      height: 24px; }
    #messagecontainer .attachments .portafoglioMessage, #messagecontainer .attachments .posizioneMessage {
      padding-left: 10px;
      padding-right: 10px;
      font-family: Barlow;
      font-size: 14px;
      color: #455a64;
      border-radius: 12px;
      border: solid 1px #008996;
      margin-right: 15px; }
    #messagecontainer .attachments .attachmentsMessage {
      border-left: 1px solid #dee0e2;
      height: 30px; }
    #messagecontainer .attachments .divider {
      border-left: 1px solid #38546d;
      border-right: 1px solid #16222c; }
    #messagecontainer .attachments .attachmentsMessage {
      padding-left: 10px;
      font-family: Barlow;
      color: #455a64; }
  #messagecontainer .pTesto {
    padding: 10px 100px 0 10px; }
    #messagecontainer .pTesto #nomeInvestitore {
      font-family: Barlow-bold;
      color: #979da5;
      font-size: 14px; }
  #messagecontainer #testoMessaggio {
    font-family: Barlow;
    color: #979da5;
    font-size: 14px; }
  #messagecontainer #utente {
    font-family: Barlow-semiBold;
    color: #455a64; }
  #messagecontainer #dataMessaggio {
    padding-right: 100px;
    color: #455a64;
    font-family: Barlow;
    font-size: 14px; }

.nav-tabs a {
  width: 100%;
  display: block;
  color: black;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent; }

.contentArrow {
  display: flex;
  align-items: center;
  justify-content: center; }
  .contentArrow #arrow {
    padding-right: 20px;
    color: #008996; }

#circle {
  margin: 15px 15px 0 25px;
  padding: 10px 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Barlow;
  font-size: 16px;
  background-image: linear-gradient(to right, #00bec9, #008998); }
  #circle.gestore {
    background-image: linear-gradient(to right, #b453cd, #7d299f); }

#aperte h2 {
  padding: 40px 0 20px;
  font-family: Barlow;
  color: #455a64;
  font-weight: normal; }

#chiuse h2 {
  padding: 40px 0 20px;
  font-family: Barlow;
  color: #455a64;
  font-weight: normal; }

#container, #tab-content {
  width: 970px;
  box-shadow: none;
  margin: 0 auto; }

#yourmess {
  font-size: 32px;
  color: #455a64;
  padding: 30px 0 5px; }

#messp {
  font-family: Barlow-italic;
  font-size: 16px;
  color: #455a64;
  margin-bottom: 50px; }

.eliminaGestore {
  float: right; }

.contentBidone {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px; }
  .contentBidone #bidone {
    justify-content: center;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    border: solid 1.8px #455a64;
    display: flex;
    align-items: center; }
    .contentBidone #bidone a {
      font-family: Barlow-med;
      font-size: 15px; }

.smallAlert .react-confirm-alert {
  max-width: 370px;
  height: 248px;
  border-radius: 10px;
  box-shadow: 0 60px 100px 0 rgba(69, 90, 100, 0.5); }
  .smallAlert .react-confirm-alert .react-confirm-alert-body {
    width: 100%;
    height: 100%; }
    .smallAlert .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group {
      display: flex;
      justify-content: space-evenly;
      width: 100%; }
      .smallAlert .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button {
        font-size: 14px; }
        .smallAlert .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button:first-child {
          background: #008996;
          color: #FFF; }

@media (min-width: 320px) and (max-width: 480px) {
  #container, #tab-content {
    width: 100%; }
  #yourmess {
    font-size: 20px; }
  #messp {
    font-size: 14px;
    padding: 0 30px;
    text-align: center;
    margin-bottom: 40px; }
  .nav-tabs {
    height: 50px; }
  .nav-tabs > li > a {
    font-size: 12px; }
    .nav-tabs > li > a.active .barraActive {
      bottom: -16px;
      left: 30px;
      width: calc(100% - 60px); }
  #aperte h2, #chiuse h2 {
    padding: 20px 0 20px;
    font-size: 16px !important; }
  #msg {
    width: 100%; }
  #messagecontainer .containerMessages {
    padding: 10px 10px 15px 10px;
    border-bottom: 1px solid #dee0e2; }
  #messagecontainer #circle {
    padding: 0px;
    margin: 20px 10px 0 0px; }
  #messagecontainer #content {
    padding: 15px 10px 0 0px;
    position: relative; }
    #messagecontainer #content #utente {
      font-size: 14px; }
    #messagecontainer #content .cntData {
      position: absolute;
      right: 0;
      top: -5px; }
      #messagecontainer #content .cntData #dataMessaggio {
        padding-right: 0px;
        font-size: 12px; }
  #messagecontainer .pTesto {
    padding: 0px;
    position: relative; }
    #messagecontainer .pTesto.circleAttach {
      padding-right: 25px; }
      #messagecontainer .pTesto.circleAttach .attachments .attachmentsMessage {
        position: absolute;
        right: 0;
        top: 30%;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #008996;
        display: flex;
        justify-content: center;
        align-items: center;
        ont-family: Barlow;
        font-size: 14px;
        color: #FFF;
        padding: 0; }
    #messagecontainer .pTesto #nomeInvestitore, #messagecontainer .pTesto #testoMessaggio {
      font-size: 12px; }
    #messagecontainer .pTesto .attachments a .portafoglioMessage, #messagecontainer .pTesto .attachments a .posizioneMessage {
      font-size: 12px;
      border: initial;
      text-decoration: underline;
      padding: 0px;
      margin-right: 5px; }
  #messagecontainer.new {
    background-color: rgba(0, 193, 192, 0.1);
    border: solid 1px #008996; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #container, #tab-content {
    width: 100%; }
  .nav-tabs > li > a.active .barraActive {
    bottom: -20px; } }
