#signup .formContainer {
  display: block; }
  #signup .formContainer.disabled {
    display: none; }

#signup .checkContainer, #signup .textareaContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px; }
  #signup .checkContainer.disabled, #signup .textareaContainer.disabled {
    display: none; }

#signup .checkLabel {
  font-size: 12px;
  color: #455a64;
  margin-left: 10px; }
  #signup .checkLabel a {
    color: #008996;
    font-weight: bold; }

#signup .checkPrivacy {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid #008996;
  cursor: pointer;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }
  #signup .checkPrivacy.selected:before {
    content: "\2713";
    color: #008996; }

#signup .selectContainer {
  font-size: 12px;
  color: #455a64;
  margin-bottom: 16px; }
  #signup .selectContainer.disabled {
    display: none; }
  #signup .selectContainer .selectLabel {
    margin-bottom: 10px; }
  #signup .selectContainer .custom-select {
    font-size: 12px;
    color: #455a64; }

#signup .error-area {
  border: 1px solid red; }
