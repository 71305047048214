.custom-ui {
  width: 570px; }

.react-confirm-alert-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

#areaPubblica {
  max-width: 1366px;
  margin: 0 auto; }
  #areaPubblica .fasciaHeader {
    padding: 45px 95px;
    position: relative;
    background-color: transparent; }
    #areaPubblica .fasciaHeader:before {
      content: "";
      position: absolute;
      top: -200px;
      left: 0;
      width: 100%;
      height: calc(85%);
      background: #008996;
      -webkit-transform: skewY(-15deg);
      -moz-transform: skewY(-15deg);
      -ms-transform: skewY(-15deg);
      -o-transform: skewY(-15deg);
      transform: skewY(-15deg); }
    #areaPubblica .fasciaHeader #headerPublic {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 70px; }
      #areaPubblica .fasciaHeader #headerPublic a {
        text-decoration: none;
        color: #fff; }
        #areaPubblica .fasciaHeader #headerPublic a img {
          content: url("../../styles/images/logoBianco.png"); }
      #areaPubblica .fasciaHeader #headerPublic .logo {
        display: flex;
        align-items: center; }
        #areaPubblica .fasciaHeader #headerPublic .logo a {
          font-size: 20px; }
        #areaPubblica .fasciaHeader #headerPublic .logo img {
          width: 140px;
          margin-right: 5px; }
      #areaPubblica .fasciaHeader #headerPublic .linkHeader {
        font-family: Barlow;
        display: flex;
        align-items: center; }
        #areaPubblica .fasciaHeader #headerPublic .linkHeader a {
          margin-right: 30px; }
          #areaPubblica .fasciaHeader #headerPublic .linkHeader a:last-child {
            margin-right: 0; }
        #areaPubblica .fasciaHeader #headerPublic .linkHeader .login,
        #areaPubblica .fasciaHeader #headerPublic .linkHeader .signIn {
          font-family: Barlow-bold;
          margin: 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 170px;
          height: 40px;
          border-radius: 4px;
          border: solid 1px #ccf2f2;
          cursor: pointer; }
          #areaPubblica .fasciaHeader #headerPublic .linkHeader .login svg,
          #areaPubblica .fasciaHeader #headerPublic .linkHeader .signIn svg {
            margin-right: 10px; }
          #areaPubblica .fasciaHeader #headerPublic .linkHeader .login a,
          #areaPubblica .fasciaHeader #headerPublic .linkHeader .signIn a {
            display: flex;
            align-items: center; }
          #areaPubblica .fasciaHeader #headerPublic .linkHeader .login.signIn,
          #areaPubblica .fasciaHeader #headerPublic .linkHeader .signIn.signIn {
            background: #fff;
            color: #008996; }
    #areaPubblica .fasciaHeader .description {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-family: Barlow;
      color: #ffffff;
      text-align: center; }
      #areaPubblica .fasciaHeader .description .title {
        max-width: 713px;
        font-size: 48px;
        margin-bottom: 30px; }
      #areaPubblica .fasciaHeader .description .subtitle,
      #areaPubblica .fasciaHeader .description .subsubtitle {
        max-width: 565px;
        font-size: 16px;
        margin-bottom: 30px; }
      #areaPubblica .fasciaHeader .description img {
        margin-top: 0px; }
  #areaPubblica .fasciaBuyer,
  #areaPubblica .fasciaSeller,
  #areaPubblica .fasciaAbout,
  #areaPubblica .fasciaHelp,
  #areaPubblica .fasciaLux {
    padding: 45px 95px;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    margin-bottom: 112px; }
    #areaPubblica .fasciaBuyer .description,
    #areaPubblica .fasciaSeller .description,
    #areaPubblica .fasciaAbout .description,
    #areaPubblica .fasciaHelp .description,
    #areaPubblica .fasciaLux .description {
      position: relative;
      z-index: 1;
      width: 50%;
      padding-right: 30px;
      display: flex;
      flex-direction: column; }
      #areaPubblica .fasciaBuyer .description .title,
      #areaPubblica .fasciaSeller .description .title,
      #areaPubblica .fasciaAbout .description .title,
      #areaPubblica .fasciaHelp .description .title,
      #areaPubblica .fasciaLux .description .title {
        font-family: Barlow-semiBold;
        font-size: 16px;
        color: #008996;
        margin-bottom: 10px; }
      #areaPubblica .fasciaBuyer .description .question,
      #areaPubblica .fasciaSeller .description .question,
      #areaPubblica .fasciaAbout .description .question,
      #areaPubblica .fasciaHelp .description .question,
      #areaPubblica .fasciaLux .description .question {
        font-family: Barlow;
        font-size: 32px;
        color: #000;
        margin-bottom: 30px; }
      #areaPubblica .fasciaBuyer .description .testo,
      #areaPubblica .fasciaSeller .description .testo,
      #areaPubblica .fasciaAbout .description .testo,
      #areaPubblica .fasciaHelp .description .testo,
      #areaPubblica .fasciaLux .description .testo {
        font-family: Barlow;
        font-size: 16px;
        color: #000;
        margin-bottom: 30px; }
      #areaPubblica .fasciaBuyer .description a:hover,
      #areaPubblica .fasciaSeller .description a:hover,
      #areaPubblica .fasciaAbout .description a:hover,
      #areaPubblica .fasciaHelp .description a:hover,
      #areaPubblica .fasciaLux .description a:hover {
        text-decoration: none; }
      #areaPubblica .fasciaBuyer .description .btn,
      #areaPubblica .fasciaSeller .description .btn,
      #areaPubblica .fasciaAbout .description .btn,
      #areaPubblica .fasciaHelp .description .btn,
      #areaPubblica .fasciaLux .description .btn {
        font-family: Barlow-Bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 209px;
        height: 40px;
        border-radius: 4px;
        border: solid 2px #008996;
        color: #008996;
        cursor: pointer; }
    #areaPubblica .fasciaBuyer .img,
    #areaPubblica .fasciaSeller .img,
    #areaPubblica .fasciaAbout .img,
    #areaPubblica .fasciaHelp .img,
    #areaPubblica .fasciaLux .img {
      position: relative;
      z-index: 1; }
  #areaPubblica .fasciaSeller,
  #areaPubblica .fasciaLux {
    margin-top: 133px; }
    #areaPubblica .fasciaSeller:before,
    #areaPubblica .fasciaLux:before {
      content: "";
      position: absolute;
      top: -100px;
      left: 0;
      width: 100%;
      height: calc(165%);
      background: #f5f8fa;
      -webkit-transform: skewY(6deg);
      -moz-transform: skewY(6deg);
      -ms-transform: skewY(6deg);
      -o-transform: skewY(6deg);
      transform: skewY(6deg);
      z-index: 2; }
    #areaPubblica .fasciaSeller .description,
    #areaPubblica .fasciaLux .description {
      z-index: 3;
      padding-right: initial; }
    #areaPubblica .fasciaSeller .img,
    #areaPubblica .fasciaLux .img {
      z-index: 3;
      padding-right: 80px; }
  #areaPubblica .fasciaLux {
    margin-bottom: 80px; }
    #areaPubblica .fasciaLux:before {
      height: calc(150%); }
  #areaPubblica .fasciaAbout {
    padding-top: 100px; }
    #areaPubblica .fasciaAbout .img {
      position: relative;
      z-index: 1;
      width: 50%; }
      #areaPubblica .fasciaAbout .img img {
        position: absolute;
        top: -160px;
        left: 15px; }
  #areaPubblica .fasciaReady {
    padding: 45px 95px;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: center;
    margin-bottom: 62px;
    padding-top: 140px; }
    #areaPubblica .fasciaReady:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: calc(115%);
      background: #008996;
      -webkit-transform: skewY(6deg);
      -moz-transform: skewY(6deg);
      -ms-transform: skewY(6deg);
      -o-transform: skewY(6deg);
      transform: skewY(6deg);
      z-index: 1; }
    #areaPubblica .fasciaReady .description {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-family: Barlow;
      color: #ffffff;
      text-align: center; }
      #areaPubblica .fasciaReady .description .title {
        max-width: 713px;
        font-size: 40px;
        margin-bottom: 20px; }
      #areaPubblica .fasciaReady .description .subtitle {
        font-family: Barlow-light;
        max-width: 565px;
        font-size: 16px;
        margin-bottom: 20px; }
      #areaPubblica .fasciaReady .description .getStart {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 370px;
        height: 64px;
        border-radius: 4px;
        box-shadow: 0 5px 20px 0 rgba(255, 255, 255, 0.8);
        background-color: #fff;
        color: #008996;
        font-family: Barlow-bold;
        font-size: 24px; }
  #areaPubblica .fasciaHelp {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 30px;
    margin-bottom: 30px;
    padding-top: 20px; }
    #areaPubblica .fasciaHelp .description {
      flex-direction: row;
      align-items: center; }
      #areaPubblica .fasciaHelp .description .question {
        font-size: 24px;
        margin-bottom: 12px; }
      #areaPubblica .fasciaHelp .description .testo {
        margin-bottom: 5px;
        color: #008996; }
        #areaPubblica .fasciaHelp .description .testo span {
          margin-left: 10px;
          color: #008996;
          cursor: pointer;
          border-bottom: 1px solid;
          padding-bottom: 5px; }
  #areaPubblica .footer {
    padding: 30px 95px;
    margin-bottom: 30px; }
    #areaPubblica .footer .logoFooter {
      margin-bottom: 20px; }
      #areaPubblica .footer .logoFooter a {
        text-decoration: none;
        color: #828282; }
        #areaPubblica .footer .logoFooter a img {
          width: 140px;
          margin-right: 5px; }
    #areaPubblica .footer .info {
      font-family: Barlow;
      font-size: 12px;
      color: #6d7278; }
      #areaPubblica .footer .info .link {
        margin-top: 20px;
        display: flex;
        align-items: center;
        color: #6d7278; }
        #areaPubblica .footer .info .link .privacy {
          cursor: pointer; }
          #areaPubblica .footer .info .link .privacy a {
            font-size: 12px;
            color: #6d7278;
            text-decoration: none; }
        #areaPubblica .footer .info .link .lux {
          margin-left: 30px;
          cursor: pointer; }
          #areaPubblica .footer .info .link .lux a {
            font-size: 12px;
            color: #6d7278;
            text-decoration: none; }

@media (min-width: 320px) and (max-width: 480px) {
  #areaPubblica {
    max-width: 100vw; }
    #areaPubblica .fasciaHeader {
      padding: 14px;
      height: 100%;
      background: #008996; }
      #areaPubblica .fasciaHeader:before {
        content: initial; }
      #areaPubblica .fasciaHeader #headerPublic {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        transition: background-color 0.3s;
        background: #008996; }
        #areaPubblica .fasciaHeader #headerPublic.white {
          background: #fff; }
          #areaPubblica .fasciaHeader #headerPublic.white .menuMobile {
            box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07); }
          #areaPubblica .fasciaHeader #headerPublic.white .logo a {
            color: #aaa;
            font-size: 14px; }
          #areaPubblica .fasciaHeader #headerPublic.white .logo img {
            width: 77px;
            content: url("../../styles/images/header.png"); }
        #areaPubblica .fasciaHeader #headerPublic .menuMobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 14px 17px; }
        #areaPubblica .fasciaHeader #headerPublic .linkHeader {
          overflow: hidden;
          -webkit-transition: height 0.3s linear;
          -moz-transition: height 0.3s linear;
          transition: height 0.3s linear;
          opacity: 0;
          height: 0;
          justify-content: space-between;
          width: calc(100% - 30px); }
          #areaPubblica .fasciaHeader #headerPublic .linkHeader.open {
            opacity: 1;
            display: flex;
            flex-direction: column;
            height: calc(100vh - 50px); }
          #areaPubblica .fasciaHeader #headerPublic .linkHeader .linkMenu {
            width: 100%; }
            #areaPubblica .fasciaHeader #headerPublic .linkHeader .linkMenu a {
              text-align: center;
              margin-right: 0px;
              color: #000; }
              #areaPubblica .fasciaHeader #headerPublic .linkHeader .linkMenu a h5 {
                padding: 20px 0;
                border-bottom: 1px solid; }
          #areaPubblica .fasciaHeader #headerPublic .linkHeader .buttonMenu {
            padding-bottom: 20px; }
            #areaPubblica .fasciaHeader #headerPublic .linkHeader .buttonMenu .login {
              width: 290px;
              border: solid 1px #008996;
              color: #008996; }
            #areaPubblica .fasciaHeader #headerPublic .linkHeader .buttonMenu .signIn {
              width: 290px;
              box-shadow: 0 5px 20px 0 rgba(0, 182, 202, 0.4);
              background-color: #008996;
              color: #fff; }
      #areaPubblica .fasciaHeader .description {
        margin-top: 70px; }
        #areaPubblica .fasciaHeader .description .title {
          font-size: 24px; }
        #areaPubblica .fasciaHeader .description .subtitle,
        #areaPubblica .fasciaHeader .description .subsubtitle {
          margin-bottom: 30px;
          font-size: 14px; }
        #areaPubblica .fasciaHeader .description img {
          margin-top: 0px;
          width: 85%; }
    #areaPubblica .fasciaBuyer,
    #areaPubblica .fasciaSeller,
    #areaPubblica .fasciaAbout,
    #areaPubblica .fasciaLux {
      padding: 14px;
      height: 100%;
      flex-direction: column;
      padding-top: 50px;
      margin-bottom: 0px;
      background: #fff; }
      #areaPubblica .fasciaBuyer:before,
      #areaPubblica .fasciaSeller:before,
      #areaPubblica .fasciaAbout:before,
      #areaPubblica .fasciaLux:before {
        content: "";
        position: absolute;
        top: -65px;
        left: 0;
        width: 100%;
        height: calc(40%);
        background: #fff;
        -webkit-transform: skewY(-15deg);
        -moz-transform: skewY(-15deg);
        -ms-transform: skewY(-15deg);
        -o-transform: skewY(-15deg);
        transform: skewY(-15deg); }
      #areaPubblica .fasciaBuyer .img,
      #areaPubblica .fasciaSeller .img,
      #areaPubblica .fasciaAbout .img,
      #areaPubblica .fasciaLux .img {
        display: flex;
        justify-content: center; }
        #areaPubblica .fasciaBuyer .img img,
        #areaPubblica .fasciaSeller .img img,
        #areaPubblica .fasciaAbout .img img,
        #areaPubblica .fasciaLux .img img {
          width: 70%; }
      #areaPubblica .fasciaBuyer .description,
      #areaPubblica .fasciaSeller .description,
      #areaPubblica .fasciaAbout .description,
      #areaPubblica .fasciaLux .description {
        margin-top: 30px;
        width: 100%;
        padding-right: 0px;
        z-index: 3; }
        #areaPubblica .fasciaBuyer .description .title,
        #areaPubblica .fasciaSeller .description .title,
        #areaPubblica .fasciaAbout .description .title,
        #areaPubblica .fasciaLux .description .title {
          font-size: 12px; }
        #areaPubblica .fasciaBuyer .description .question,
        #areaPubblica .fasciaSeller .description .question,
        #areaPubblica .fasciaAbout .description .question,
        #areaPubblica .fasciaLux .description .question {
          font-size: 20px;
          width: 100%;
          margin-bottom: 10px; }
        #areaPubblica .fasciaBuyer .description .testo,
        #areaPubblica .fasciaSeller .description .testo,
        #areaPubblica .fasciaAbout .description .testo,
        #areaPubblica .fasciaLux .description .testo {
          font-size: 14px;
          margin-bottom: 20px; }
        #areaPubblica .fasciaBuyer .description .btn,
        #areaPubblica .fasciaSeller .description .btn,
        #areaPubblica .fasciaAbout .description .btn,
        #areaPubblica .fasciaLux .description .btn {
          width: 290px;
          font-size: 14px;
          margin: 0 auto; }
    #areaPubblica .fasciaSeller,
    #areaPubblica .fasciaLux {
      padding-top: 90px;
      margin-top: 0; }
      #areaPubblica .fasciaSeller:before,
      #areaPubblica .fasciaLux:before {
        content: "";
        position: absolute;
        top: 30px;
        left: 0;
        width: 100%;
        height: calc(105%);
        background: #f5f8fa;
        -webkit-transform: skewY(6deg);
        -moz-transform: skewY(6deg);
        -ms-transform: skewY(6deg);
        -o-transform: skewY(6deg);
        transform: skewY(6deg);
        z-index: 2; }
      #areaPubblica .fasciaSeller .img,
      #areaPubblica .fasciaLux .img {
        margin-left: -14px;
        justify-content: flex-start; }
        #areaPubblica .fasciaSeller .img img,
        #areaPubblica .fasciaLux .img img {
          width: 115%; }
    #areaPubblica .fasciaLux {
      margin-bottom: 100px; }
    #areaPubblica .fasciaAbout {
      margin-top: 0;
      padding: 0 0 50px 0; }
      #areaPubblica .fasciaAbout:before {
        content: initial; }
      #areaPubblica .fasciaAbout .img {
        width: 100%; }
        #areaPubblica .fasciaAbout .img img {
          top: 0;
          left: 0;
          position: relative;
          width: 100%; }
        #areaPubblica .fasciaAbout .img .gradient {
          position: absolute;
          width: 100%;
          height: 50px;
          background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
          bottom: 69px;
          left: 0; }
      #areaPubblica .fasciaAbout .description {
        margin-top: -70px;
        background: #fff;
        padding: 14px; }
    #areaPubblica .fasciaReady {
      padding: 0 30px 60px 30px;
      margin-bottom: 0px; }
      #areaPubblica .fasciaReady:before {
        top: -50px; }
      #areaPubblica .fasciaReady .description .title {
        font-size: 20px; }
      #areaPubblica .fasciaReady .description .subtitle {
        font-size: 14px; }
      #areaPubblica .fasciaReady .description .getStart {
        width: 280px;
        height: 40px;
        font-size: 14px; }
    #areaPubblica .fasciaHelp {
      padding: 80px 14px 10px 14px;
      background: #fff;
      margin-top: -60px;
      margin-bottom: 0; }
      #areaPubblica .fasciaHelp .description {
        text-align: center;
        width: 100%; }
        #areaPubblica .fasciaHelp .description .question {
          font-size: 20px; }
        #areaPubblica .fasciaHelp .description .testo {
          font-size: 14px; }
    #areaPubblica .footer {
      background: #fff;
      padding: 14px;
      margin-bottom: 0; }
      #areaPubblica .footer .logoFooter {
        text-align: center;
        padding-top: 10px; }
      #areaPubblica .footer .info {
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        #areaPubblica .footer .info .capitale {
          margin-bottom: 20px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #areaPubblica {
    max-width: 100%; }
    #areaPubblica .fasciaHeader {
      padding: 20px; }
      #areaPubblica .fasciaHeader:before {
        height: calc(100%); }
      #areaPubblica .fasciaHeader #headerPublic {
        margin-bottom: 40px; }
        #areaPubblica .fasciaHeader #headerPublic #openPublic .login {
          width: 100px; }
        #areaPubblica .fasciaHeader #headerPublic #openPublic .signIn {
          width: 100px; }
      #areaPubblica .fasciaHeader .description .title {
        font-size: 32px;
        width: 600px;
        line-height: 40px; }
      #areaPubblica .fasciaHeader .description img {
        width: 660px; }
    #areaPubblica .fasciaBuyer,
    #areaPubblica .fasciaSeller,
    #areaPubblica .fasciaAbout,
    #areaPubblica .fasciaReady,
    #areaPubblica .fasciaHelp,
    #areaPubblica .footer,
    #areaPubblica .fasciaLux {
      padding: 40px 20px; }
      #areaPubblica .fasciaBuyer .description,
      #areaPubblica .fasciaSeller .description,
      #areaPubblica .fasciaAbout .description,
      #areaPubblica .fasciaReady .description,
      #areaPubblica .fasciaHelp .description,
      #areaPubblica .footer .description,
      #areaPubblica .fasciaLux .description {
        padding-right: 15px; }
        #areaPubblica .fasciaBuyer .description .title,
        #areaPubblica .fasciaSeller .description .title,
        #areaPubblica .fasciaAbout .description .title,
        #areaPubblica .fasciaReady .description .title,
        #areaPubblica .fasciaHelp .description .title,
        #areaPubblica .footer .description .title,
        #areaPubblica .fasciaLux .description .title {
          font-size: 14px; }
        #areaPubblica .fasciaBuyer .description .question,
        #areaPubblica .fasciaSeller .description .question,
        #areaPubblica .fasciaAbout .description .question,
        #areaPubblica .fasciaReady .description .question,
        #areaPubblica .fasciaHelp .description .question,
        #areaPubblica .footer .description .question,
        #areaPubblica .fasciaLux .description .question {
          font-size: 24px;
          margin-bottom: 10px; }
        #areaPubblica .fasciaBuyer .description .testo,
        #areaPubblica .fasciaSeller .description .testo,
        #areaPubblica .fasciaAbout .description .testo,
        #areaPubblica .fasciaReady .description .testo,
        #areaPubblica .fasciaHelp .description .testo,
        #areaPubblica .footer .description .testo,
        #areaPubblica .fasciaLux .description .testo {
          font-size: 14px; }
      #areaPubblica .fasciaBuyer .img,
      #areaPubblica .fasciaSeller .img,
      #areaPubblica .fasciaAbout .img,
      #areaPubblica .fasciaReady .img,
      #areaPubblica .fasciaHelp .img,
      #areaPubblica .footer .img,
      #areaPubblica .fasciaLux .img {
        width: 50%; }
        #areaPubblica .fasciaBuyer .img img,
        #areaPubblica .fasciaSeller .img img,
        #areaPubblica .fasciaAbout .img img,
        #areaPubblica .fasciaReady .img img,
        #areaPubblica .fasciaHelp .img img,
        #areaPubblica .footer .img img,
        #areaPubblica .fasciaLux .img img {
          width: 100%; }
    #areaPubblica .fasciaSeller,
    #areaPubblica .fasciaLux {
      padding: 20px 0 40px;
      margin-top: 90px;
      margin-bottom: 0px;
      align-items: center; }
      #areaPubblica .fasciaSeller:before,
      #areaPubblica .fasciaLux:before {
        top: -50px;
        height: 120%; }
      #areaPubblica .fasciaSeller .img,
      #areaPubblica .fasciaLux .img {
        padding-right: 20px; }
    #areaPubblica .fasciaAbout {
      margin-bottom: 0px; }
      #areaPubblica .fasciaAbout .img img {
        left: 20px;
        top: -45px; }
    #areaPubblica .fasciaReady {
      margin-bottom: 30px; }
      #areaPubblica .fasciaReady:before {
        top: 30px;
        height: calc(100%); }
      #areaPubblica .fasciaReady .description {
        margin-top: 45px; }
        #areaPubblica .fasciaReady .description .title {
          margin-bottom: 10px; }
        #areaPubblica .fasciaReady .description .subtitle {
          max-width: 450px; }
        #areaPubblica .fasciaReady .description .getStart {
          width: 300px;
          height: 50px;
          font-size: 20px; }
    #areaPubblica .footer {
      padding: 0px 20px; }
      #areaPubblica .footer .info .capitale {
        width: 80%; } }
