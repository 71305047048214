.infoMarker {
  width: 280px;
  height: 100%;
  font-family: Arial;
  padding: 5px 0px 5px 8px; }
  .infoMarker .header {
    color: #455a64;
    font-family: Barlow-semiBold;
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #eceff1;
    font-size: 14px; }
    .infoMarker .header svg {
      fill: #455a64;
      width: 20px;
      margin-right: 15px; }
  .infoMarker .corpo {
    margin: 20px 0;
    text-align: center;
    font-size: 14px; }
    .infoMarker .corpo .desc {
      color: #000;
      font-family: Barlow-semiBold;
      line-height: 22px; }
    .infoMarker .corpo .stato {
      display: block;
      font-size: 12px;
      font-family: Barlow;
      margin-bottom: 18px;
      color: #455a64; }
    .infoMarker .corpo .indirizzo {
      font-family: Barlow;
      font-size: 14px;
      color: #455a64;
      text-decoration: underline;
      line-height: 22px; }
    .infoMarker .corpo .cap {
      display: block;
      font-family: Barlow;
      font-size: 14px;
      color: #455a64;
      text-decoration: underline; }
  .infoMarker .posAppartenenza {
    display: flex;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #e5f8f8;
    align-items: center;
    justify-content: center; }
    .infoMarker .posAppartenenza span {
      color: #008996;
      font-size: 14px;
      font-family: Barlow-semiBold; }

.boxFilter {
  width: 270px;
  height: 133px;
  z-index: 9999;
  position: absolute;
  top: 50px;
  right: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
  background-color: #FFF;
  padding: 20px;
  color: #455a64;
  font-family: Barlow; }
  .boxFilter input[type=checkbox] {
    min-width: 19px;
    min-height: 19px;
    margin-right: 10px; }
  .boxFilter img {
    width: 25px;
    height: 100%; }
  .boxFilter .asset {
    align-items: center;
    padding: 20px 0 10px; }
  .boxFilter .debitori {
    align-items: center; }

.iconMap {
  background-image: url("../images/header.png"); }

@media (min-width: 320px) and (max-width: 480px) {
  .map #expandMap, .map #compressMap {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1; }
    .map #expandMap svg, .map #compressMap svg {
      width: 25px;
      fill: #455a64; }
  .map #compressMap {
    display: none; }
  .map #expandMap {
    top: 45px;
    display: block; }
  .map #buttonMapFiltra {
    display: none; }
  .map.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 30;
    margin: 0; }
    .map.fullscreen #compressMap {
      display: block; }
    .map.fullscreen #expandMap {
      display: none; }
    .map.fullscreen #buttonMapFiltra {
      position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: solid 2px #7d299f;
      background-color: #FFF;
      justify-content: center;
      align-items: center;
      z-index: 1; }
      .map.fullscreen #buttonMapFiltra svg {
        width: 16px;
        display: inline-block;
        fill: #7d299f; }
  .gm-style-iw-t {
    padding-bottom: 60px !important; }
    .gm-style-iw-t::after {
      content: '' !important;
      height: 0 !important; }
  .gm-style-iw.gm-style-iw-c {
    max-width: 300px !important; }
    .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
      max-width: 260px !important; }
  .infoMarker {
    width: 240px; }
    .infoMarker .header {
      font-size: 12px; }
      .infoMarker .header svg {
        width: 16px; }
    .infoMarker .corpo {
      font-size: 14px; }
      .infoMarker .corpo .desc {
        line-height: 18px; }
      .infoMarker .corpo .indirizzo {
        font-family: Barlow;
        font-size: 14px;
        color: #455a64;
        text-decoration: underline;
        line-height: 22px; }
  .boxFilter {
    width: 190px;
    right: -190px;
    border-radius: 10px 0 0 10px;
    transition: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1); }
    .boxFilter svg {
      transform: rotate(0deg); }
    .boxFilter.show {
      transition: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
      right: 0px; }
      .boxFilter.show svg {
        transform: rotate(180deg); }
    .boxFilter #btnMobile {
      width: 30px;
      height: 40px;
      border-radius: 4px 0 0 4px;
      background-color: #455a64;
      position: absolute;
      top: 20px;
      left: -30px;
      display: flex;
      justify-content: center;
      align-items: center; } }
