.autocopileHashtag .headerInput {
  width: 350px;
  height: 50px;
  border: 1px solid #e8e8ea;
  border-radius: 4px;
  margin: 0 auto;
  padding: 0 20px 0 10px;
  margin-bottom: 30px; }
  .autocopileHashtag .headerInput .input {
    width: 100%; }
    .autocopileHashtag .headerInput .input input {
      border: none;
      font-family: Barlow-italic;
      font-size: .875em;
      line-height: 1.5;
      color: #979da5; }
      .autocopileHashtag .headerInput .input input:focus {
        border: none;
        outline: none; }
    .autocopileHashtag .headerInput .input svg {
      cursor: pointer; }
  .autocopileHashtag .headerInput.filter {
    width: 100%;
    background: #fff;
    height: 40px;
    margin-bottom: 20px; }

.autocopileHashtag .contentListHash {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  /* Track */
  /* Handle */ }
  .autocopileHashtag .contentListHash::-webkit-scrollbar {
    width: 6px;
    -webkit-border-radius: 10px;
    border-radius: 10px; }
  .autocopileHashtag .contentListHash::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px; }
  .autocopileHashtag .contentListHash::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #ddd;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }
  .autocopileHashtag .contentListHash::-webkit-scrollbar-thumb:window-inactive {
    background: #ddd;
    -webkit-border-radius: 10px;
    border-radius: 10px; }

.autocopileHashtag .listHashTag {
  display: flex;
  flex-wrap: wrap; }
  .autocopileHashtag .listHashTag .itemHash {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    border-radius: 20px;
    background: #fff;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    -moz-box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    box-shadow: 0 5px 20px 0 rgba(0, 34, 40, 0.07);
    margin-bottom: 30px;
    cursor: pointer; }
    .autocopileHashtag .listHashTag .itemHash.selected {
      border: 2px solid #22b4b1;
      -webkit-box-shadow: 0 5px 20px 0 rgba(34, 180, 177, 0.5);
      -moz-box-shadow: 0 5px 20px 0 rgba(34, 180, 177, 0.5);
      box-shadow: 0 5px 20px 0 rgba(34, 180, 177, 0.5); }

.autocopileHashtag .selectedHashtag .radioHashtag {
  margin-bottom: 15px;
  display: flex;
  align-items: center; }
  .autocopileHashtag .selectedHashtag .radioHashtag .circleHashtag {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    border: 1px solid #767676;
    background: #fff;
    margin-right: 10px;
    position: relative; }
    .autocopileHashtag .selectedHashtag .radioHashtag .circleHashtag.selected:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background: #0075ff;
      top: calc(50% - 6px);
      left: calc(50% - 6px); }

@media (min-width: 320px) and (max-width: 480px) {
  .autocopileHashtag .headerInput {
    width: 100%; } }
