.remove {
  font-weight: bold;
  font-family: Barlow;
  text-align: center;
  margin-bottom: 5px !important;
  padding: 7px 30px;
  border-radius: 4px;
  border: 2px solid #ff3f5d;
  color: #ff3f5d; }
  .remove:hover {
    color: white;
    background-color: #ff3f5d; }

.card.containerPack {
  max-height: 398px; }

.addcart {
  font-family: Barlow;
  line-height: normal;
  letter-spacing: normal;
  font-style: normal;
  font-size: 14px !important;
  font-weight: bold;
  padding: 10px 10px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid white;
  background-color: #008996;
  color: white;
  outline: none;
  cursor: pointer; }
  .addcart:active {
    color: #008996;
    background-color: white;
    outline: none; }

#border {
  padding-top: 10px;
  border-top: 1px solid #f3f4f5;
  margin: 10px -10px 0; }

#trash {
  padding: 0 10px; }

.card.containerPack.preferiti {
  height: 440px;
  max-height: initial; }
