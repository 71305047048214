#filterContainer {
  background: #f5f8fa;
  padding: 0px;
  border-radius: 10px;
  border: solid 1px rgba(87, 36, 163, 0.1);
  /*# sourceMappingURL=index.css.map */ }
  #filterContainer::-webkit-scrollbar {
    width: 0em; }
  #filterContainer .container {
    padding-right: 20px;
    padding-left: 20px; }
  #filterContainer .headerFilter {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(87, 36, 163, 0.1);
    font-family: Barlow-semiBold;
    padding: 0 20px; }
    #filterContainer .headerFilter .reset {
      color: #008996;
      padding-bottom: 5px;
      border-bottom: 2px solid #008996;
      cursor: pointer; }
    #filterContainer .headerFilter .close {
      display: none;
      width: 15px;
      height: 15px;
      position: relative;
      cursor: pointer; }
      #filterContainer .headerFilter .close:after {
        content: "";
        height: 15px;
        border-left: 2px solid #008996;
        position: absolute;
        transform: rotate(45deg);
        left: 7px; }
      #filterContainer .headerFilter .close:before {
        content: "";
        height: 15px;
        border-left: 2px solid #008996;
        position: absolute;
        transform: rotate(-45deg);
        left: 7px; }
  #filterContainer .resetAll {
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    margin-bottom: 20px; }
    #filterContainer .resetAll h5 {
      text-align: center;
      font-family: Barlow-semiBold;
      color: #fff;
      padding: 10px;
      width: 100%;
      background: #455a64;
      border-radius: 4px;
      cursor: pointer; }
  #filterContainer .filterBox {
    position: relative;
    margin-bottom: 20px;
    display: block;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc; }
    #filterContainer .filterBox.disableCheck {
      opacity: 0.5; }
    #filterContainer .filterBox .label {
      font-family: Barlow-semiBold;
      margin-bottom: 10px !important;
      color: #455a64; }
    #filterContainer .filterBox.gbv {
      margin-top: 20px; }
      #filterContainer .filterBox.gbv .label {
        margin-bottom: 40px !important; }
    #filterContainer .filterBox input[type="checkbox"] {
      margin-right: 10px;
      min-width: 19px;
      min-height: 19px; }
    #filterContainer .filterBox .solo {
      font-family: Barlow-semiBold;
      cursor: pointer;
      padding: 5.5px 10px;
      border-radius: 3px;
      color: #455a64;
      background: rgba(87, 36, 163, 0.1);
      position: relative; }
      #filterContainer .filterBox .solo.select {
        background: #008996;
        color: #fff; }
      #filterContainer .filterBox .solo:after {
        position: absolute;
        content: url("../../styles/images/tooltip.png");
        left: 50px;
        top: -37px;
        width: 260px;
        height: auto;
        display: none;
        z-index: 99; }
      #filterContainer .filterBox .solo:hover:after {
        display: block; }
    #filterContainer .filterBox #labelMin {
      margin-left: -10px;
      margin-bottom: 10px; }
    #filterContainer .filterBox #labelMax {
      margin-right: 10px;
      margin-bottom: 10px; }
    #filterContainer .filterBox .boxCollapse {
      display: flex;
      justify-content: space-between; }
      #filterContainer .filterBox .boxCollapse .filterCollapse #freccia {
        width: 10px;
        height: 10px;
        border-right: 2px solid #455a64;
        border-bottom: 2px solid #455a64;
        transform: rotate(45deg);
        -webkit-transition-duration: 0.5s;
        -moz-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        -o-transition-property: -o-transform;
        transition-property: transform; }
        #filterContainer .filterBox .boxCollapse .filterCollapse #freccia.active {
          transform: rotate(225deg); }
    #filterContainer .filterBox .collapse .check {
      align-items: center;
      margin-bottom: 5px; }
      #filterContainer .filterBox .collapse .check h6 {
        margin-bottom: 0px; }
    #filterContainer .filterBox .radioFilter {
      display: flex;
      width: 230px;
      height: 30px;
      border-radius: 4px;
      border: solid 1px #dcdcdc;
      flex-direction: row;
      align-content: center; }
      #filterContainer .filterBox .radioFilter .item {
        flex: 1;
        background: #fff;
        border-radius: 4px;
        width: 50%;
        opacity: 0.5;
        font-family: Barlow;
        font-size: 14px;
        text-align: center;
        color: #455a64;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        #filterContainer .filterBox .radioFilter .item.select {
          background: rgba(0, 182, 202, 0.1);
          border-radius: 4px;
          box-shadow: 0 0 1pt 2pt #008996;
          z-index: 1;
          color: #008996;
          font-family: Barlow-bold; }
    #filterContainer .filterBox .checkCustom {
      width: 25px;
      height: 25px;
      border-radius: 4px;
      border: 1px solid #008996;
      cursor: pointer;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center; }
      #filterContainer .filterBox .checkCustom.selected:before {
        content: "\2713";
        color: #008996; }
      #filterContainer .filterBox .checkCustom.disabled {
        opacity: 0.2;
        cursor: default; }
  #filterContainer #autosuggestluogoAsset,
  #filterContainer #collapsecaratteristicheAssetValues {
    position: relative; }
    #filterContainer #autosuggestluogoAsset .disable,
    #filterContainer #collapsecaratteristicheAssetValues .disable {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9999;
      display: none; }
      #filterContainer #autosuggestluogoAsset .disable.show,
      #filterContainer #collapsecaratteristicheAssetValues .disable.show {
        display: block; }
  #filterContainer .input-range__slider {
    background: #fff;
    border: 1px solid #cecece;
    width: 25px;
    height: 25px;
    top: -5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07); }
  #filterContainer .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc; }
  #filterContainer .input-range__label {
    color: #aaaaaa;
    font-family: "Arial";
    font-size: 0.6rem; }
  #filterContainer .input-range__label--value .input-range__label-container {
    top: -5px; }
  #filterContainer .input-range__label--min,
  #filterContainer .input-range__label--max {
    display: none; }
  #filterContainer .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem; }
  #filterContainer .input-range--disabled .input-range__track {
    background: #eeeeee; }
  #filterContainer .input-range__track--active {
    background: #008996; }
  #filterContainer .input-range {
    margin: 10px; }
  #filterContainer select {
    background: transparent;
    width: 100%;
    margin-top: 10px;
    font-size: 0.6em !important;
    padding: 5px 10px; }
  #filterContainer .react-autosuggest__container {
    width: 100%; }
  #filterContainer .react-autosuggest__input {
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    font-family: Barlow-italic;
    font-size: 0.875em;
    border: 1px solid #aaa;
    border-radius: 4px;
    line-height: 1.5;
    color: #979da5; }
  #filterContainer .react-autosuggest__input--focused {
    outline: none; }
  #filterContainer .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  #filterContainer .react-autosuggest__suggestions-container {
    display: none; }
  #filterContainer .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 75px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 0.75em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2; }
    #filterContainer .react-autosuggest__suggestions-container--open li {
      padding: 5px 20px;
      border-bottom: 1px solid #ccc; }
  #filterContainer .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto; }
  #filterContainer .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px; }
  #filterContainer .react-autosuggest__suggestion--highlighted {
    background-color: #ddd; }

@media (min-width: 320px) and (max-width: 1024px) {
  #filterContainer {
    position: fixed;
    left: 0px;
    z-index: 99;
    top: 0;
    height: 100vh;
    overflow: scroll;
    transform: translate(-300px, 0);
    transition: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0;
    overflow-x: hidden;
    border-radius: initial; }
    #filterContainer .headerFilter .close {
      display: block;
      opacity: 1; }
    #filterContainer.show {
      transform: translate(0px, 0);
      transition: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
      opacity: 1; }
    #filterContainer .container {
      margin-top: 0; } }
